<template>

<div class="col">
            <div class="row">
     
              
            
        <div class="card mb-2" v-for="is in paginatedisler" :key="is.id">
          <div class="row g-0 sh-12">
            <div class="col">
              <div class="card-body pt-0 pb-0 h-100">
                <div class="row g-0 h-100 align-content-center">
                  <div class="col-auto d-flex flex-column mb-2 mb-md-0 position-static">
                    <router-link v-if="yetki_kontrol('is_goster')" :to="'/isler/' + is.id"> {{ is.ad }} </router-link>
                    <div class="text-small text-muted text-truncate">Müşteri: {{ musteriBilgileri(is.musteri).unvan}} </div>
                    <div class="text-small text-muted text-truncate">İş Türü: {{ isturBilgileri(is.istur).ad}} </div>
                  </div>

                  <div class="col w-100 d-flex justify-content-around mb-1">
                    
                    <div class="me-3 me-md-7">
                      <p class="text-small text-muted mb-1">Tarihler</p>
                      <div class="text-small text-muted text-truncate">Başlama Tarihi: {{ is.baslamatarih   }} </div>
                      <div class="text-small text-muted text-truncate">Bitiş Tarihi: {{ is.bitistarih   }} </div>
                    </div>
                    <div class="me-3 me-md-7">
                      <p class="text-small text-muted mb-1">Çalışanlar</p>
                      <template  v-if="is.kullanicilar && is.kullanicilar.length > 0">
                      <div class="text-small text-muted text-truncate" v-for="kulId in is.kullanicilar.split(',')" :key="kulId">
                      
                          {{ kullaniciBilgileri(kulId).ad }} {{ kullaniciBilgileri(kulId).soyad }} 
                      
                      </div>
                    </template>
                    </div>
                    <div class="me-3 me-md-7">
                      <p class="text-small text-muted mb-1">Durum</p>
                      <p class="mb-0"><span class="badge bg-outline-primary me-1">{{ getDurumAdi(is.durum) }} </span></p>
                    </div>
                  </div>

                  <div class="col-auto d-flex align-items-center justify-content-md-end">




                    <router-link v-if="yetki_kontrol('is_goster')" :to="'/isler/' + is.id"
                      class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1">

                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                        class="acorn-icons acorn-icons-eye icon">
                        <path
                          d="M2.47466 10.8418C2.15365 10.3203 2.15365 9.67971 2.47466 9.15822C3.49143 7.50643 6.10818 4 10 4C13.8918 4 16.5086 7.50644 17.5253 9.15822C17.8464 9.67971 17.8464 10.3203 17.5253 10.8418C16.5086 12.4936 13.8918 16 10 16C6.10818 16 3.49143 12.4936 2.47466 10.8418Z">
                        </path>
                        <path
                          d="M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z">
                        </path>
                      </svg>
                      <span class="d-none d-xxl-inline-block">Detay</span>
                    </router-link>

                    <button  v-if="yetki_kontrol('isdurum_duzenle')" @click="duzenle(is)"  class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                        class="acorn-icons acorn-icons-edit-square undefined">
                        <path
                          d="M11 2L5.5 2C4.09554 2 3.39331 2 2.88886 2.33706C2.67048 2.48298 2.48298 2.67048 2.33706 2.88886C2 3.39331 2 4.09554 2 5.5L2 14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18L14.5 18C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5L18 11">
                        </path>
                        <path
                          d="M15.4978 3.06224C15.7795 2.78052 16.1616 2.62225 16.56 2.62225C16.9585 2.62225 17.3405 2.78052 17.6223 3.06224C17.904 3.34396 18.0623 3.72605 18.0623 4.12446C18.0623 4.52288 17.904 4.90497 17.6223 5.18669L10.8949 11.9141L8.06226 12.6223L8.7704 9.78966L15.4978 3.06224Z">
                        </path>
                      </svg>
                      <span class="d-none d-xxl-inline-block">Düzenle</span>
                    </button>
                    <button v-if="yetki_kontrol('is_sil')" @click="sil(is.id)"
                      class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1 	">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                        class="acorn-icons acorn-icons-bin undefined">
                        <path
                          d="M4 5V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V5">
                        </path>
                        <path
                          d="M14 5L13.9424 4.74074C13.6934 3.62043 13.569 3.06028 13.225 2.67266C13.0751 2.50368 12.8977 2.36133 12.7002 2.25164C12.2472 2 11.6734 2 10.5257 2L9.47427 2C8.32663 2 7.75281 2 7.29981 2.25164C7.10234 2.36133 6.92488 2.50368 6.77496 2.67266C6.43105 3.06028 6.30657 3.62044 6.05761 4.74074L6 5">
                        </path>
                        <path d="M2 5H18M12 9V13M8 9V13"></path>
                      </svg>
                      <span class="d-none d-xxl-inline-block">Sil</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
            
           
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === isler.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="islerSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    isler:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  data() {
    return {
      kullanicilar: [],
      musteriler: [],
      isturler: [],
      durumlar: [
                { id: 'musteriyeni', ad: 'Müşteri İsteği' },
                { id: 'ajansyeni', ad: 'Yeni' },
                { id: 'hazirlaniyor', ad: 'Hazırlanıyor' },
                { id: 'ajansonaybekliyor', ad: 'Ajans Onayı Bekliyor' },
                { id: 'musterionaybekliyor', ad: 'Müşteri Onayı Bekliyor' },
                { id: 'revizeli', ad: 'Revizeli' },
                { id: 'tamamlandi', ad: 'Tamamlandi' },
                { id: 'iptal', ad: 'İptal Edildi' },
            ],
    };
  },
  created() {
    this.kullanicilarGetir(); 
    this.musterilerGetir(); 
    this.isturlerGetir(); 
  },
  computed: {
    filteredisler() {
      return this.isler.tumu.filter((is) => {

        
        const tumIsler =
          (is.durum && this.isler.filtreCard=='tumisler');

        const buHaftaIslerFiltre =
          (is.id && this.isler.filtreCard=='buhafta' && this.isler.buHaftakiIslerIdler.includes(is.id));

          
        const revizeliIslerFiltre =
          (is.id && this.isler.filtreCard=='revizeli' && this.isler.revizeliIsIdler.includes(is.id));


        const durumFiltre =
          (is.durum  && this.isler.filtreCard!='buhafta' && this.isler.filtreCard!='tumisler' && is.durum == this.isler.filtreCard);

      
        const digerFiltreler =
          (is.uruntur && is.uruntur.toLowerCase().includes(this.isler.arananKelime && this.isler.arananKelime.toLowerCase())) ||
          (is.aciklama && is.aciklama.toLowerCase().includes(this.isler.arananKelime && this.isler.arananKelime.toLowerCase()));

   
        return (tumIsler || durumFiltre || buHaftaIslerFiltre || revizeliIslerFiltre ) && digerFiltreler ;
      });
    },
      
    paginatedisler() {
      const start = (this.isler.seciliSayfa - 1) * this.isler.sayfadaKayit;
      const end = start + this.isler.sayfadaKayit;
      return this.filteredisler.slice(start, end);
    },
    isler_toplamSayfa() {
      const totalFilteredUsers = this.filteredisler.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.isler.sayfadaKayit), 1);
      if (this.isler.seciliSayfa > maxPage) {
        this.islerSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
      return Array.from({ length: this.isler_toplamSayfa }, (_, index) => index + 1);
    },
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
   
    getDurumAdi(durumId) {
            const durum = this.durumlar.find(item => item.id === durumId);
            return durum ? durum.ad : 'Bilinmeyen Durum';
        },

    async sil(isId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("isler/sil/" + isId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    islerSayfaDegistir(page) {
      this.$emit('update:isler',{
      ...this.isler,
      seciliSayfa: page
    });
    },
    islerSayfadaKayitDegistir(value) {
      this.$emit('update:isler',{
      ...this.isler,
      sayfadaKayit: value
    });
      this.islerSayfaDegistir(1);
    },

    async kullanicilarGetir() {
      try {
        const response = await axiosCustom.get("kullanicilar");
        this.kullanicilar = response.data;
      } catch (error) {
        console.error("kullanicilar alma hatası: ", error);
      }
    },
    kullaniciBilgileri(id) {
      return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
    },
    async musterilerGetir() {
      try {
        const response = await axiosCustom.get("musteriler"); 
        this.musteriler = response.data;
      } catch (error) {
        console.error("musteriler alma hatası: ", error);
      }
    },
    musteriBilgileri(id) {
      return this.musteriler.find((musteri) => musteri.id === id) || {};
    },
    async isturlerGetir() {
      try {
        const response = await axiosCustom.get("isturler"); 
        this.isturler = response.data;
      } catch (error) {
        console.error("istürler alma hatası: ", error);
      }
    },
    isturBilgileri(id) {
      return this.isturler.find((musteri) => musteri.id === id) || {};
    },
  },
};
</script>
