/* eslint-disable */

export function yetkiKontrol(yetki) {

    const jwtToken = localStorage.getItem('token');

    if (jwtToken) {
        try {

            const decodedToken = parseJwt(jwtToken);

            if (decodedToken && decodedToken.yetkiler && decodedToken.yetkiler.includes(yetki)) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('JWT parse hatası:', error);
            return false;
        }
    } else {
        return false;
    }
}


function parseJwt(token) {
    try {
        const [header, payload, signature] = token.split('.');

        const decodedHeader = JSON.parse(atob(header));
        const decodedPayload = JSON.parse(atob(payload));

        return decodedPayload;
    } catch (e) {
        return null;
    }
}