// globalVariable.js veya benzeri bir dosyanız
export const globalVariable = {
    apiUrl: 'http://takip.arttimemedya.com/api/',
    varsayilanGorsel: function(event) {
        event.target.src = this.apiUrl + 'resources/varsayilan.png';
    },
    isValidDateFormat: function(tarih) {
        // Düzenli ifade ile format kontrolü
        const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
        return regex.test(tarih);
    },
    setRouter: function(router) {
        this.router = router;
    },
    cikisYap: function() {
        localStorage.removeItem('musteritoken');
        localStorage.removeItem('token');
        this.router.push({ name: 'Login' });
    },
};

globalVariable.uploadUrl = globalVariable.apiUrl + 'uploads/';


