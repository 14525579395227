<template>
  <div class="d-flex justify-content-between">
    <h2 class="small-title">Yapılacaklar</h2>
    <button class="btn btn-icon btn-icon-end btn-xs btn-background-alternate p-0 text-small" type="button"
      @click="yapilacak_ekle">
      <span class="align-bottom">Yeni Ekle</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20" fill="none" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
        class="acorn-icons acorn-icons-chevron-right align-middle">
        <path d="M7 4L12.6464 9.64645C12.8417 9.84171 12.8417 10.1583 12.6464 10.3536L7 16"></path>
      </svg>
    </button>
  </div>
  <div class="card h-xl-100-card">
    <div class="card-header border-0 pb-0 d-flex justify-content-center">
      <div class="glide-tab-container">
        <div class="glide glide-tab glide--ltr glide--slider glide--swipeable" id="appointmentsCarousel">
          <div class="glide__track" data-glide-el="track">
            <div class="glide__slides nav nav-pills d-flex justify-content-center">
              <template v-for="(yapilacak, index) in yapilacaklar" :key="index">
                <div class="glide__slide flex-fill" :class="{ 'glide__slide--active active': yapilacakaktif == index }"
                  @click="yapilacakTabDegistir(index)">
                  <button class="btn btn-foreground hover-outline px-1 py-3 rounded-xl sw-4" type="button">
                    <div class="text-alternate mb-2">{{ yapilacak.gunismi }}</div>
                    <div class="text-primary">{{ yapilacak.gun }}</div>
                  </button>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-3">
      <div class="tab-content">
        <template v-for="(yapilacak, index) in yapilacaklar" :key="index">
          <div class="tab-pane fade mb-n3" :class="{ 'active show': yapilacakaktif == index }">
            <div class="mb-4 text-primary text-center">
              {{ yapilacak.tarihgosterim }}
            </div>
            <div class="text-center" v-if="yapilacak.yapilacaklar.length == 0">
              <p>Notunuz Bulunmuyor</p>
              <button class="btn btn-icon btn-icon-start btn-primary" type="button" @click="yapilacak_ekle">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="acorn-icons acorn-icons-calendar undefined">
                  <path
                    d="M14.5 4C15.9045 4 16.6067 4 17.1111 4.33706C17.3295 4.48298 17.517 4.67048 17.6629 4.88886C18 5.39331 18 6.09554 18 7.5L18 14.5C18 15.9045 18 16.6067 17.6629 17.1111C17.517 17.3295 17.3295 17.517 17.1111 17.6629C16.6067 18 15.9045 18 14.5 18L5.5 18C4.09554 18 3.39331 18 2.88886 17.6629C2.67048 17.517 2.48298 17.3295 2.33706 17.1111C2 16.6067 2 15.9045 2 14.5L2 7.5C2 6.09554 2 5.39331 2.33706 4.88886C2.48298 4.67048 2.67048 4.48298 2.88886 4.33706C3.39331 4 4.09554 4 5.5 4L14.5 4Z">
                  </path>
                  <path d="M2 9H18M7 2 7 5.5M13 2 13 5.5M5 15H6"></path>
                </svg>
                <span>Yapılacak Ekleme</span>
              </button>
            </div>
            <div class="row g-0 mb-3" v-for="yap in yapilacak.yapilacaklar" :key="yap.id" @click="yapilacak_duzenle(yap)">
              <div class="col-auto">
                <div class="sw-5 d-inline-block d-flex align-items-center pt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                    class="acorn-icons acorn-icons-bell icon text-primary">
                    <path opacity="0.9" d="M11 18H10H9"></path>
                    <path
                      d="M6 7.38161V5.6718C6 4.25597 6.83142 2.97212 8.12338 2.39297C8.70126 2.13392 9.32738 2 9.96066 2H10.0393C10.6726 2 11.2987 2.13392 11.8766 2.39297C13.1686 2.97212 14 4.25597 14 5.6718V7.38161C14 8.44715 14.2374 9.4993 14.6949 10.4616L15.578 12.3193C15.8428 12.8762 15.8216 13.5267 15.5213 14.0653C15.1995 14.6423 14.5905 15 13.9297 15H6.07028C5.40953 15 4.80053 14.6423 4.4787 14.0653C4.17837 13.5267 4.15724 12.8762 4.42198 12.3193L5.30513 10.4616C5.76263 9.4993 6 8.44715 6 7.38161Z">
                    </path>
                  </svg>
                </div>
              </div>
              <div class="col">
                <div class="card-body d-flex flex-column ps-0 pt-0 pb-0 h-100 justify-content-center">
                  <div class="d-flex flex-column">
                    <div class="text-body">{{ yap.ad }}</div>
                    <div class="text-body">{{ yap.aciklama }}</div>
                    <template v-for="kul in yap.kullanici" :key="kul">
                      <div class="text-muted">{{ kullaniciBilgileri(kul).ad }} {{ kullaniciBilgileri(kul).soyad }} </div>
                    </template>
                    <template v-for="mus in yap.musteri" :key="mus">
                      <div class="text-muted">{{ musteriBilgileri(mus).unvan }}</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </template>


      </div>
    </div>
  </div>





  <div class="modal fade modal-close-out show" style="display: block;" v-if="yapilacakmodal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="yapilacakislem == 'ekle'"> Yeni Yapılacak Ekle </h5>
          <h5 class="modal-title" v-if="yapilacakislem == 'duzenle'">Yapılacak Düzenleme </h5>
          <button type="button" class="btn-close" @click="yapilacak_iptal"></button>
        </div>
        <div class="modal-body">
          <yapilacakForm formIslem="yapilacakislem" :formData="yapilacakform" :formKaydetSonrasi="yapilacaklarGetir"
            :formIptal="yapilacak_iptal" />

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import yapilacakForm from "@/components/yapilacaklar/yapilacakForm.vue";


export default {
  name: "YapilacaklarCard",
  components: {
    yapilacakForm,
  },
  data() {
    return {
      yapilacaklar: [],
      kullanicilar: [],
      musteriler: [],
      yapilacakaktif: 0,
      yapilacakform: {},
      yapilacakislem: 'ekle',
      yapilacakmodal: false,
    }
  },
  mounted() {
    this.yapilacaklarGetir();
    this.kullanicilarGetir();
    this.musterilerGetir();
  },
  methods: {

    yapilacaklarGetir() {
      axiosCustom
        .get("anasayfa/yapilacaklar")
        .then((response) => {
          this.yapilacaklar = response.data;
          this.yapilacakmodal = false;
        })
        .catch((error) => {
          console.error("Veri çekme hatası: ", error);
        });
    },

    yapilacakTabDegistir(index) {
      this.yapilacakaktif = index;
    },

    yapilacak_ekle() {
      this.yapilacakform = {};
      this.yapilacakislem = 'ekle';
      this.yapilacakmodal = true;
    },
    yapilacak_duzenle(yap) {
      this.yapilacakform = yap;
      this.yapilacakislem = 'duzenle';
      this.yapilacakmodal = true;
    },

    yapilacak_iptal() {
      this.yapilacakmodal = false;
    },

    async kullanicilarGetir() {
      try {
        const response = await axiosCustom.get("kullanicilar");
        this.kullanicilar = response.data;
      } catch (error) {
        console.error("kullanicilar alma hatası: ", error);
      }
    },
    kullaniciBilgileri(id) {
      return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
    },


    async musterilerGetir() {
      try {
        const response = await axiosCustom.get("musteriler");
        this.musteriler = response.data;
      } catch (error) {
        console.error("musteriler alma hatası: ", error);
      }
    },
    musteriBilgileri(id) {
      return this.musteriler.find((musteri) => musteri.id === id) || {};
    },



  },
};
</script>




