<!-- eslint-disable vue/no-mutating-props -->
<template>
  

                 
  <form @submit.prevent="gonder">

    <div class="row">
   
    



      <div class="row">
                  <label class="col-4 pt-2" :class="{ 'text-danger': formHatalar.durum }">
                    İş Durumu  :
                  </label>
                  <div class="form-floating col-8 mb-2" :class="{ 'text-danger': formHatalar.durum }">
                    <v-select v-model="localFormData.durum" :options="durumlar" label="ad" :reduce="tur => tur.id"
                      placeholder="Durum seçin" />
                    <span v-if="formHatalar.durum" class="error">{{ formHatalar.durum }}</span>
                  </div>
                </div>	

      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <label for="ad">Ad</label> 
      </div>	
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
    
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.aciklama }"> 
        <textarea id="aciklama" v-model="localFormData.aciklama" class="form-control" rows="5"></textarea>
        <label for="aciklama">Açıklama</label> 
      </div>	
        <span v-if="formHatalar.aciklama" class="error">{{ formHatalar.aciklama }}</span>
    
    
    
      <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.baslamatarih }"> 
        <VueCtkDateTimePicker 
        v-model="localFormData.baslamatarih" 
        format="YYYY-MM-DD HH:mm:ss"
        label="İş Başlama Tarihi Seçiniz"
        button-now-translation="Bugün"
        />
      </div>	
        <span v-if="formHatalar.baslamatarih" class="error">{{ formHatalar.baslamatarih }}</span>
   
    
      <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.bitistarih }"> 
        <VueCtkDateTimePicker 
        v-model="localFormData.bitistarih" 
        format="YYYY-MM-DD HH:mm:ss"
        label="İş Bitiş Tarihi Seçiniz"
        button-now-translation="Bugün"
        />
      </div>	
        <span v-if="formHatalar.bitistarih" class="error">{{ formHatalar.bitistarih }}</span>
   
    
    
      <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.musteri }"> 
        <v-select
          v-model="localFormData.musteri"
          :options="musteriler"
          label="unvan"
          :reduce="musteri => musteri.id"
          placeholder="Müşteri Seçin"
        />
      </div>	
        <span v-if="formHatalar.musteri" class="error">{{ formHatalar.musteri }}</span>
    
    
    
    <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.istur }"> 
        <v-select-coklu
          multiple
          v-model="localFormData.istur"
          :options="isturler"
          label="ad"
          :reduce="istur => istur.id"
          placeholder="İş Türü Seçin"
        />
      </div>	
        <span v-if="formHatalar.istur" class="error">{{ formHatalar.istur }}</span>
    
    
    <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.kullanicilar }"> 
        <v-select-coklu
          multiple
          v-model="localFormData.kullanicilar"
          :options="kullanicilar"
          label="ad"
          :reduce="kullanicilar => kullanicilar.id"
          placeholder="Kullanıcılar Seçin"
        />
      </div>	
        <span v-if="formHatalar.kullanicilar" class="error">{{ formHatalar.kullanicilar }}</span>
    
    
    
    
      <div class="form-group col-12 mb-1" :class="{ 'text-danger': formHatalar.dosyalar }" v-if="formIslem=='ekle'"> 
              <label for="dosyalar">Dosyalar</label> 
              <MultiFileCompenent :seciliDosyalar="seciliDosyalar" :yuklenmisDosyalar="yuklenmisDosyalar" :tabloid="localFormData.id"  @dosya-islem="seciliDosyalarDegistir"  @dosya-sil-islem="yukluDosyalarSil"></MultiFileCompenent>
              <span v-if="formHatalar.dosyalar" class="error">{{ formHatalar.dosyalar }}</span>
     </div>	
    
    

     
    </div>

    
    <div  class="col-12" v-if="isUploading">
      {{ uploadFileNmae }} Dosyası Yükleniyor
    
      <div class="progress" >
          <div class="progress-bar" role="progressbar" :style="{ width: `${uploadProgress}%` }">
            {{ uploadProgress }}%
        </div>
      </div>

    </div>
    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";

import vSelect from "vue-select";


import vSelectCoklu from "vue-select";




import MultiFileCompenent from "@/components/anasayfa/MultiFileCompenent.vue";


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';



export default {
components: {
  
      vSelect,
  
  
      vSelectCoklu,
  
  
  
    MultiFileCompenent,
  
  
    VueCtkDateTimePicker,
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  musteriler: [],
  
  
  isturler: [],
  
  kullanicilar: [],
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    
    seciliDosyalar:[],
    yuklenmisDosyalar:[],

    isUploading: false,
    uploadProgress: 0,
    uploadFileName:'',
    
    durumlar: [
        { id: 'musteriyeni', ad: 'Müşteri İsteği' },
        { id: 'ajansyeni', ad: 'Yeni' },
        { id: 'hazirlaniyor', ad: 'Hazırlanıyor' },
        { id: 'ajansonaybekliyor', ad: 'Ajans Onayı Bekliyor' },
        { id: 'musterionaybekliyor', ad: 'Müşteri Onayı Bekliyor' },
        { id: 'revizeli', ad: 'Revizeli' },
        { id: 'tamamlandi', ad: 'Tamamlandi' },
        { id: 'iptal', ad: 'İptal Edildi' },
      ],

  };
},
created() {
  
  this.musterilerGetir();
  
  
  this.isturlerGetir();
  
  this.kullanicilarGetir();
   

  
  this.yuklenmisDosyalarGetir();
  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          
          
          try{
            this.localFormData.istur = newFormData.istur.split(',');
          }catch (error) {
            this.localFormData.istur = newFormData.istur;
          }
          
          try{
            this.localFormData.kullanicilar = newFormData.kullanicilar.split(',');
          }catch (error) {
            this.localFormData.kullanicilar = newFormData.kullanicilar;
          }
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  async musterilerGetir() {
    try {
      const response = await axiosCustom.get("musteriler"); 
      this.musteriler = response.data;
    } catch (error) {
      console.error("musteriler alma hatası: ", error);
    }
  },
  
  
  async isturlerGetir() {
    try {
      const response = await axiosCustom.get("isturler"); 
      this.isturler = response.data;
    } catch (error) {
      console.error("isturler alma hatası: ", error);
    }
  },
  
  async kullanicilarGetir() {
    try {
      const response = await axiosCustom.get("kullanicilar"); 
      this.kullanicilar = response.data;
    } catch (error) {
      console.error("kullanicilar alma hatası: ", error);
    }
  },
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "ad boş bırakılamaz.";
      kontrol=false;
    }
    
    
    
  
  if (!this.localFormData.aciklama) {
      this.formHatalar.aciklama = "Açıklama boş bırakılamaz.";
      kontrol=false;
    }
  
  

    
  
  
  if (!this.localFormData.musteri) {
      this.formHatalar.musteri = "Müşteri boş bırakılamaz.";
      kontrol=false;
    }
  
  
  if (!this.localFormData.istur) {
      this.formHatalar.istur = "İş Türü boş bırakılamaz.";
      kontrol=false;
    }

    
   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("ad", this.localFormData.ad);newFormData.append("durum", this.localFormData.durum);
  newFormData.append("aciklama", this.localFormData.aciklama);
  newFormData.append("baslamatarih", this.localFormData.baslamatarih);
  newFormData.append("bitistarih", this.localFormData.bitistarih);
  newFormData.append("musteri", this.localFormData.musteri);
  newFormData.append("istur", this.localFormData.istur);newFormData.append("kullanicilar", this.localFormData.kullanicilar); 
  
  

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("isler/duzenle/" + this.localFormData.id, newFormData)
      .then((response) => {
        
        this.cokluDosyalarYukle(response.data.id);
        

        this.$toast.success("İş Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("isler/ekle", newFormData)
      .then((response) => {
        
        this.cokluDosyalarYukle(response.data.id);
        

        this.$toast.success("Yeni İş Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  
  async yuklenmisDosyalarGetir() {
      try {
        const response = await axiosCustom.get("isler/dosyalar");
        this.yuklenmisDosyalar = response.data;
      } catch (error) {
        console.error("dosya alma hatası: ", error);
      }
    },

  seciliDosyalarDegistir({ files, index, type }) {
    if (type === "ekle") {
      for (let i = 0; i < files.length; i++) {
        this.seciliDosyalar.push(files[i]);
      }
    } else if (type === "kaldır") {
      this.seciliDosyalar.splice(index, 1);
    }
  },

    async cokluDosyalarYukle(id) {
    const CHUNK_SIZE = 1024 * 1024; // 1 MB

    for (const file of this.seciliDosyalar) {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        for (let chunk = 0; chunk < totalChunks; chunk++) {
            const start = chunk * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);

            const chunkData = file.slice(start, end);

            let formData = new FormData();
            formData.append("fileName", file.name);
            this.uploadFileName = file.name;
            formData.append("fileChunk", chunkData);
            formData.append("currentChunk", chunk);
            formData.append("totalChunks", totalChunks);

            try {
                await axiosCustom.post("isler/dosyalaryukle/" + id, formData);
                this.isUploading = true;
            
                this.uploadProgress = (chunk * totalChunks) / 100;
            } catch (error) {
                console.error('dosyalar yükleme hatası: ', error);
                this.$toast.error('dosyalar yükleme hatası');
                // Handle error
                return;
            }
        }
    }

    // Tüm dosyaların tüm chunk'ları yüklendiyse, işlemi tamamla
    this.isUploading = false;
    this.uploadProgress = 100;
    },

      async yukludosyalarSil(id) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("isler/dosyalarsil/" + id);
          this.$toast.success("Dosyalar Silindi");
          this.yuklenmisDosyalarGetir();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },

  

  
},
};
</script>