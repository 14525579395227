<!-- eslint-disable vue/no-mutating-props -->
<template>
  

                 
  <form @submit.prevent="gonder">

    <div class="row">
   

      <div class="col-12 mb-1" :class="{ 'text-danger': formHatalar.gorsel }"> 
       
       <picture-input 
         ref="profil" 
         height="200" 
         margin="0" 
         accept="image/jpeg,image/png" 
         size="10" 
         button-class="btn"
         :custom-strings="{
           upload: '<h1>Görsel</h1>',
           drag: 'Fikir Görseli Eklemek İçin Tıklayınız.'
         }"
         @change="gorselDegistir">
       </picture-input>
     </div>	
       <span v-if="formHatalar.gorsel" class="error">{{ formHatalar.gorsel }}</span>
  
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.baslik }"> 
        <input id="baslik" v-model="localFormData.baslik" class="form-control"/>
        <label for="baslik">Başlık</label> 
      </div>	
        <span v-if="formHatalar.baslik" class="error">{{ formHatalar.baslik }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.link }"> 
        <input id="link" v-model="localFormData.link" class="form-control"/>
        <label for="link">Link</label> 
      </div>	
        <span v-if="formHatalar.link" class="error">{{ formHatalar.link }}</span>
      
    
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.aciklama }"> 
        <textarea id="aciklama" v-model="localFormData.aciklama" class="form-control" rows="5"></textarea>
        <label for="aciklama">Açıklama</label> 
      </div>	
        <span v-if="formHatalar.aciklama" class="error">{{ formHatalar.aciklama }}</span>
    
    
    
    
    
    <div class=" col-12 mb-2" :class="{ 'text-danger': formHatalar.musteri }"> 
        <v-select-coklu
          multiple
          v-model="localFormData.musteri"
          :options="musteriler"
          label="unvan"
          :reduce="musteri => musteri.id"
          placeholder="Müşteri Seçin"
        />
      </div>	
    <span v-if="formHatalar.musteri" class="error">{{ formHatalar.musteri }}</span>
    
    
    
   
    
    
    
      <div class="col-12  d-flex justify-content-start mb-1" :class="{ 'text-danger': formHatalar.yapildi }">  
        <label >Yapıldı mı ?</label> 
        <Toggle  v-model="localFormData.yapildi" />	
        
      </div>
      <span v-if="formHatalar.yapildi" class="error">{{ formHatalar.yapildi }}</span>
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";


import vSelectCoklu from "vue-select";



import PictureInput from 'vue-picture-input'




import Toggle from '@vueform/toggle';


export default {
components: {
  
  
      vSelectCoklu,
  
  
    PictureInput,
  
  
  
  
    Toggle,
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    gorselSecili: null,
    
    
  
  musteriler: [],
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  
  this.musterilerGetir();
   

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          if(this.localFormData.yapildi=='1'){
            this.localFormData.yapildi=true;
            }else{
              this.localFormData.yapildi=false;
            }
          
          
          
          try{
            this.localFormData.musteri = newFormData.musteri.split(',');
          }catch (error) {
            this.localFormData.musteri = newFormData.musteri;
          }
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  
  async musterilerGetir() {
    try {
      const response = await axiosCustom.get("musteriler"); 
      this.musteriler = response.data;
    } catch (error) {
      console.error("musteriler alma hatası: ", error);
    }
  },
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.baslik) {
      this.formHatalar.baslik = "Başlık boş bırakılamaz.";
      kontrol=false;
    }
 
    

  
  
  
  if (!this.localFormData.musteri) {
      this.formHatalar.musteri = "Müşteri boş bırakılamaz.";
      kontrol=false;
    }
   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("baslik", this.localFormData.baslik);newFormData.append("link", this.localFormData.link);
  newFormData.append("aciklama", this.localFormData.aciklama);
  
  
  newFormData.append("musteri", this.localFormData.musteri); 
  newFormData.append("gorsel",  this.gorselSecili);
  newFormData.append("yapildi",  this.localFormData.yapildi==true ? '1' : '0');

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("fikirler/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("İş Fikir Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("fikirler/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni İş Fikir Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
  gorselDegistir(event) {
        // Dosya seçildiğinde bu metod çağrılır
        this.gorselSecili = event.target.files[0];

        // Resmin önizlemesini gösterme
        if (this.gorselSecili) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.gorselOnizleme = e.target.result;
            };

            reader.readAsDataURL(this.gorselSecili);
        }
    },
    
},
};
</script>