<!-- eslint-disable vue/no-mutating-props -->
<template>
  

  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <label for="ad">Ad</label> 
      </div>	
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
      
    
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.aciklama }"> 
        <textarea id="aciklama" v-model="localFormData.aciklama" class="form-control" rows="5"></textarea>
        <label for="aciklama">Açıklama</label> 
      </div>	
        <span v-if="formHatalar.aciklama" class="error">{{ formHatalar.aciklama }}</span>
    
    
    
      <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.tarih }"> 
        <VueCtkDateTimePicker 
        v-model="localFormData.tarih" 
        format="YYYY-MM-DD HH:mm:ss"
        label="Tarih Seçiniz"
        button-now-translation="Bugün"
        />
      </div>	
        <span v-if="formHatalar.tarih" class="error">{{ formHatalar.tarih }}</span>
   
    
    
    
    <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.musteriler }"> 
        <v-select-coklu
          multiple
          v-model="localFormData.musteriler"
          :options="musteriler"
          label="unvan"
          :reduce="musteriler => musteriler.id"
          placeholder="Müşteriler Seçin"
        />
      </div>	
        <span v-if="formHatalar.musteriler" class="error">{{ formHatalar.musteriler }}</span>
    
    
    <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.kullanicilar }"> 
        <v-select-coklu
          multiple
          v-model="localFormData.kullanicilar"
          :options="kullanicilar"
          label="ad"
          :reduce="kullanicilar => kullanicilar.id"
          placeholder="Kullanıcılar Seçin"
        />
      </div>	
        <span v-if="formHatalar.kullanicilar" class="error">{{ formHatalar.kullanicilar }}</span>
    
    
    
    
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";


import vSelectCoklu from "vue-select";





import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';



export default {
components: {
  
  
      vSelectCoklu,
  
  
  
  
    VueCtkDateTimePicker,
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  
  musteriler: [],
  
  kullanicilar: [],
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  
  this.musterilerGetir();
  
  this.kullanicilarGetir();
   

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          
          
          try{
            this.localFormData.musteriler = newFormData.musteriler.split(',');
          }catch (error) {
            this.localFormData.musteriler = newFormData.musteriler;
          }
          
          try{
            this.localFormData.kullanicilar = newFormData.kullanicilar.split(',');
          }catch (error) {
            this.localFormData.kullanicilar = newFormData.kullanicilar;
          }
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  
  async musterilerGetir() {
    try {
      const response = await axiosCustom.get("musteriler"); 
      this.musteriler = response.data;
    } catch (error) {
      console.error("musteriler alma hatası: ", error);
    }
  },
  
  async kullanicilarGetir() {
    try {
      const response = await axiosCustom.get("kullanicilar"); 
      this.kullanicilar = response.data;
    } catch (error) {
      console.error("kullanicilar alma hatası: ", error);
    }
  },
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "Ad boş bırakılamaz.";
      kontrol=false;
    }
    
  
  if (!this.localFormData.aciklama) {
      this.formHatalar.aciklama = "Açıklama boş bırakılamaz.";
      kontrol=false;
    }
  
  
  if (!this.localFormData.tarih) {
      this.formHatalar.tarih = "Tarih boş bırakılamaz.";
      kontrol=false;
    }
  
  
  
  if (!this.localFormData.musteriler) {
      this.formHatalar.musteriler = "Müşteriler boş bırakılamaz.";
      kontrol=false;
    }
  
  if (!this.localFormData.kullanicilar) {
      this.formHatalar.kullanicilar = "Kullanıcılar boş bırakılamaz.";
      kontrol=false;
    }
   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("ad", this.localFormData.ad);
  newFormData.append("aciklama", this.localFormData.aciklama);
  newFormData.append("tarih", this.localFormData.tarih);
  
  newFormData.append("musteriler", this.localFormData.musteriler);newFormData.append("kullanicilar", this.localFormData.kullanicilar); 
  
  

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("yapilacaklar/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("Yapılacak Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("yapilacaklar/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni Yapılacak Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
},
};
</script>