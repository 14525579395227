<template>
  <div class="col">
    <div class="row">

    

        <!-- yapilacak Kart -->
        <div class="col-md-6 mt-4" v-for="yapilacak in paginatedyapilacaklar" :key="yapilacak.id">

          <div class="card h-100">

            <div class="card-body row g-0">
              <div class="col-auto">
                <div class="d-inline-block d-flex">
                  <div class="bg-gradient-light sw-5 sh-5 rounded-md">
                    <div
                      class="text-white d-flex justify-content-center align-items-center h-100 text-small text-center lh-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                        class="acorn-icons acorn-icons-notebook-1 undefined">
                        <path
                          d="M3 5.5C3 4.09554 3 3.39331 3.33706 2.88886C3.48298 2.67048 3.67048 2.48298 3.88886 2.33706C4.39331 2 5.09554 2 6.5 2H13.5C14.9045 2 15.6067 2 16.1111 2.33706C16.3295 2.48298 16.517 2.67048 16.6629 2.88886C17 3.39331 17 4.09554 17 5.5V14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18H6.5C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5V5.5Z">
                        </path>
                        <path d="M8 6H12M8 10H12M8 14H12M2 8H4M2 12H4"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card-body d-flex flex-column pe-0 pt-0 pb-0 h-100 justify-content-start">
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-column justify-content-center">
                      <router-link v-if="yetki_kontrol('yapilacak_goster')" :to="'yapilacaklar/' + yapilacak.id"
                        class="heading ">{{ yapilacak.ad }}</router-link>
                    </div>
                    <div class="text-alternate">{{ yapilacak.tarih }} </div>
                    <div class="text-alternate" v-if="yapilacak.musteriler && yapilacak.musteriler.length>0">
                      <template v-for="mus in yapilacak.musteriler">
                        {{ musteriBilgileri(mus).unvan }}   
                     </template>
                    </div>
                    <div class="text-muted mt-1">{{ yapilacak.aciklama }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-muted">
              <div class="row g-0 sh-6">
                <div class="col-auto" v-if="yapilacak.kullanicilar && yapilacak.kullanicilar.length > 0">
                  <div class="row g-0 align-items-start align-content-start align-content-md-center align-items-md-center sh-5">
                    <div class="col-auto d-flex align-items-center">
                      <div :class="'sw-' + (Math.max(yapilacak.kullanicilar.length, 0) * 4)">
                        <div class="sw-4 mb-1 d-inline-block">
                          <img :src="$globalVariable.uploadUrl + kullaniciBilgileri(yapilacak.kullanicilar[0]).profil"
                                 @error="$globalVariable.varsayilanGorsel"
                            class="img-fluid rounded-xl border border-2 border-foreground sh-4" >
                        </div>
                        <div class="sw-4 mb-1 d-inline-block ms-n3" v-if="yapilacak.kullanicilar && yapilacak.kullanicilar.length>2">
                          <img :src="$globalVariable.uploadUrl + kullaniciBilgileri(yapilacak.kullanicilar[1]).profil"
                    @error="$globalVariable.varsayilanGorsel"
                            class="img-fluid rounded-xl border border-2 border-foreground sh-4" 
                            >
                        </div>
                        <div
                          class="sw-4 sh-4 ms-n3 bg-separator-light d-inline-flex justify-content-center align-items-center rounded-xl" v-if=" yapilacak.kullanicilar&& yapilacak.kullanicilar.length>3">
                          <div class="fw-bold text-small text-primary cursor-default">
                           {{ yapilacak.kullanicilar && yapilacak.kullanicilar.length }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div>{{ kullaniciBilgileri(yapilacak.kullanicilar[0]).ad }}
                        {{ kullaniciBilgileri(yapilacak.kullanicilar[0]).soyad }}</div>
                      <div class="text-muted text-small" v-if="yapilacak.kullanicilarlar && yapilacak.kullanicilar.length>1">ve {{ yapilacak.kullanicilar.length-1 }} Kullanıcı</div>
                    </div>
                  </div>
                </div>
                <div class="col  d-flex justify-content-end">
                  
                      <button v-if="yetki_kontrol('yapilacak_duzenle')" @click="duzenle(yapilacak)"
                        class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="pen" fill="#7c7c7c" class="icon"></unicon>
                      </button>
                      <button v-if="yetki_kontrol('yapilacak_sil')" @click="sil(yapilacak.id)"
                        class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="trash" fill="#7c7c7c" class="icon"></unicon>
                      </button>
                 
                  
                </div>
              </div>


            </div>

          </div>

        </div>
        <!-- yapilacak Kart -->
  
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <!-- Sayfalama -->
        <nav>
          <ul class="pagination">
            <li class="page-item" :class="{ active: page === yapilacaklar.seciliSayfa }" v-for="page in pages"
              :key="page">
              <a class="page-link" @click="yapilacaklarSayfaDegistir(page)">{{
                page
              }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {
    yapilacaklar: {
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur: String,
  },

  data() {
    return {
      kullanicilar: [],
      musteriler: [],
    };
  },
  created() {
    this.kullanicilarGetir(); this.musterilerGetir();
  },
  computed: {
    filteredyapilacaklar() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.yapilacaklar.tumu.filter(
        (yapilacak) =>

          yapilacak.ad.toLowerCase()

            .includes(this.yapilacaklar.arananKelime.toLowerCase()) || yapilacak.ad.toLowerCase()


              .includes(this.yapilacaklar.arananKelime.toLowerCase()) || yapilacak.aciklama.toLowerCase()

                .includes(this.yapilacaklar.arananKelime.toLowerCase())



      );
    },
    paginatedyapilacaklar() {
      const start = (this.yapilacaklar.seciliSayfa - 1) * this.yapilacaklar.sayfadaKayit;
      const end = start + this.yapilacaklar.sayfadaKayit;
      return this.filteredyapilacaklar.slice(start, end);
    },
    yapilacaklar_toplamSayfa() {
      const totalFilteredUsers = this.filteredyapilacaklar.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.yapilacaklar.sayfadaKayit), 1);
      if (this.yapilacaklar.seciliSayfa > maxPage) {
        this.yapilacaklarSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
      return Array.from({ length: this.yapilacaklar_toplamSayfa }, (_, index) => index + 1);
    },
  },
 
  methods: {
    yetki_kontrol(yetki) {
      return yetkiKontrol(yetki);
    },
    async sil(yapilacakId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("yapilacaklar/sil/" + yapilacakId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    yapilacaklarSayfaDegistir(page) {
      this.$emit('update:yapilacaklar', {
        ...this.yapilacaklar,
        seciliSayfa: page
      });
    },
    yapilacaklarSayfadaKayitDegistir(value) {
      this.$emit('update:yapilacaklar', {
        ...this.yapilacaklar,
        sayfadaKayit: value
      });
      this.yapilacaklarSayfaDegistir(1);
    },


    async kullanicilarGetir() {
      try {
        const response = await axiosCustom.get("kullanicilar");
        this.kullanicilar = response.data;
      } catch (error) {
        console.error("kullanicilar alma hatası: ", error);
      }
    },
    kullaniciBilgileri(id) {
      return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
    },


    async musterilerGetir() {
      try {
        const response = await axiosCustom.get("musteriler");
        this.musteriler = response.data;
      } catch (error) {
        console.error("musteriler alma hatası: ", error);
      }
    },
    musteriBilgileri(id) {
      return this.musteriler.find((musteri) => musteri.id === id) || {};
    },
  },
};
</script>
