<!-- eslint-disable vue/no-mutating-props -->
<template>
  

  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.revize }"> 
        <textarea id="revize" v-model="localFormData.revize" class="form-control" rows="5"></textarea>
        <label for="revize">Revize</label> 
      </div>	
        <span v-if="formHatalar.revize" class="error">{{ formHatalar.revize }}</span>
      
    
  
    
    
    
      <div class="col-12  d-flex justify-content-start mb-1" :class="{ 'text-danger': formHatalar.yapildi }">  
        <label >Yapıldı mı ?</label> 
        <Toggle  v-model="localFormData.yapildi" />	
        
      </div>
      <span v-if="formHatalar.yapildi" class="error">{{ formHatalar.yapildi }}</span>
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";





import Toggle from '@vueform/toggle';


export default {
components: {

  
  
  
    Toggle,
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  isler: [],
  
  isdosyalar: [],
  
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  this.islerGetir();
  
  this.isdosyalarGetir();
  
   

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          if(this.localFormData.yapildi=='1'){
            this.localFormData.yapildi=true;
            }else{
              this.localFormData.yapildi=false;
            }
          
          
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  async islerGetir() {
    try {
      const response = await axiosCustom.get("isler"); 
      this.isler = response.data;
    } catch (error) {
      console.error("isler alma hatası: ", error);
    }
  },
  
  async isdosyalarGetir() {
    try {
      const response = await axiosCustom.get("isdosyalar"); 
      this.isdosyalar = response.data;
    } catch (error) {
      console.error("isdosyalar alma hatası: ", error);
    }
  },
  
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.revize) {
      this.formHatalar.revize = "Revize boş bırakılamaz.";
      kontrol=false;
    }
    
    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("revize", this.localFormData.revize);
  
  
  newFormData.append("is", this.localFormData.is);


  newFormData.append("yapildi",  this.localFormData.yapildi==true ? '1' : '0');

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("revizeler/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("Revize Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("revizeler/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni Revize Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
},
};
</script>