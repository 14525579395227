<!-- eslint-disable vue/no-mutating-props -->
<template>
  
  <h5 class="card-title" v-if="formIslem === 'duzenle'">Müşteri Düzenleme</h5>
  <h5 class="card-title" v-if="formIslem === 'ekle'">Yeni Müşteri Ekle</h5>
                 
  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.unvan }"> 
        <input id="unvan" v-model="localFormData.unvan" class="form-control"/>
        <label for="unvan">Ünvan</label> 
      </div>	
        <span v-if="formHatalar.unvan" class="error">{{ formHatalar.unvan }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.telefon }"> 
        <input id="telefon" v-model="localFormData.telefon" class="form-control"/>
        <label for="telefon">Telefon</label> 
      </div>	
        <span v-if="formHatalar.telefon" class="error">{{ formHatalar.telefon }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.email }"> 
        <input id="email" v-model="localFormData.email" class="form-control"/>
        <label for="email">E-mail</label> 
      </div>	
        <span v-if="formHatalar.email" class="error">{{ formHatalar.email }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.adres }"> 
        <input id="adres" v-model="localFormData.adres" class="form-control"/>
        <label for="adres">Adres</label> 
      </div>	
        <span v-if="formHatalar.adres" class="error">{{ formHatalar.adres }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.kullaniciadi }"> 
        <input id="kullaniciadi" v-model="localFormData.kullaniciadi" class="form-control"/>
        <label for="kullaniciadi">Kullanıcı Adı</label> 
      </div>	
        <span v-if="formHatalar.kullaniciadi" class="error">{{ formHatalar.kullaniciadi }}</span>
      
    
        <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.sifre }"> 
        <input id="sifre" v-model="localFormData.sifre" class="form-control" type="password"/>
        <label for="sifre">Şifre</label> 
      </div>	
        <span v-if="formHatalar.sifre" class="error">{{ formHatalar.sifre }}</span>

        <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.sifre2 }"> 
          <input id="sifre2" v-model="localFormData.sifre2" class="form-control" type="password"/>
          <label for="sifre2">Şifre Tekrarı</label> 
        </div>	
        <span v-if="formHatalar.sifre2" class="error">{{ formHatalar.sifre2 }}</span>
    
    
    
    
    <div class="col-12 mb-1" :class="{ 'text-danger': formHatalar.logo }"> 
       
        <picture-input 
          ref="profil"
          width="600" 
          height="600" 
          margin="16" 
          accept="image/jpeg,image/png" 
          size="10" 
          button-class="btn"
          :custom-strings="{
            upload: '<h1>Logo</h1>',
            drag: 'Tıklayın veya sürükleyip bırakın'
          }"
          @change="logoDegistir">
        </picture-input>
      </div>	
        <span v-if="formHatalar.logo" class="error">{{ formHatalar.logo }}</span>
   
    
    
      <div class="form-group col-12 mb-1" :class="{ 'text-danger': formHatalar.dosyalar }"> 
              <label for="dosyalar">Dosyalar</label> 
              <MultiFileCompenent :seciliDosyalar="seciliDosyalar" :yuklenmisDosyalar="yuklenmisDosyalar" :tabloid="localFormData.id"  @dosya-islem="seciliDosyalarDegistir"  @dosya-sil-islem="yukluDosyalarSil"></MultiFileCompenent>
              <span v-if="formHatalar.dosyalar" class="error">{{ formHatalar.dosyalar }}</span>
     </div>	
    
    

     
    </div>

    
    <div  class="col-12" v-if="isUploading">
      {{ uploadFileNmae }} Dosyası Yükleniyor
    
      <div class="progress" >
          <div class="progress-bar" role="progressbar" :style="{ width: `${uploadProgress}%` }">
            {{ uploadProgress }}%
        </div>
      </div>

    </div>
    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";




import PictureInput from 'vue-picture-input'


import MultiFileCompenent from "@/components/anasayfa/MultiFileCompenent.vue";




export default {
components: {
  
  
  
    PictureInput,
  
  
    MultiFileCompenent,
  
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    logoSecili: null,
    
    
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    
    seciliDosyalar:[],
    yuklenmisDosyalar:[],

    isUploading: false,
    uploadProgress: 0,
    uploadFileName:'',
    

  };
},
created() {
  
   

  
  this.yuklenmisDosyalarGetir();
  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };
          this.localFormData.sifre='';
  
          
          
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.unvan) {
      this.formHatalar.unvan = "Ünvan boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.telefon) {
      this.formHatalar.telefon = "Telefon boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.email) {
      this.formHatalar.email = "E-mail boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.adres) {
      this.formHatalar.adres = "Adres boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.kullaniciadi) {
      this.formHatalar.kullaniciadi = "Kullanıcı Adı boş bırakılamaz.";
      kontrol=false;
    }
    
  
  
  
   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("unvan", this.localFormData.unvan);newFormData.append("telefon", this.localFormData.telefon);newFormData.append("email", this.localFormData.email);newFormData.append("adres", this.localFormData.adres);newFormData.append("kullaniciadi", this.localFormData.kullaniciadi);
  
  newFormData.append("sifre", this.localFormData.sifre);
  newFormData.append("sifre2", this.localFormData.sifre2);
  
  
   
  newFormData.append("logo",  this.logoSecili);
  

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("musteriler/duzenle/" + this.localFormData.id, newFormData)
      .then((response) => {
        
        this.cokluDosyalarYukle(response.data.id);
        

        this.$toast.success("Müşteri Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("musteriler/ekle", newFormData)
      .then((response) => {
        
        this.cokluDosyalarYukle(response.data.id);
        

        this.$toast.success("Yeni Müşteri Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  
  async yuklenmisDosyalarGetir() {
      try {
        const response = await axiosCustom.get("musteriler/dosyalar");
        this.yuklenmisDosyalar = response.data;
      } catch (error) {
        console.error("dosya alma hatası: ", error);
      }
    },

  seciliDosyalarDegistir({ files, index, type }) {
    if (type === "ekle") {
      for (let i = 0; i < files.length; i++) {
        this.seciliDosyalar.push(files[i]);
      }
    } else if (type === "kaldır") {
      this.seciliDosyalar.splice(index, 1);
    }
  },

    async cokluDosyalarYukle(id) {
    const CHUNK_SIZE = 1024 * 1024; // 1 MB

    for (const file of this.seciliDosyalar) {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        for (let chunk = 0; chunk < totalChunks; chunk++) {
            const start = chunk * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);

            const chunkData = file.slice(start, end);

            let formData = new FormData();
            formData.append("fileName", file.name);
            this.uploadFileName = file.name;
            formData.append("fileChunk", chunkData);
            formData.append("currentChunk", chunk);
            formData.append("totalChunks", totalChunks);

            try {
                await axiosCustom.post("musteriler/dosyalaryukle/" + id, formData);
                this.isUploading = true;
            
                this.uploadProgress = (chunk * totalChunks) / 100;
            } catch (error) {
                console.error('dosyalar yükleme hatası: ', error);
                this.$toast.error('dosyalar yükleme hatası');
                // Handle error
                return;
            }
        }
    }

    // Tüm dosyaların tüm chunk'ları yüklendiyse, işlemi tamamla
    this.isUploading = false;
    this.uploadProgress = 100;
    },

      async yukludosyalarSil(id) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("musteriler/dosyalarsil/" + id);
          this.$toast.success("Dosyalar Silindi");
          this.yuklenmisDosyalarGetir();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },

  

  
  logoDegistir(event) {
        // Dosya seçildiğinde bu metod çağrılır
        this.logoSecili = event.target.files[0];

        // Resmin önizlemesini gösterme
        if (this.logoSecili) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.logoOnizleme = e.target.result;
            };

            reader.readAsDataURL(this.logoSecili);
        }
    },
    
},
};
</script>