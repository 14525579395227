<template>

<div class="col">
            <div class="row">
         
        
                <!-- revize  liste -->
                
                <div class="mb-2" v-for="revize in paginatedrevizeler" :key="revize.id">
                                    <label class="form-check w-100 checked-line-through checked-opacity-75" @click.prevent="duzenle(revize)">
                                    <input type="checkbox" class="form-check-input" :checked="revize.yapildi == 1"  >
                                    <span class="form-check-label d-block">
                                    <span>{{ revize.revize }}</span>
                                    <span class="text-muted d-block text-small mt-0"> {{ revize.olusturma_tarih }}</span>
                                    
                                  </span>
                                 
                                    </label>
                                  
                  </div>

          
            
                <!-- revize  liste -->
          
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === revizeler.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="revizelerSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    revizeler:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  computed: {
    filteredrevizeler() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.revizeler.tumu.filter(
        (revize) =>

          revize.revize.toLowerCase() 
          
          .includes(this.revizeler.arananKelime.toLowerCase()) ||revize.revize.toLowerCase()
          
          
          .includes(this.revizeler.arananKelime.toLowerCase())


    
      );
    },
    paginatedrevizeler() {
      const start = (this.revizeler.seciliSayfa - 1) * this.revizeler.sayfadaKayit;
      const end = start + this.revizeler.sayfadaKayit;
      return this.filteredrevizeler.slice(start, end);
    },
    revizeler_toplamSayfa() {
      const totalFilteredUsers = this.filteredrevizeler.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.revizeler.sayfadaKayit), 1);
      if (this.revizeler.seciliSayfa > maxPage) {
        this.revizelerSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.revizeler_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.revizeler.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(revizeId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("revizeler/sil/" + revizeId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    revizelerSayfaDegistir(page) {
      this.$emit('update:revizeler',{
      ...this.revizeler,
      seciliSayfa: page
    });
    },
    revizelerSayfadaKayitDegistir(value) {
      this.$emit('update:revizeler',{
      ...this.revizeler,
      sayfadaKayit: value
    });
      this.revizelerSayfaDegistir(1);
    },
  },
};
</script>
