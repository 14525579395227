<template>
    <div
      id="nav"
      :class="navContainerSiniflar"
        @mouseover="menuGoster" 
        @mouseleave="menuGizle"
        data-vertical-unpinned="10000"
        data-vertical-mobile="600"
        data-disable-pinning="true"
    >
      <div class="nav-content d-flex">
        <div class="logo position-relative">
          <router-link to="/musteripanel">
            <div class="img"></div>
          </router-link>
        
        
                   

        </div>
    
  
        <div :class="menuContainerSiniflar" @mouseover="menuGoster"
      @mouseleave="menuGizle" >
          <div class="os-resize-observer-host observed">
            <div class="os-resize-observer" style="left: 0px; right: auto"></div>
          </div>
          <div
            class="os-size-auto-observer observed"
            style="height: calc(100% + 1px); float: left"
          >
            <div class="os-resize-observer"></div>
          </div>
          <div
            class="os-content-glue"
            style="margin: 0px; width: 79px; height: 999px"
          ></div>
          <div class="os-padding">
            <div class="os-viewport os-viewport-native-scrollbars-invisible">
              <div
                class="os-content"
                style="padding: 0px; height: 100%; width: 100%">
                <ul id="menu" class="menu show">
                  <li>
                    <router-link to="/musteripanel"  :class="{ active: $route.name === 'musteripanel' }" >
                      <unicon name="estate" class="icon" fill="white"></unicon>
                      <span class="label">Anasayfa</span>
                    </router-link>
                  </li>
                  

                    <li>
                    <a @click="this.$globalVariable.cikisYap">
                      <unicon name="sign-out-alt" class="icon" fill="white"></unicon>
                      <span class="label">Çıkış Yap</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"
          >
            <div class="os-scrollbar-track os-scrollbar-track-off">
              <div
                class="os-scrollbar-handle"
                style="width: 53.6913%; transform: translate(0px, 0px)"
              ></div>
            </div>
          </div>
          <div
            class="os-scrollbar os-scrollbar-vertical os-scrollbar-unusable os-scrollbar-auto-hidden"
          >
            <div class="os-scrollbar-track os-scrollbar-track-off">
              <div
                class="os-scrollbar-handle"
                style="height: 100%; transform: translate(0px, 0px)"
              ></div>
            </div>
          </div>
          <div class="os-scrollbar-corner"></div>
        </div>
        <div class="mobile-buttons-container">
          <router-link to="/" id="scrollSpyButton" class="spy-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="acorn-icons acorn-icons-menu-dropdown undefined"
            >
              <path
                d="M2 3 18 3M2 10 18 10M13 15 10.3536 17.6464C10.1583 17.8417 9.84171 17.8417 9.64645 17.6464L7 15"
              ></path>
            </svg>
          </router-link>
          <div
            class="dropdown-menu dropdown-menu-end"
            id="scrollSpyDropdown"
          ></div>
          <router-link to="#" id="mobileMenuButton" class="menu-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="acorn-icons acorn-icons-menu undefined"
            >
              <path d="M2 3 18 3M2 10 18 10M2 17 18 17"></path>
            </svg>
          </router-link>
        </div>
      </div>
      <div class="nav-shadow"></div>
    </div>
    <main>
      <button class="btn btn-icon btn-icon-only btn-foreground hover-outline mb-2"   @click="toggleMenu">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-arrow-double-right mb-3 d-inline-block text-primary" v-if="menuGorunurluk===false">
        <path d="M8 3L14.6464 9.64645C14.8417 9.84171 14.8417 10.1583 14.6464 10.3536L8 17"></path>
        <path d="M5 6L8.64645 9.64645C8.84171 9.84171 8.84171 10.1583 8.64645 10.3536L5 14"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-arrow-double-left mb-3 d-inline-block text-primary" v-if="menuGorunurluk===true">
        <path d="M12 17L5.35355 10.3536C5.15829 10.1583 5.15829 9.84171 5.35355 9.64645L12 3"></path>
        <path d="M15 14L11.3536 10.3536C11.1583 10.1583 11.1583 9.84171 11.3536 9.64645L15 6"></path>
      </svg>
    </button>

    <button class="btn btn-icon btn-icon-only btn-foreground hover-outline mb-2 ml-2"   @click="darkMode">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-arrow-double-left mb-3 d-inline-block text-primary"><path d="M8 14L8.28283 16.4684C8.35276 17.0786 8.38772 17.3837 8.55097 17.6009C8.63047 17.7067 8.73017 17.7956 8.8443 17.8625C9.07862 18 9.38575 18 10 18V18C10.6143 18 10.9214 18 11.1557 17.8625C11.2698 17.7956 11.3695 17.7067 11.449 17.6009C11.6123 17.3837 11.6472 17.0786 11.7172 16.4684L12 14"></path><path d="M15 7C15 10.1518 13.5 10.5 13 11.5C12.5 12.5 12.6096 14.5 10 14.5C7.39038 14.5 7.5 12.5 7 11.5C6.5 10.5 5 10.1518 5 7C5 4.23858 7.23858 2 10 2C12.7614 2 15 4.23858 15 7Z"></path></svg>
    </button>
      
      <slot></slot></main>
  </template>
  
  <script>
  


  export default {
    data() {
      return {
        menuGorunurluk: false,
        darkmode : false,
      };
    },
    computed: {
    menuContainerSiniflar() {
      return {
        "menu-container": true,
        "flex-grow-1": true,
        "os-host": true,
        "os-theme-dark": true,
        "os-host-resize-disabled": true,
        "os-host-scrollbar-horizontal-hidden": true,
        "os-host-scrollbar-vertical-hidden": true,
        "os-host-transition": true,
        "os-host-overflow": !this.menuGorunurluk,
        "os-host-overflow-x": !this.menuGorunurluk,
      };
    },
    navContainerSiniflar() {
      return {
        "nav-container": true,
        goster: this.menuGorunurluk,
        "d-flex": true,
      };
    },
  },

  mounted() {

      if(!localStorage.getItem('musteritoken')){
        this.$router.push({ name: 'Login' });
      }

        
    },
    methods: {
      menuGoster() {
      this.menuGorunurluk = true;

    },
    darkMode() {
      
      if(this.darkmode==false){
        document.getElementById("html").setAttribute('data-color', 'dark-red');
      this.darkmode = true;
      }else{
        document.getElementById("html").setAttribute('data-color', 'red');
       this.darkmode = false;
      }
    
    },
    menuGizle() {
        this.menuGorunurluk = false;

    },
    toggleMenu() {
      this.menuGorunurluk = !this.menuGorunurluk; 
    },
    
      
    },
  };
  </script>
  