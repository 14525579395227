<template>
  <div>
    <input type="file" class="form-control"  @change="dosyaIslem" multiple />
    <div class="mt-2">
      <div
        v-for="(file, index) in seciliDosyalar"
        :key="index"
        class="d-flex justify-content-between align-items-center"
      >
        <span>{{ file.name }}</span>
        <button
          type="button"
          class="btn btn-sm btn-danger"
          @click="dosyaIslem(index)"
        >
          Kaldır
        </button>
      </div>

      <b  class="pt-4" v-if="yuklenmisDosyalar.length>0">Yüklenmiş Dosyalar</b>
      <template  v-for="(file, index) in yuklenmisDosyalar" :key="index">
        <div 
       v-if="file.tabloid==tabloid"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          <span><strong>Ad:</strong> {{ file.ad }}</span>
        
        </div>
        <button
          type="button"
          class="btn btn-sm btn-danger"
          @click="dosyaSilIslem(file.id)"
        >
          Sil
        </button>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    seciliDosyalar: {
      type: Array,
      default: () => [],
    },
    yuklenmisDosyalar: {
      type: Array,
      default: () => [],
    },
    tabloid: {
      type: String,
      default: '0',
    },
    
  },
  methods: {
    dosyaIslem(eventOrIndex) {
      if (eventOrIndex instanceof Event) {
        // Dosya seçildiğinde
        const files = eventOrIndex.target.files;
        this.$emit("dosya-islem", { files, type: "ekle" });
      } else {
        // Kaldır düğmesine basıldığında
        this.$emit("dosya-islem", { index: eventOrIndex, type: "kaldır" });
      }
    },

    dosyaSilIslem(id) {
      this.$emit("dosya-sil-islem",  id );
    },
  },
};
</script>

