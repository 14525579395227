<template>
    <div class="profil">
        <AppMain>
            <div class="container">
                <div class="page-title-container">
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="mb-0 pb-0 display-4" id="title">Profilim</h1>
                            <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                                <ul class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Anasayfa</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Profil</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-4 col-xxl-3">
                        <h2 class="small-title">Profil</h2>
                        <div class="card mb-5">
                            <div class="card-body">
                                <div class="d-flex align-items-center flex-column mb-4">
                                    <div class="d-flex align-items-center flex-column">
                                        <div class="sw-13 position-relative mb-3">
                                            <img :src="$globalVariable.uploadUrl + profil.profil"
                                                @error="$globalVariable.varsayilanGorsel" class="img-fluid rounded-xl"
                                                alt="thumb">
                                        </div>
                                        <div class="h5 mb-0">{{ profil.ad }} {{ profil.soyad }}</div>
                                        <div class="text-muted">{{ profil.rol }}</div>

                                    </div>
                                </div>
                                <div class="nav flex-column" role="tablist">
                                    <a class="nav-link px-0 border-bottom border-separator-light active"
                                        data-bs-toggle="tab" href="#bilgilerTab" role="tab" aria-selected="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"
                                            fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" class="acorn-icons acorn-icons-activity me-2">
                                            <path
                                                d="M2 10H4.82798C5.04879 10 5.24345 10.1448 5.3069 10.3563L7.10654 16.3551C7.25028 16.8343 7.93071 16.8287 8.06664 16.3473L11.905 2.75299C12.0432 2.26379 12.7384 2.26886 12.8693 2.76003L14.701 9.62883C14.7594 9.84771 14.9576 10 15.1841 10H18">
                                            </path>
                                        </svg>
                                        <span class="align-middle">Bilgiler</span>
                                    </a>
                                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab"
                                        href="#hareketlerTab" role="tab" aria-selected="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"
                                            fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" class="acorn-icons acorn-icons-office me-2">
                                            <path
                                                d="M3 8L3.18483 9.84826C3.31243 11.1243 3.37623 11.7623 3.71943 12.2125C3.8685 12.408 4.05235 12.5744 4.26175 12.7033C4.74387 13 5.38507 13 6.66746 13H13.3325C14.6149 13 15.2561 13 15.7383 12.7033C15.9477 12.5744 16.1315 12.408 16.2806 12.2125C16.6238 11.7623 16.6876 11.1243 16.8152 9.84826L17 8">
                                            </path>
                                            <path
                                                d="M13.8838 8.14084C13.9301 8.8824 13.9533 9.25318 13.7881 9.52272C13.7167 9.63915 13.6222 9.73972 13.5104 9.81824C13.2517 10 12.8802 10 12.1372 10L7.86279 10C7.11978 10 6.74828 10 6.48957 9.81824C6.37782 9.73972 6.28334 9.63915 6.21195 9.52272C6.04668 9.25318 6.06985 8.8824 6.1162 8.14084L6.39745 3.64084C6.43883 2.97866 6.45953 2.64758 6.63028 2.41242C6.70436 2.31041 6.79706 2.22332 6.90349 2.15575C7.14884 2 7.48057 2 8.14404 2L11.856 2C12.5194 2 12.8512 2 13.0965 2.15575C13.2029 2.22332 13.2956 2.31041 13.3697 2.41242C13.5405 2.64758 13.5612 2.97866 13.6026 3.64084L13.8838 8.14084Z">
                                            </path>
                                            <path d="M10 10 10 15.5M10 15.5 5 17M10 15.5 15 17"></path>
                                            <path
                                                d="M14 17C14 16.4477 14.4477 16 15 16V16C15.5523 16 16 16.4477 16 17V17C16 17.5523 15.5523 18 15 18V18C14.4477 18 14 17.5523 14 17V17zM4 17C4 16.4477 4.44772 16 5 16V16C5.55228 16 6 16.4477 6 17V17C6 17.5523 5.55228 18 5 18V18C4.44772 18 4 17.5523 4 17V17z">
                                            </path>
                                        </svg>
                                        <span class="align-middle">Aktiviteler</span>
                                    </a>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-8 col-xxl-9 mb-5 tab-content">
                        <div class="tab-pane fade active show" id="bilgilerTab" role="tabpanel">
                            <h2 class="small-title">Bilgilerim</h2>
                            <div class="card mb-5">
                                <div class="card-body">
                                    <form @submit.prevent="guncelle">

                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.profil }"> 
                                            <label  class="col-lg-2 col-md-3 col-sm-4 col-form-label" for="profil">Profil</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <picture-input 
                                                ref="profil"
                                                width="300" 
                                                height="300" 
                                                margin="16" 
                                                accept="image/jpeg,image/png" 
                                                size="10" 
                                                button-class="btn"
                                                :custom-strings="{
                                                    upload: '<h1>Profil</h1>',
                                                    drag: 'Tıklayın veya sürükleyip bırakın'
                                                }"
                                                @change="profilDegistir">
                                                </picture-input>
                                                <span v-if="formHatalar.profil" class="error">{{ formHatalar.profil }}</span>
                                            </div>	
                                        </div>	

                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.ad }"> 
                                            <label for="ad" class="col-lg-2 col-md-3 col-sm-4 col-form-label">Ad</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <input id="ad" v-model="profil.ad" class="form-control"/>
                                                <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
                                            </div>
                                        </div>	
                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.soyad }"> 
                                            <label for="soyad" class="col-lg-2 col-md-3 col-sm-4 col-form-label">Soyad</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <input id="ad" v-model="profil.soyad" class="form-control"/>
                                                <span v-if="formHatalar.soyad" class="error">{{ formHatalar.soyad }}</span>
                                            </div>
                                        </div>
                                        
                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.kullaniciadi }"> 
                                            <label for="kullaniciadi" class="col-lg-2 col-md-3 col-sm-4 col-form-label">Kullanıcı Adı</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <input id="ad" v-model="profil.kullaniciadi" class="form-control"/>
                                                <span v-if="formHatalar.kullaniciadi" class="error">{{ formHatalar.kullaniciadi }}</span>
                                            </div>
                                        </div>
                                        
                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.sifre }"> 
                                            <label for="sifre" class="col-lg-2 col-md-3 col-sm-4 col-form-label">Yeni Şifre</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <input id="ad" v-model="profil.sifre" class="form-control"/>
                                                <span v-if="formHatalar.sifre" class="error">{{ formHatalar.sifre }}</span>
                                            </div>
                                        </div>

                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.sifre2 }"> 
                                            <label for="sifre2" class="col-lg-2 col-md-3 col-sm-4 col-form-label">Yeni Şifre Tekrarı</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <input id="ad" v-model="profil.sifre2" class="form-control"/>
                                                <span v-if="formHatalar.sifre2" class="error">{{ formHatalar.sifre2 }}</span>
                                            </div>
                                        </div>
                                        
                                    
                                        
                                       	
                                        <div class="mb-3 row" :class="{ 'text-danger': formHatalar.telefon }"> 
                                            <label for="telefon" class="col-lg-2 col-md-3 col-sm-4 col-form-label">Telefon</label> 
                                            <div class="col-sm-8 col-md-9 col-lg-10">
                                                <input id="ad" v-model="profil.telefon" class="form-control"/>
                                                <span v-if="formHatalar.telefon" class="error">{{ formHatalar.telefon }}</span>
                                            </div>
                                        </div>

                              
                                      
                                    
                                        <div class="mb-3 row mt-5">
                                            <div class="col-sm-8 col-md-9 col-lg-10 ms-auto">
                                                <button class="btn btn-primary" type="submit">Güncelle</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane active"  id="hareketlerTab">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">Hareketlerim</h2>
                                    <div class="card mb-5">
                                            <div class="card-body">

                                                <div class="row g-0" v-for="hareket in paginatedHareketler" :key="hareket.id">
                                                    <div
                                                        class="col-auto sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                                                        <div class="w-100 d-flex sh-1"></div>
                                                        <div
                                                            class="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                                                            <div class="bg-gradient-light sw-1 sh-1 rounded-xl position-relative"></div>
                                                        </div>
                                                        <div class="w-100 d-flex h-100 justify-content-center position-relative">
                                                            <div class="line-w-1 bg-separator h-100 position-absolute"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col mb-4">
                                                        <div class="h-100">
                                                            <div class="d-flex flex-column justify-content-start">
                                                                <div class="d-flex flex-column">
                                                                    <a href="#" class="heading stretched-link">{{ hareket.aciklama }}</a>
                                                                    <div class="text-alternate">{{ hareket.tarih }}</div>
                                                                    <div class="text-muted mt-1">
                                                                        {{ hareket.tarayici }}-{{ hareket.ip }}-{{ hareket.url }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                
                                </div>
                            </div>
                            <div class="row mt-5">
                        <div class="col-12 text-center">
                            <!-- Sayfalama -->
                            <nav>
                            <ul class="pagination">
                                <li class="page-item" :class="{ active: page === hareketSeciliSayfa }" v-for="page in hareketler_pages" :key="page">
                                <a class="page-link" @click="hareketlerSayfaDegistir(page)">{{
                                    page
                                }}</a>
                                </li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                        </div>


                    </div>
                </div>

            </div>
        </AppMain>
    </div>
</template>
  
<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';
import AppMain from "@/components/AppMain.vue";
import PictureInput from 'vue-picture-input'
export default {
    name: "ProfilView",
    components: {
        AppMain,
        PictureInput,

    },
    data() {
        return {
            profil: [],
            hareketler: [],
            hareketSayfadaKayit: 10,
            hareketSeciliSayfa: 1,

            formHatalar:{},
            profilSecili: null,
        };
    },
    computed: {

paginatedHareketler() {
    if (!this.hareketler) {
        return [];
    }
const start = (this.hareketSeciliSayfa - 1) * this.hareketSayfadaKayit;
const end = start + this.hareketSayfadaKayit;
var hareketler=this.hareketler;
return hareketler.slice(start, end);
},
hareketler_toplamSayfa() {
    if (!this.hareketler) {
        return [];
    }
const toplamHareket = this.hareketler.length;
const maxPage = Math.max(Math.ceil(toplamHareket / this.hareketSayfadaKayit), 1);
if (this.hareketSeciliSayfa > maxPage) {
    this.kullanicilarSayfaDegistir(maxPage);
}
return maxPage;
},

hareketler_pages() {
    const totalPageCount = this.hareketler_toplamSayfa;
    const maxVisiblePages = 5;
    const currentPage = this.hareketSeciliSayfa;

    if (totalPageCount <= maxVisiblePages) {
        return Array.from({ length: totalPageCount }, (_, index) => index + 1);
    }

    const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

    return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
},
},
    mounted() {
        this.profilGetir();
        this.hareketlerGetir();
    },
    methods: {
        yetki_kontrol(yetki) {
            return yetkiKontrol(yetki);
        },
        profilGetir() {
            axiosCustom
                .get("profil")
                .then((response) => {
                    this.profil = response.data;
                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },
        hareketlerGetir() {
            axiosCustom
                .get("profil/hareketler")
                .then((response) => {
                this.hareketler = response.data;
                })
                .catch((error) => {
                console.error("Veri çekme hatası: ", error);
                });
            },
        hareketlerSayfaDegistir(page) {
            this.hareketSeciliSayfa= page
        },
        formDogrula() {
        this.formHatalar = {};
        var kontrol=true;

        if (!this.profil.ad) {
          this.formHatalar.ad = "Ad boş bırakılamaz.";
          kontrol=false;
        }

        if (!this.profil.soyad) {
          this.formHatalar.soyad = "Soyad boş bırakılamaz.";
          kontrol=false;
        }
        
        if (!this.profil.kullaniciadi) {
          this.formHatalar.kullaniciadi = "Kullanıcı Adı boş bırakılamaz.";
          kontrol=false;
        }
        if (this.profil.sifre!=this.profil.sifre2) {
          this.formHatalar.sifre = "Şifreler Uyuşmuyor";
          this.formHatalar.sifre2 = "Şifreler Uyuşmuyor";
          kontrol=false;
        }
        

        return kontrol;
      },
      async  guncelle() {
        if(this.formDogrula()){
          const newFormData = new FormData();
          newFormData.append("ad", this.profil.ad);
          newFormData.append("soyad", this.profil.soyad);
          newFormData.append("kullaniciadi", this.profil.kullaniciadi);
          newFormData.append("sifre", this.profil.sifre);
          newFormData.append("sifre2", this.profil.sifre2);
          newFormData.append("telefon", this.profil.telefon);
  
          newFormData.append("profil",  this.profilSecili);
          
          try {
          
            axiosCustom
              .post("profil/duzenle/", newFormData)
              .then(() => {
                this.$toast.success("Profil Düzenlendi");
                this.profilGetir();
              })
              .catch((error) => {
                console.error("Güncelleme hatası: ", error);
                this.$toast.error("Güncelleme hatası");
              });
       
        } catch (error) {
            console.error('Kaydetme hatası: ', error);
            this.$toast.error('Kaydetme hatası');
          }
        }
      },
      profilDegistir(event) {
            // Dosya seçildiğinde bu metod çağrılır
            this.profilSecili = event.target.files[0];

            // Resmin önizlemesini gösterme
            if (this.profilSecili) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.profilOnizleme = e.target.result;
                };

                reader.readAsDataURL(this.profilSecili);
            }
        },

    },
};
</script>
  