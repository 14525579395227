<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="Musteri_detay">
        <AppMain>
            <div class="container">
                <div class="page-title-container">
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="mb-0 pb-0 display-4" id="title">İş Detay</h1>
                            <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                                <ul class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Anasayfa</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/isler">İşler</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/">İş Detay</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">

                            <div class="col-12 col-md-5 d-flex align-items-start justify-content-end gap-2">

                                <button type="button" v-if="yetki_kontrol('is_duzenle')"
                                    class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                    @click="isler_duzenle">
                                    <i data-acorn-icon="pencil"></i>
                                    <span>İşi Düzenle</span>
                                </button>


                                <button type="button" v-if="yetki_kontrol('revize_duzenle')"
                                    class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                    @click="revizeler_ekle">
                                    <i data-acorn-icon="pencil"></i>
                                    <span>Revize Ekle</span>
                                </button>

                                <button type="button" v-if="yetki_kontrol('isadim_duzenle')"
                                    class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                    @click="isadimlar_ekle">
                                    <i data-acorn-icon="pencil"></i>
                                    <span>İş Adım Ekle</span>
                                </button>

                                <button type="button" v-if="yetki_kontrol('isdurum_duzenle')"
                                    class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                    @click="isdurumlar_ekle">
                                    <i data-acorn-icon="pencil"></i>
                                    <span>İş Durum Ekle</span>
                                </button>



                                <button type="button"
                                    class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                    @click="dosyalar_ekle">
                                    <i data-acorn-icon="pencil"></i>
                                    <span>Dosya Ekle</span>
                                </button>

                                <button type="button"
                                    class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                    @click="calismalar_ekle">
                                    <i data-acorn-icon="pencil"></i>
                                    <span>Çalışma Ekle</span>
                                </button>


                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-12 col-xl-4 ">

                        <h2 class="small-title">İş</h2>
                        <div class="card  mb-4">
                            <div class="card-body">
                                <div class="d-flex align-items-center flex-column mb-4">
                                    <div class="d-flex align-items-center flex-column">
                                        <div class="h5 mb-0">{{ isler.ad }}</div>
                                    </div>
                                </div>


                                <div class="mb-5">
                                    <p class="text-muted ms-2 mt-n1 lh-1-25">İş Bilgiler</p>

                                    <div class="mb-1">
                                        <p class="text-small text-muted mb-1">Ad</p>
                                        <p>
                                            {{ isler.ad }}
                                        </p>
                                    </div>
                                    <div class="mb-1">
                                        <p class="text-small text-muted mb-1">Açıklama</p>
                                        <p>
                                            {{ isler.aciklama }}
                                        </p>
                                    </div>
                                    <div class="mb-1">
                                        <p class="text-small text-muted mb-1">İş Başlama Tarihi : </p>
                                        <p>
                                            {{ isler.baslamatarih }}
                                        </p>
                                    </div>
                                    <div class="mb-1">
                                        <p class="text-small text-muted mb-1">İş Bitiş Tarihi : </p>
                                        <p>
                                            {{ isler.bitistarih }}
                                        </p>
                                    </div>

                                    <div class="mb-1">
                                        <p class="text-small text-muted mb-1">Durum : </p>
                                        <p>
                                            {{ getDurumAdi(isler.durum) }}
                                        </p>
                                    </div>








                                </div>

                                <div class="mb-5">
                                    <p class="text-muted ms-2 mt-n1 lh-1-25">Müşteri Bilgileri</p>


                                    <div
                                        class="row g-0 align-items-start align-content-start align-content-md-center align-items-md-center sh-5">
                                        <div class="col-auto d-flex align-items-center">
                                            <div class="sw-4">
                                                <div class="sw-4 mb-1 d-inline-block">
                                                    <img :src="$globalVariable.uploadUrl + musteriBilgileri(isler.musteri).logo"
                                                        @error="$globalVariable.varsayilanGorsel"
                                                        class="img-fluid rounded-xl border border-2 border-foreground sh-4">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div>{{ musteriBilgileri(isler.musteri).unvan }}</div>

                                        </div>
                                    </div>



                                </div>





                            </div>
                        </div>
                        <div class="card mb-5">
                            <div class="card-body">
                                <p class="text-muted ms-2 mt-n1 lh-1-25">Çalışan Bilgileri</p>

                        
                                <div v-if="isler.kullanicilar && isler.kullanicilar.length > 0" >
                                <div class="row g-0 sh-13 sh-md-6 mb-2 item" v-for="kul in isler.kullanicilar" :key="kul">
                                                                    <div class="col-auto">
                                                                        <img :src="$globalVariable.uploadUrl + kullaniciBilgileri(kul).profil"
                                                                @error="$globalVariable.varsayilanGorsel"
                                                                class="card-img rounded-xl sh-6 sw-6">
                                                                       
                                                                    </div>
                                                                    <div class="col">
                                                                        <div class="card-body pt-0 pb-0 pe-0 h-100">
                                                                            <div
                                                                                class="row g-0 h-100 align-items-start align-content-start align-content-md-center align-items-md-center">
                                                                                <div
                                                                                    class="col-12 col-md-5 d-flex align-items-center mb-md-0">
                                                                                    <div>{{ kullaniciBilgileri(kul).ad }} {{ kullaniciBilgileri(kul).soyad }}</div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-12 col-md-4 d-flex align-items-center text-muted pe-2 mb-2 mb-md-0">
                                                                                    {{ kullaniciBilgileri(kul).telefon }}</div>
                                                                                <div
                                                                                    class="col-12 col-md-3 d-flex align-items-center justify-content-md-end">
                                                                                    <button
                                                                                        class="btn btn-sm btn-icon btn-icon-only btn-outline-primary align-top float-end"
                                                                                        type="button" >
                                                                                       
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                          



                            </div>
                        </div>
                       
                                <h2 class="small-title">Durumlar</h2>
                                <isdurumCard :isdurumlar="isdurumlar" :duzenle="isdurumlar_duzenle"
                                        :formKaydetSonrasi="islerDetayGetir" @update:isdurumlar="isdurumlarGuncelle" />





                                    <div class="modal fade modal-close-out show" style="display: block;"
                                        v-if="isdurumlar.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" v-if="isdurumlar.islem == 'ekle'"> Yeni İş Durum
                                                        Ekle </h5>
                                                    <h5 class="modal-title" v-if="isdurumlar.islem == 'duzenle'">İş Durum
                                                        Düzenleme </h5>
                                                    <button type="button" class="btn-close"
                                                        @click="isdurumlar_iptal"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <isdurumForm :formIslem="isdurumlar.islem" :formData="isdurumlar.secili"
                                                        :formKaydetSonrasi="islerDetayGetir"
                                                        :formIptal="isdurumlar_iptal" />


                                                </div>
                                            </div>

                                        </div>
                                    </div>


      
                                         
                          
                            

                    </div>


                    <div class="col-12 col-xl-8">
                        <div class="row g-2 mb-5">
                            <h2 class="small-title">Durumlar</h2>
                            <div class="col-12 col-lg-3 mb-2">
                                    <div class="card h-100">
                                        <div class="h-100 row g-0 card-body align-items-center">
                                            <div class="col-auto">
                                                <div class="bg-gradient-light sw-6 sh-6 rounded-md d-flex justify-content-center align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-loaf text-white"><path d="M15 3.75C15 3.04777 15 2.69665 14.8315 2.44443C14.7585 2.33524 14.6648 2.24149 14.5556 2.16853C14.3033 2 13.9522 2 13.25 2H6.75C6.04777 2 5.69665 2 5.44443 2.16853C5.33524 2.24149 5.24149 2.33524 5.16853 2.44443C5 2.69665 5 3.04777 5 3.75V9.25C5 9.95223 5 10.3033 5.16853 10.5556C5.24149 10.6648 5.33524 10.7585 5.44443 10.8315C5.69665 11 6.04777 11 6.75 11H13.25C13.9522 11 14.3033 11 14.5556 10.8315C14.6648 10.7585 14.7585 10.6648 14.8315 10.5556C15 10.3033 15 9.95223 15 9.25V3.75Z"></path><path d="M10 11 10 13M8 2 8 5M12 2 12 5M14.5 8 5.5 8"></path><path d="M10.125 13C10.4761 13 10.6517 13 10.7778 13.0843C10.8324 13.1207 10.8793 13.1676 10.9157 13.2222C11 13.3483 11 13.5239 11 13.875L11 17.125C11 17.4761 11 17.6517 10.9157 17.7778C10.8793 17.8324 10.8324 17.8793 10.7778 17.9157C10.6517 18 10.4761 18 10.125 18L9.875 18C9.52388 18 9.34833 18 9.22221 17.9157C9.16762 17.8793 9.12074 17.8324 9.08427 17.7778C9 17.6517 9 17.4761 9 17.125L9 13.875C9 13.5239 9 13.3483 9.08427 13.2222C9.12074 13.1676 9.16762 13.1207 9.22222 13.0843C9.34833 13 9.52388 13 9.875 13L10.125 13Z"></path></svg>
                                                   
                                                </div>
                                            </div>
                                            <div class="col sh-6 ps-3 d-flex flex-column justify-content-center">
                                                <div class="heading mb-0 d-flex align-items-center lh-1-25">Revizeler</div>
                                                <div class="row g-0">
                                                    <div class="col-auto">
                                                        <div class="cta-2 text-primary">{{ revizeler.tumu.length }}</div>
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3 mb-2">
                                    <div class="card h-100 ">
                                        <div class="h-100 row g-0 card-body align-items-center">
                                            <div class="col-auto">
                                                <div class="bg-gradient-light sw-6 sh-6 rounded-md d-flex justify-content-center align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-loaf text-white"><path d="M6.5 18H13.5C14.9045 18 15.6067 18 16.1111 17.6629C16.3295 17.517 16.517 17.3295 16.6629 17.1111C17 16.6067 17 15.9045 17 14.5V7.44975C17 6.83775 17 6.53175 16.9139 6.24786C16.8759 6.12249 16.8256 6.00117 16.7638 5.88563C16.624 5.62399 16.4076 5.40762 15.9749 4.97487L14.0251 3.02513L14.0251 3.02512C13.5924 2.59238 13.376 2.37601 13.1144 2.23616C12.9988 2.1744 12.8775 2.12415 12.7521 2.08612C12.4682 2 12.1622 2 11.5503 2H6.5C5.09554 2 4.39331 2 3.88886 2.33706C3.67048 2.48298 3.48298 2.67048 3.33706 2.88886C3 3.39331 3 4.09554 3 5.5V14.5C3 15.9045 3 16.6067 3.33706 17.1111C3.48298 17.3295 3.67048 17.517 3.88886 17.6629C4.39331 18 5.09554 18 6.5 18Z"></path><circle r="1.5" transform="matrix(-1 0 0 1 8.5 7.5)"></circle><path d="M17 13L14.142 11.2137C13.6738 10.9211 13.4397 10.7748 13.1892 10.7581C13.0799 10.7508 12.9701 10.7615 12.8643 10.7898C12.6217 10.8546 12.4204 11.0434 12.0176 11.421L5 18"></path></svg>
                                                   
                                                </div>
                                            </div>
                                            <div class="col sh-6 ps-3 d-flex flex-column justify-content-center">
                                                <div class="heading mb-0 d-flex align-items-center lh-1-25">Çalışma Sayısı</div>
                                                <div class="row g-0">
                                                    <div class="col-auto">
                                                        <div class="cta-2 text-primary">{{ yuklenmisCalismalar.length }}</div>
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            
                            <div class="col-12 col-lg-6 mb-2">
                                <div class="card h-100">
                                    <div class="card-body h-100">
                                        <div class="text-small text-muted mb-2">İş Durumu</div>
                                        <div class="cta-3 text-primary sh-3 mb-2">{{hesaplaYuzde()}}%</div>
                                        <div class="progress progress-sm">
                                            <div class="progress-bar" role="progressbar" :aria-valuenow="hesaplaYuzde"
                                                aria-valuemin="0" aria-valuemax="100" :style="{ width: hesaplaYuzde() + '%' }"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-5">
                                <h2 class="small-title">Revizeler</h2>
                                <div class="card h-xl-100-card sh-40">
                                    <div class="card-body mb-n2 scroll-out h-100">
                                    
                                        <revizeCard :revizeler="revizeler" :duzenle="revizeler_duzenle"
                                        :formKaydetSonrasi="islerDetayGetir" @update:revizeler="revizelerGuncelle" />

                                
                                    </div>
                                    </div>
                              




                                    <div class="modal fade modal-close-out show" style="display: block;"
                                        v-if="revizeler.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" v-if="revizeler.islem == 'ekle'"> Yeni Revize
                                                        Ekle </h5>
                                                    <h5 class="modal-title" v-if="revizeler.islem == 'duzenle'">Revize
                                                        Düzenleme </h5>
                                                    <button type="button" class="btn-close"
                                                        @click="revizeler_iptal"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <revizeForm :formIslem="revizeler.islem" :formData="revizeler.secili"
                                                        :formKaydetSonrasi="islerDetayGetir" :formIptal="revizeler_iptal" />


                                                </div>
                                            </div>

                                        </div>
                                    </div>


                            </div>
                            <div class="col-12 col-lg-6 mb-5">
                                <h2 class="small-title">İş Adımları</h2>
                                    <isadimCard :isadimlar="isadimlar" :duzenle="isadimlar_duzenle"
                                        :formKaydetSonrasi="islerDetayGetir" @update:isadimlar="isadimlarGuncelle" />





                                    <div class="modal fade modal-close-out show" style="display: block;"
                                        v-if="isadimlar.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" v-if="isadimlar.islem == 'ekle'"> Yeni İş Adım
                                                        Ekle </h5>
                                                    <h5 class="modal-title" v-if="isadimlar.islem == 'duzenle'">İş Adım
                                                        Düzenleme </h5>
                                                    <button type="button" class="btn-close"
                                                        @click="isadimlar_iptal"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <isadimForm :formIslem="isadimlar.islem" :formData="isadimlar.secili"
                                                        :formKaydetSonrasi="islerDetayGetir" :formIptal="isadimlar_iptal" />


                                                </div>
                                            </div>

                                        </div>
                                    </div>


                            </div>
                            <div class="col-12 col-xl-12 mb-5">
                                <h2 class="small-title">Yapılan Çalışmalar</h2>


                                <div class="modal fade modal-close-out show" style="display: block;" v-if="calismalarEkleModal">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title"> Çalışma  Ekleme </h5>
                                                            <button type="button" class="btn-close" @click="calismalar_iptal"></button>
                                                        </div>
                                                        <div class="modal-body">

                                                            <input type="file" class="form-control" @change="calismalarIslem" multiple />
                                                            <div class="mt-2">
                                                                <div v-for="(file, index) in seciliCalismalar" :key="index"
                                                                    class="d-flex justify-content-between align-items-center">
                                                                    <span>{{ file.name }}</span>
                                                                    <button type="button" class="btn btn-sm btn-danger"
                                                                        @click="calismalarIslem(index)">
                                                                        Kaldır
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div v-if="isUploadingCalismalar">
                                                                {{ uploadFileNameCalismalar }} Dosyası Yükleniyor
                                                            </div>
                                                            <div class="progress" v-if="isUploadingCalismalar">
                                                                <div class="progress-bar" role="progressbar"
                                                                    :style="{ width: `${uploadProgressCalismalar}%` }">
                                                                    {{ uploadProgressCalismalar }}%
                                                                </div>
                                                            </div>

                                                            <div class="d-flex justify-content-around gap-2 mt-4">
                                                                <button class="btn btn-outline-primary"
                                                                    @click="calismalar_iptal">İptal</button>
                                                                <button class="btn btn-primary w-100"
                                                                    @click="cokluCalismalarYukle">Kaydet</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="row">

                                                <div class="col-sm-6 col-lg-3 col-xxl-3 " v-for="(file, index) in yuklenmisCalismalar" :key="index">
                                                    <div class="card h-100">
                                                    <img :src="$globalVariable.uploadUrl + file.dosya"
                                                                    @error="$globalVariable.varsayilanGorsel"
                                                                     class="card-img-top sh-22">
                                                    <div class="card-body pb-0">
                                                    <a :href="$globalVariable.uploadUrl + file.dosya" class="h7 heading ">{{ file.ad }}
                                                    </a>
                                                    </div>
                                                    <div class="card-footer border-0 pt-0">
                                                    <div class="d-flex align-items-center ">
                                                        <a :href="$globalVariable.uploadUrl + file.dosya" target="_blank"
                                                                            class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                                                viewBox="0 0 20 20" fill="none" stroke="currentColor"
                                                                                stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="acorn-icons acorn-icons-bin undefined">
                                                                                <path
                                                                                    d="M2 14V14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18H14.5C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5V14">
                                                                                </path>
                                                                                <path
                                                                                    d="M14 10 10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L6 10M10 2 10 13">
                                                                                </path>
                                                                            </svg>
                                                                            <span class="d-none d-xxl-inline-block">İndir</span>
                                                                        </a>
                                                                        <button
                                                                            class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1"
                                                                            @click="calismalarSil(file.id)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                                                viewBox="0 0 20 20" fill="none" stroke="currentColor"
                                                                                stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="acorn-icons acorn-icons-bin undefined">
                                                                                <path
                                                                                    d="M4 5V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V5">
                                                                                </path>
                                                                                <path
                                                                                    d="M14 5L13.9424 4.74074C13.6934 3.62043 13.569 3.06028 13.225 2.67266C13.0751 2.50368 12.8977 2.36133 12.7002 2.25164C12.2472 2 11.6734 2 10.5257 2L9.47427 2C8.32663 2 7.75281 2 7.29981 2.25164C7.10234 2.36133 6.92488 2.50368 6.77496 2.67266C6.43105 3.06028 6.30657 3.62044 6.05761 4.74074L6 5">
                                                                                </path>
                                                                                <path d="M2 5H18M12 9V13M8 9V13"></path>
                                                                            </svg>
                                                                            <span class="d-none d-xxl-inline-block">Sil</span>
                                                                        </button>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>


                                            </div>



                                         
                          
                            </div>
                            <div class="col-12 col-xl-12 mb-5">
                                <h2 class="small-title">Gönderilen Dosyalar</h2>


                                <div class="modal fade modal-close-out show" style="display: block;" v-if="dosyalarEkleModal">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title"> Dosyalar Ekleme </h5>
                                                            <button type="button" class="btn-close" @click="dosyalar_iptal"></button>
                                                        </div>
                                                        <div class="modal-body">

                                                            <input type="file" class="form-control" @change="dosyalarIslem" multiple />
                                                            <div class="mt-2">
                                                                <div v-for="(file, index) in seciliDosyalar" :key="index"
                                                                    class="d-flex justify-content-between align-items-center">
                                                                    <span>{{ file.name }}</span>
                                                                    <button type="button" class="btn btn-sm btn-danger"
                                                                        @click="dosyalarIslem(index)">
                                                                        Kaldır
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div v-if="isUploading">
                                                                {{ uploadFileNmae }} Dosyası Yükleniyor
                                                            </div>
                                                            <div class="progress" v-if="isUploading">
                                                                <div class="progress-bar" role="progressbar"
                                                                    :style="{ width: `${uploadProgress}%` }">
                                                                    {{ uploadProgress }}%
                                                                </div>
                                                            </div>

                                                            <div class="d-flex justify-content-around gap-2 mt-4">
                                                                <button class="btn btn-outline-primary"
                                                                    @click="dosyalar_iptal">İptal</button>
                                                                <button class="btn btn-primary w-100"
                                                                    @click="cokluDosyalarYukle">Kaydet</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="mt-4 d-flex flex-s flex-wrap">

                                                <div class="card mb-2  w-100" v-for="(file, index) in yuklenmisDosyalar" :key="index">
                                                    <div class="row g-0 sh-12 ">
                                                        <div class="col-auto h-100">
                                                            <a :href="$globalVariable.uploadUrl + file.dosya">
                                                                <img :src="$globalVariable.uploadUrl + file.dosya"
                                                                    @error="$globalVariable.varsayilanGorsel" alt="alternate text"
                                                                    class="card-img card-img-horizontal sw-13 sw-lg-15 ">
                                                            </a>
                                                        </div>
                                                        <div class="col">
                                                            <div class="card-body pt-0 pb-0 h-100">
                                                                <div class="row g-0 h-100 align-content-center">


                                                                    <div class="col w-100 d-flex justify-content-around mb-1">
                                                                        <div class="me-3 me-md-7">
                                                                            <p class="text-small text-muted mb-1">Ad</p>
                                                                            <p class="mb-0">{{ file.ad }}</p>
                                                                        </div>
                                                                        <div class="me-3 me-md-7">
                                                                            <p class="text-small text-muted mb-1">Açıklama</p>
                                                                            <p class="mb-0">{{ file.aciklama }}</p>
                                                                        </div>
                                                                        <div class="me-3 me-md-7">
                                                                            <p class="text-small text-muted mb-1">Yükleme Tarihi</p>
                                                                            <p class="mb-0">{{ file.olusturma_tarih }} </p>
                                                                        </div>


                                                                    </div>
                                                                    <div class="col-auto d-flex align-items-center justify-content-md-end">

                                                                        <a :href="$globalVariable.uploadUrl + file.dosya" target="_blank"
                                                                            class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                                                viewBox="0 0 20 20" fill="none" stroke="currentColor"
                                                                                stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="acorn-icons acorn-icons-bin undefined">
                                                                                <path
                                                                                    d="M2 14V14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18H14.5C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5V14">
                                                                                </path>
                                                                                <path
                                                                                    d="M14 10 10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L6 10M10 2 10 13">
                                                                                </path>
                                                                            </svg>
                                                                            <span class="d-none d-xxl-inline-block">İndir</span>
                                                                        </a>
                                                                        <button
                                                                            class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1"
                                                                            @click="dosyalarSil(file.id)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                                                viewBox="0 0 20 20" fill="none" stroke="currentColor"
                                                                                stroke-width="1.5" stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="acorn-icons acorn-icons-bin undefined">
                                                                                <path
                                                                                    d="M4 5V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V5">
                                                                                </path>
                                                                                <path
                                                                                    d="M14 5L13.9424 4.74074C13.6934 3.62043 13.569 3.06028 13.225 2.67266C13.0751 2.50368 12.8977 2.36133 12.7002 2.25164C12.2472 2 11.6734 2 10.5257 2L9.47427 2C8.32663 2 7.75281 2 7.29981 2.25164C7.10234 2.36133 6.92488 2.50368 6.77496 2.67266C6.43105 3.06028 6.30657 3.62044 6.05761 4.74074L6 5">
                                                                                </path>
                                                                                <path d="M2 5H18M12 9V13M8 9V13"></path>
                                                                            </svg>
                                                                            <span class="d-none d-xxl-inline-block">Sil</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>



                                         
                          
                            </div>

                                
          <div class="modal fade modal-close-out show" style="display: block;"
                                        v-if="islerform.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                
                                                    <h5 class="modal-title">İş 
                                                        Düzenleme </h5>
                                                    <button type="button" class="btn-close"
                                                        @click="isler_iptal"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <isForm 
                                                        :formIslem="islerform.islem" 
                                                        :formData="islerform.secili" 
                                                        :formKaydetSonrasi="islerYenile" 
                                                        :formIptal="isler_iptal" />


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                         
                        </div>

                    </div>
               

                </div>
            </div>

        </AppMain>
    </div>
</template>
  
<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';
import AppMain from "@/components/AppMain.vue";


import revizeCard from "@/components/revizeler/revizeCard.vue";
import revizeForm from "@/components/revizeler/revizeForm.vue";

import isadimCard from "@/components/isadimlar/isadimCard.vue";
import isadimForm from "@/components/isadimlar/isadimForm.vue";

import isdurumCard from "@/components/isdurumlar/isdurumCard.vue";
import isdurumForm from "@/components/isdurumlar/isdurumForm.vue";

import isForm from "@/components/isler/isForm.vue";


export default {
    name: "islerDetayView",
    components: {
        AppMain,

        isForm,

        revizeCard,
        revizeForm,

        isadimCard,
        isadimForm,

        isdurumCard,
        isdurumForm,

    },
    data() {
        return {
            isler: [],
            islerform: {
                tumu:[],
                secili:null,
                seciliSayfa:1,
                sayfadaKayit:5,
                arananKelime:"",
                islem:'ekle',
                modal:false,
            },
            
            aktifSekme: 'revizeler',
            hareketSayfadaKayit: 10,
            hareketSeciliSayfa: 1,


            revizeler: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },

            isadimlar: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },

            isdurumlar: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },

            durumlar: [
                { id: 'musteriyeni', ad: 'Müşteri İsteği' },
                { id: 'ajansyeni', ad: 'Yeni' },
                { id: 'hazirlaniyor', ad: 'Hazırlanıyor' },
                { id: 'ajansonaybekliyor', ad: 'Ajans Onayı Bekliyor' },
                { id: 'musterionaybekliyor', ad: 'Müşteri Onayı Bekliyor' },
                { id: 'revizeli', ad: 'Revizeli' },
                { id: 'tamamlandi', ad: 'Tamamlandi' },
                { id: 'iptal', ad: 'İptal Edildi' },
            ],

            yuklenmisDosyalar: [],
            dosyalarEkleModal: false,
            seciliDosyalar: [],
            isUploading: false,
            uploadProgress: 0,
            uploadFileName: '',



            yuklenmisCalismalar: [],
            calismalarEkleModal: false,
            seciliCalismalar: [],
            isUploadingCalismalar: false,
            uploadProgressCalismalar: 0,
            uploadFileNameCalismalar: '',


            kullanicilar: [],
            musteriler: [],



        };
    },
    mounted() {
        this.islerDetayGetir();
        this.kullanicilarGetir();
        this.musterilerGetir();
    },

    methods: {
        yetki_kontrol(yetki) {
            return yetkiKontrol(yetki);
        },
     
        islerDetayGetir() {
            const id = this.$route.params.id;

            axiosCustom
                .get(`isler/detay/${id}`)
                .then((response) => {
                    this.isler = response.data;


                    this.revizeler.tumu = response.data.revizeler;
                    this.revizeler.modal = false;

                    this.isadimlar.tumu = response.data.isadimlar;
                    this.isadimlar.modal = false;

                    this.isdurumlar.tumu = response.data.isdurumlar;
                    this.isdurumlar.modal = false;



                    this.yuklenmisDosyalar = response.data.dosyalar;

                    this.yuklenmisCalismalar = response.data.calismalar;



                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },


        islerYenile() {
        this.islerDetayGetir();
        this.islerform.modal=false;
        },
        islerGuncelle(veriler) {
        this.islerform = veriler;
        },

        isler_duzenle() {
            this.islerform.secili = this.isler;
            this.islerform.islem = "duzenle";
            this.islerform.modal=true;
        },

        isler_iptal() {
        this.islerform.secili = {};
        this.islerform.modal=false;
        },


        getDurumAdi(durumId) {
            const durum = this.durumlar.find(item => item.id === durumId);
            return durum ? durum.ad : 'Bilinmeyen Durum';
        },


        revizelerGuncelle(veriler) {
            this.revizeler = veriler;
        },
        revizeler_ekle() {
            this.revizeler.secili = { 'is': this.isler.id };
            this.revizeler.islem = "ekle";
            this.revizeler.modal = true;
        },
        revizeler_duzenle(veri) {
            this.revizeler.secili = veri;
            this.revizeler.islem = "duzenle";
            this.revizeler.modal = true;
        },
        revizeler_iptal() {
            this.revizeler.secili = {};
            this.revizeler.modal = false;
        },

        isadimlarGuncelle(veriler) {
            this.isadimlar = veriler;
        },
        isadimlar_ekle() {
            this.isadimlar.secili = { 'is': this.isler.id };
            this.isadimlar.islem = "ekle";
            this.isadimlar.modal = true;
        },
        isadimlar_duzenle(veri) {
            this.isadimlar.secili = veri;
            this.isadimlar.islem = "duzenle";
            this.isadimlar.modal = true;
        },
        isadimlar_iptal() {
            this.isadimlar.secili = {};
            this.isadimlar.modal = false;
        },

        isdurumlarGuncelle(veriler) {
            this.isdurumlar = veriler;
        },
        isdurumlar_ekle() {
            this.isdurumlar.secili = { 'is': this.isler.id };
            this.isdurumlar.islem = "ekle";
            this.isdurumlar.modal = true;
            
        },
        isdurumlar_duzenle(veri) {
            this.isdurumlar.secili = veri;
            this.isdurumlar.islem = "duzenle";
            this.isdurumlar.modal = true;
        },
        isdurumlar_iptal() {
            this.isdurumlar.secili = {};
            this.isdurumlar.modal = false;
        },







        dosyalar_ekle() {
            this.dosyalarEkleModal = true;
            
        },
        dosyalar_iptal() {
            this.dosyalarEkleModal = false;
            
        },
        dosyalarIslem(eventOrIndex) {
            if (eventOrIndex instanceof Event) {
                // Dosyalar seçildiğinde
                const files = eventOrIndex.target.files;
                for (let i = 0; i < files.length; i++) {
                    this.seciliDosyalar.push(files[i]);
                }

            } else {
                // Kaldır düğmesine basıldığında
                this.seciliDosyalar.splice(eventOrIndex, 1);
            }
        },

        async cokluDosyalarYukle() {
            const CHUNK_SIZE = 1024 * 1024; // 1 MB

            for (const file of this.seciliDosyalar) {
                const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

                for (let chunk = 0; chunk < totalChunks; chunk++) {
                    const start = chunk * CHUNK_SIZE;
                    const end = Math.min(start + CHUNK_SIZE, file.size);

                    const chunkData = file.slice(start, end);

                    let formData = new FormData();
                    formData.append("fileName", file.name);
                    this.uploadFileName = file.name;
                    formData.append("fileChunk", chunkData);
                    formData.append("currentChunk", chunk);
                    formData.append("totalChunks", totalChunks);

                    try {
                        await axiosCustom.post("isler/dosyalaryukle/" + this.isler.id, formData);
                        this.isUploading = true;

                        this.uploadProgress = (chunk * totalChunks) / 100;



                    } catch (error) {
                        console.error('Dosyalar yükleme hatası: ', error);
                        this.$toast.error('Dosyalar yükleme hatası');
                        // Handle error
                        return;
                    }
                }


            }

            // Tüm dosyaların tüm chunk'ları yüklendiyse, işlemi tamamla
            this.isUploading = false;
            this.uploadProgress = 100;

            this.$toast.success("Dosyalar Yüklendi");
            this.islerDetayGetir();
            this.dosyalarEkleModal = false;
        },

        async dosyalarSil(id) {
            const result = await this.$swal({
                title: "Emin misiniz?",
                text: "Bu işlemi geri alamayacaksınız!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet, sil!",
                cancelButtonText: "İptal",
            });

            if (result.isConfirmed) {
                try {
                    await axiosCustom.post("isler/dosyalarsil/" + id);
                    this.$toast.success("Dosyalar Silindi");
                    this.islerDetayGetir();
                } catch (error) {
                    console.error("Silme hatası: ", error);
                    this.$toast.error("Silme hatası");
                }
            }
        },

        calismalar_ekle() {
    this.calismalarEkleModal = true;
    
},
calismalar_iptal() {
    this.calismalarEkleModal = false;
    
},
calismalarIslem(eventOrIndex) {
    if (eventOrIndex instanceof Event) {
        // Calismalar seçildiğinde
        const files = eventOrIndex.target.files;
        for (let i = 0; i < files.length; i++) {
            this.seciliCalismalar.push(files[i]);
        }

    } else {
        // Kaldır düğmesine basıldığında
        this.seciliCalismalar.splice(eventOrIndex, 1);
    }
},

async cokluCalismalarYukle() {
    const CHUNK_SIZE = 1024 * 1024; // 1 MB

    for (const file of this.seciliCalismalar) {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        for (let chunk = 0; chunk < totalChunks; chunk++) {
            const start = chunk * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);

            const chunkData = file.slice(start, end);

            let formData = new FormData();
            formData.append("fileName", file.name);
            this.uploadFileNameCalismalar = file.name;
            formData.append("fileChunk", chunkData);
            formData.append("currentChunk", chunk);
            formData.append("totalChunks", totalChunks);

            try {
                await axiosCustom.post("isler/calismalaryukle/" + this.isler.id, formData);
                this.isUploadingCalismalar = true;

                this.uploadProgressCalismalar = (chunk * totalChunks) / 100;



            } catch (error) {
                console.error('Calismalar yükleme hatası: ', error);
                this.$toast.error('Calismalar yükleme hatası');
                // Handle error
                return;
            }
        }


    }

    // Tüm calismaların tüm chunk'ları yüklendiyse, işlemi tamamla
    this.isUploadingCalismalar = false;
    this.uploadProgressCalismalar = 100;

    this.$toast.success("Calismalar Yüklendi");
    this.islerDetayGetir();
    this.calismalarEkleModal = false;
},

async calismalarSil(id) {
    const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
        try {
            await axiosCustom.post("isler/calismalarsil/" + id);
            this.$toast.success("Calismalar Silindi");
            this.islerDetayGetir();
        } catch (error) {
            console.error("Silme hatası: ", error);
            this.$toast.error("Silme hatası");
        }
    }
},



 hesaplaYuzde() {
  const toplamAdimSayisi = this.isadimlar.tumu.length;
  const yapilanAdimSayisi = this.isadimlar.tumu.filter(adim => adim.yapildi == 1).length;

  // Tüm adımlar yapılmışsa yüzde 100 döndür
  if (yapilanAdimSayisi === toplamAdimSayisi) {
    return 100;
  }

  // Yapılan adımların yüzdesini hesapla
  const yuzde = (yapilanAdimSayisi / toplamAdimSayisi) * 100;
  return yuzde;
},


        async kullanicilarGetir() {
            try {
                const response = await axiosCustom.get("kullanicilar");
                this.kullanicilar = response.data;
            } catch (error) {
                console.error("kullanicilar alma hatası: ", error);
            }
        },
        kullaniciBilgileri(id) {
            return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
        },


        async musterilerGetir() {
            try {
                const response = await axiosCustom.get("musteriler");
                this.musteriler = response.data;
            } catch (error) {
                console.error("musteriler alma hatası: ", error);
            }
        },
        musteriBilgileri(id) {
            return this.musteriler.find((musteri) => musteri.id === id) || {};
        },





    }
};
</script>
  

  