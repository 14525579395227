<template>
  <div class="col">

    <div class="row g-0" v-for="(isdurum, index) in paginatedisdurumlar" :key="isdurum.id">
      <div class="col-auto sw-7 d-flex flex-column justify-content-center align-items-center position-relative me-4">
        <div class="w-100 d-flex h-0"></div>
        <div
          class="bg-foreground sw-7 sh-7 rounded-lg shadow d-flex flex-shrink-0 justify-content-center align-items-center">
          <div class="bg-gradient-light sw-5 sh-5 rounded-md">
            <div class="text-white d-flex justify-content-center align-items-center h-100 text-small text-center lh-1">
              {{ index }}</div>
          </div>
        </div>
        <div class="w-100 d-flex h-100 justify-content-center position-relative">
          <div class="line-w-1 bg-separator h-100 position-absolute"></div>
        </div>
      </div>
      <div class="col mb-2">
        <div class="card h-100">
          <div class="card-body d-flex flex-column justify-content-start">
            <div class="d-flex flex-column">
              <router-link to=""  class="heading ">{{ isdurum.durum }}</router-link>
              <div class="text-alternate">{{ isdurum.olusturma_tarih }}</div>
              <div class="text-muted mt-1">{{ kullaniciBilgileri(isdurum.kullanici).ad  +' '+kullaniciBilgileri(isdurum.kullanici).soyad }} </div>
            </div>
            <div class="mt-4 text-center">
              <button v-if="yetki_kontrol('isdurum_duzenle')" @click="duzenle(isdurum)" type="button"
                class="btn btn-icon btn-icon-only btn-foreground-alternate">
                <unicon name="pen" fill="#7c7c7c" class="icon"></unicon>
              </button>
              <button v-if="yetki_kontrol('isdurum_sil')" @click="sil(isdurum.id)" type="button"
                class="btn btn-icon btn-icon-only btn-foreground-alternate">
                <unicon name="trash" fill="#7c7c7c" class="icon"></unicon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 text-center">
        <!-- Sayfalama -->
        <nav>
          <ul class="pagination">
            <li class="page-item" :class="{ active: page === isdurumlar.seciliSayfa }" v-for="page in pages" :key="page">
              <a class="page-link" @click="isdurumlarSayfaDegistir(page)">{{
                page
              }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    isdurumlar: {
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur: String,
  },
  data() {
    return {
      kullanicilar: [],
    };
  },
  created() {
    this.kullanicilarGetir(); 
  },
  computed: {
    filteredisdurumlar() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.isdurumlar.tumu.filter(
        (isdurum) =>

          isdurum.id.toLowerCase()


            .includes(this.isdurumlar.arananKelime.toLowerCase()) || isdurum.durum.toLowerCase()

              .includes(this.isdurumlar.arananKelime.toLowerCase())



      );
    },
    paginatedisdurumlar() {
      const start = (this.isdurumlar.seciliSayfa - 1) * this.isdurumlar.sayfadaKayit;
      const end = start + this.isdurumlar.sayfadaKayit;
      return this.filteredisdurumlar.slice(start, end);
    },
    isdurumlar_toplamSayfa() {
      const totalFilteredUsers = this.filteredisdurumlar.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.isdurumlar.sayfadaKayit), 1);
      if (this.isdurumlar.seciliSayfa > maxPage) {
        this.isdurumlarSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
      const totalPageCount = this.isdurumlar_toplamSayfa;
      const maxVisiblePages = 5;
      const currentPage = this.isdurumlar.seciliSayfa;

      if (totalPageCount <= maxVisiblePages) {
        return Array.from({ length: totalPageCount }, (_, index) => index + 1);
      }

      const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
      const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

      return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },

  },
  methods: {
    yetki_kontrol(yetki) {
      return yetkiKontrol(yetki);
    },
    async sil(isdurumId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("isdurumlar/sil/" + isdurumId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    isdurumlarSayfaDegistir(page) {
      this.$emit('update:isdurumlar', {
        ...this.isdurumlar,
        seciliSayfa: page
      });
    },
    isdurumlarSayfadaKayitDegistir(value) {
      this.$emit('update:isdurumlar', {
        ...this.isdurumlar,
        sayfadaKayit: value
      });
      this.isdurumlarSayfaDegistir(1);
    },

    async kullanicilarGetir() {
      try {
        const response = await axiosCustom.get("kullanicilar");
        this.kullanicilar = response.data;
      } catch (error) {
        console.error("kullanicilar alma hatası: ", error);
      }
    },
    kullaniciBilgileri(id) {
      return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
    },
  },
};
</script>
