<template>
  <h2 class="small-title">Hızlı Menü</h2>
  <div class="row gx-2">
    <div class="col">
      <router-link to="/isler">
      <div class="card mb-5 hover-border-primary">
        <div class="h-100 d-flex flex-column justify-content-between card-body align-items-center">
          <div class="sw-8 sh-8 rounded-xl d-flex justify-content-center align-items-center border border-primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              class="acorn-icons acorn-icons-menu-dashed icon text-primary">
              <path d="M8 3 18 3M8 10 18 10M8 17 18 17M2 3 4 3M2 10 4 10M2 17 4 17"></path>
            </svg>
          </div>
          <div class="text-center mb-0 sh-8 d-flex align-items-center lh-1-5">
            Bu Haftaki İşler
          </div>
          <div class="display-6 text-primary">{{sayilar.bu_hafta_isler_adet}} Adet</div>
        </div>
      </div>
    </router-link>
    </div>
    <div class="col">
      <router-link to="/isler">
      <div class="card mb-5 hover-border-primary">
        <div class="h-100 d-flex flex-column justify-content-between card-body align-items-center">
          <div class="sw-8 sh-8 rounded-xl d-flex justify-content-center align-items-center border border-primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              class="acorn-icons acorn-icons-user icon text-primary">
              <path
                d="M10.0179 8C10.9661 8 11.4402 8 11.8802 7.76629C12.1434 7.62648 12.4736 7.32023 12.6328 7.06826C12.8989 6.64708 12.9256 6.29324 12.9789 5.58557C13.0082 5.19763 13.0071 4.81594 12.9751 4.42106C12.9175 3.70801 12.8887 3.35148 12.6289 2.93726C12.4653 2.67644 12.1305 2.36765 11.8573 2.2256C11.4235 2 10.9533 2 10.0129 2V2C9.03627 2 8.54794 2 8.1082 2.23338C7.82774 2.38223 7.49696 2.6954 7.33302 2.96731C7.07596 3.39365 7.05506 3.77571 7.01326 4.53982C6.99635 4.84898 6.99567 5.15116 7.01092 5.45586C7.04931 6.22283 7.06851 6.60631 7.33198 7.03942C7.4922 7.30281 7.8169 7.61166 8.08797 7.75851C8.53371 8 9.02845 8 10.0179 8V8Z">
              </path>
              <path
                d="M16.5 17.5L16.583 16.6152C16.7267 15.082 16.7986 14.3154 16.2254 13.2504C16.0456 12.9164 15.5292 12.2901 15.2356 12.0499C14.2994 11.2842 13.7598 11.231 12.6805 11.1245C11.9049 11.048 11.0142 11 10 11C8.98584 11 8.09511 11.048 7.31945 11.1245C6.24021 11.231 5.70059 11.2842 4.76443 12.0499C4.47077 12.2901 3.95441 12.9164 3.77462 13.2504C3.20144 14.3154 3.27331 15.082 3.41705 16.6152L3.5 17.5">
              </path>
            </svg>
          </div>
          <div class="text-center mb-0 sh-8 d-flex align-items-center lh-1-5">
            Tüm İşler
          </div>
          <div class="display-6 text-primary">{{sayilar.tum_isler_adet}} Adet</div>
        </div>
      </div>
    </router-link>
    </div>
    <div class="col">
      <router-link to="/musteriler">
      <div class="card mb-5 hover-border-primary">
        <div class="h-100 d-flex flex-column justify-content-between card-body align-items-center">
          <div class="sw-8 sh-8 rounded-xl d-flex justify-content-center align-items-center border border-primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              class="acorn-icons acorn-icons-user icon text-primary">
              <path
                d="M10.0179 8C10.9661 8 11.4402 8 11.8802 7.76629C12.1434 7.62648 12.4736 7.32023 12.6328 7.06826C12.8989 6.64708 12.9256 6.29324 12.9789 5.58557C13.0082 5.19763 13.0071 4.81594 12.9751 4.42106C12.9175 3.70801 12.8887 3.35148 12.6289 2.93726C12.4653 2.67644 12.1305 2.36765 11.8573 2.2256C11.4235 2 10.9533 2 10.0129 2V2C9.03627 2 8.54794 2 8.1082 2.23338C7.82774 2.38223 7.49696 2.6954 7.33302 2.96731C7.07596 3.39365 7.05506 3.77571 7.01326 4.53982C6.99635 4.84898 6.99567 5.15116 7.01092 5.45586C7.04931 6.22283 7.06851 6.60631 7.33198 7.03942C7.4922 7.30281 7.8169 7.61166 8.08797 7.75851C8.53371 8 9.02845 8 10.0179 8V8Z">
              </path>
              <path
                d="M16.5 17.5L16.583 16.6152C16.7267 15.082 16.7986 14.3154 16.2254 13.2504C16.0456 12.9164 15.5292 12.2901 15.2356 12.0499C14.2994 11.2842 13.7598 11.231 12.6805 11.1245C11.9049 11.048 11.0142 11 10 11C8.98584 11 8.09511 11.048 7.31945 11.1245C6.24021 11.231 5.70059 11.2842 4.76443 12.0499C4.47077 12.2901 3.95441 12.9164 3.77462 13.2504C3.20144 14.3154 3.27331 15.082 3.41705 16.6152L3.5 17.5">
              </path>
            </svg>
          </div>
          <div class="text-center mb-0 sh-8 d-flex align-items-center lh-1-5">
            Müşteriler
          </div>
          <div class="display-6 text-primary">{{sayilar.musteriler_adet}} Adet</div>
        </div>
      </div>
    </router-link>
    </div>
    <div class="col">
      <router-link to="/kullanicilar">
      <div class="card mb-5 hover-border-primary">
        <div class="h-100 d-flex flex-column justify-content-between card-body align-items-center">
          <div class="sw-8 sh-8 rounded-xl d-flex justify-content-center align-items-center border border-primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              class="acorn-icons acorn-icons-file-image icon text-primary">
              <path
                d="M6.5 18H13.5C14.9045 18 15.6067 18 16.1111 17.6629C16.3295 17.517 16.517 17.3295 16.6629 17.1111C17 16.6067 17 15.9045 17 14.5V7.44975C17 6.83775 17 6.53175 16.9139 6.24786C16.8759 6.12249 16.8256 6.00117 16.7638 5.88563C16.624 5.62399 16.4076 5.40762 15.9749 4.97487L14.0251 3.02513L14.0251 3.02512C13.5924 2.59238 13.376 2.37601 13.1144 2.23616C12.9988 2.1744 12.8775 2.12415 12.7521 2.08612C12.4682 2 12.1622 2 11.5503 2H6.5C5.09554 2 4.39331 2 3.88886 2.33706C3.67048 2.48298 3.48298 2.67048 3.33706 2.88886C3 3.39331 3 4.09554 3 5.5V14.5C3 15.9045 3 16.6067 3.33706 17.1111C3.48298 17.3295 3.67048 17.517 3.88886 17.6629C4.39331 18 5.09554 18 6.5 18Z">
              </path>
              <circle r="1.5" transform="matrix(-1 0 0 1 8.5 7.5)"></circle>
              <path
                d="M17 13L14.142 11.2137C13.6738 10.9211 13.4397 10.7748 13.1892 10.7581C13.0799 10.7508 12.9701 10.7615 12.8643 10.7898C12.6217 10.8546 12.4204 11.0434 12.0176 11.421L5 18">
              </path>
            </svg>
          </div>
          <div class="text-center mb-0 sh-8 d-flex align-items-center lh-1-5">
            Kullanıcılar
          </div>
          <div class="display-6 text-primary">{{sayilar.kullanicilar_adet}} Adet</div>
        </div>
      </div>
    </router-link>
    </div>
    <div class="col">
      <router-link to="/isler">
      <div class="card mb-5 hover-border-primary">
        <div class="h-100 d-flex flex-column justify-content-between card-body align-items-center">
          <div class="sw-8 sh-8 rounded-xl d-flex justify-content-center align-items-center border border-primary">
           
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-file-image icon text-primary"><path d="M15 3.75C15 3.04777 15 2.69665 14.8315 2.44443C14.7585 2.33524 14.6648 2.24149 14.5556 2.16853C14.3033 2 13.9522 2 13.25 2H6.75C6.04777 2 5.69665 2 5.44443 2.16853C5.33524 2.24149 5.24149 2.33524 5.16853 2.44443C5 2.69665 5 3.04777 5 3.75V9.25C5 9.95223 5 10.3033 5.16853 10.5556C5.24149 10.6648 5.33524 10.7585 5.44443 10.8315C5.69665 11 6.04777 11 6.75 11H13.25C13.9522 11 14.3033 11 14.5556 10.8315C14.6648 10.7585 14.7585 10.6648 14.8315 10.5556C15 10.3033 15 9.95223 15 9.25V3.75Z"></path><path d="M10 11 10 13M8 2 8 5M12 2 12 5M14.5 8 5.5 8"></path><path d="M10.125 13C10.4761 13 10.6517 13 10.7778 13.0843C10.8324 13.1207 10.8793 13.1676 10.9157 13.2222C11 13.3483 11 13.5239 11 13.875L11 17.125C11 17.4761 11 17.6517 10.9157 17.7778C10.8793 17.8324 10.8324 17.8793 10.7778 17.9157C10.6517 18 10.4761 18 10.125 18L9.875 18C9.52388 18 9.34833 18 9.22221 17.9157C9.16762 17.8793 9.12074 17.8324 9.08427 17.7778C9 17.6517 9 17.4761 9 17.125L9 13.875C9 13.5239 9 13.3483 9.08427 13.2222C9.12074 13.1676 9.16762 13.1207 9.22222 13.0843C9.34833 13 9.52388 13 9.875 13L10.125 13Z"></path></svg>
          </div>
          <div class="text-center mb-0 sh-8 d-flex align-items-center lh-1-5">
            Yapılacak Revizeler
          </div>
          <div class="display-6 text-primary">{{sayilar.revizeler_adet}} Adet</div>
        </div>
      </div>
    </router-link>
    </div>
  </div>
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
export default {
  name: "HizliMenu",
  data() {
    return {
      sayilar: [],
    }
  },
  mounted() {
    this.hizliMenuGetir();
  },
  methods: {

    hizliMenuGetir() {
  axiosCustom
    .get("anasayfa/hizlimenu")
    .then((response) => {
      this.sayilar = response.data;
    })
    .catch((error) => {
      console.error("Veri çekme hatası: ", error);
    });
},





},
};
</script>
