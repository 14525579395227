<template>

<div class="col">
            <div class="row">
        
                <!-- kullanici Kart -->
                <div class="col-md-3 mt-4"  v-for="kullanici in paginatedkullanicilar" :key="kullanici.id" >
                <div class="card h-100">
                 
                
                  <div class="card-body">
                    <div class="d-flex flex-column align-items-center ">
                    <router-link v-if="yetki_kontrol('kullanici_goster')"  :to="'kullanicilar/' + kullanici.id">
                   
                    <img :src="$globalVariable.uploadUrl+kullanici.profil" @error="$globalVariable.varsayilanGorsel" class="sw-9 sh-9 rounded-xl mb-3"  />
                
                  </router-link>

                    <router-link v-if="yetki_kontrol('kullanici_goster')" :to="'kullanicilar/' + kullanici.id"  >{{ kullanici.ad }}  {{ kullanici.soyad }}</router-link>
                    <div class="text-muted mb-1">{{kullanici.rol_ad}}</div>

                    <div class="row g-0 align-items-center mb-2">
                      <div class="col-auto">
                        <div class="sw-3 d-flex justify-content-center align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-phone text-primary"><path d="M2.36839 7.02416C2.12354 6.39146 2.25595 5.68011 2.67976 5.15035L4.20321 3.24603C5.07388 2.1577 6.76286 2.27131 7.47994 3.46644L8.71763 5.52926C8.89353 5.82244 8.90746 6.18516 8.75456 6.49097L8.10563 7.78883C8.0362 7.92769 7.99726 8.08102 8.00921 8.2358C8.15129 10.0757 9.92438 11.8487 11.7642 11.9908C11.919 12.0028 12.0723 11.9638 12.2112 11.8944L13.5091 11.2455C13.8149 11.0926 14.1776 11.1065 14.4708 11.2824L16.5336 12.5201C17.7287 13.2372 17.8423 14.9262 16.754 15.7968L14.8497 17.3203C14.3199 17.7441 13.6086 17.8765 12.9759 17.6317C7.87729 15.6586 4.34147 12.1228 2.36839 7.02416Z"></path></svg>
                        </div>
                      </div>
                      <div class="col ps-3">
                        <div class="d-flex align-items-center lh-1-25">
                          {{ kullanici.telefon }}
                        </div>
                      </div>
                    </div>
                    
                    
                    <div class="mt-2 text-center">
                      <button v-if="yetki_kontrol('kullanici_duzenle')" @click="duzenle(kullanici)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                      </button>
                      <button  v-if="yetki_kontrol('kullanici_sil')"  @click="sil(kullanici.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                      </button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
                <!-- kullanici Kart -->
             
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === kullanicilar.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="kullanicilarSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    kullanicilar:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  computed: {
    filteredkullanicilar() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.kullanicilar.tumu.filter(
        (kullanici) =>

          kullanici.ad.toLowerCase() 
          
          .includes(this.kullanicilar.arananKelime.toLowerCase()) ||kullanici.ad.toLowerCase()
          
          .includes(this.kullanicilar.arananKelime.toLowerCase()) ||kullanici.soyad.toLowerCase()
          
          .includes(this.kullanicilar.arananKelime.toLowerCase()) ||kullanici.kullaniciadi.toLowerCase()
          
          .includes(this.kullanicilar.arananKelime.toLowerCase()) ||kullanici.telefon.toLowerCase()
          
          
          .includes(this.kullanicilar.arananKelime.toLowerCase())


    
      );
    },
    paginatedkullanicilar() {
      const start = (this.kullanicilar.seciliSayfa - 1) * this.kullanicilar.sayfadaKayit;
      const end = start + this.kullanicilar.sayfadaKayit;
      return this.filteredkullanicilar.slice(start, end);
    },
    kullanicilar_toplamSayfa() {
      const totalFilteredUsers = this.filteredkullanicilar.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.kullanicilar.sayfadaKayit), 1);
      if (this.kullanicilar.seciliSayfa > maxPage) {
        this.kullanicilarSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.kullanicilar_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.kullanicilar.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(kullaniciId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("kullanicilar/sil/" + kullaniciId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    kullanicilarSayfaDegistir(page) {
      this.$emit('update:kullanicilar',{
      ...this.kullanicilar,
      seciliSayfa: page
    });
    },
    kullanicilarSayfadaKayitDegistir(value) {
      this.$emit('update:kullanicilar',{
      ...this.kullanicilar,
      sayfadaKayit: value
    });
      this.kullanicilarSayfaDegistir(1);
    },
  },
};
</script>
