<template>

  <div class="col">
              <div class="row">
           
                <template  v-if="tur=='liste'">
                  <!-- rol  liste -->
                  
                  <div class="card mb-2 sh-10 sh-md-8"  v-for="rol in paginatedroller" :key="rol.id" >
                            <div class="card-body pt-0 pb-0 h-100">
                              <div class="row g-0 h-100 align-content-center">
                                <div class="col-12 col-md-4 d-flex align-items-center mb-2 mb-md-0">
                                  <span class="body-link text-truncate"> {{ rol.ad }}</span>
                                </div>
                              
  
                                <div class="col-12 col-md-3 d-flex align-items-center justify-content-end text-muted text-medium">
                                  <span>{{ rol.aciklama }}</span>
                                </div>
                                <div class="col-12 col-md-3 d-flex align-items-center justify-content-end text-muted text-medium">
                                  <template v-for="yetki in yetkiler" :key="yetki.key">
                                    <div class="mb-1" v-if="rol.yetkiler.includes(yetki.key)">{{yetki.ad}}</div>
                                    <div class="text-muted mb-1">
                                      {{ (rol.yetkiler.includes(yetki.key+'_goster')) ? ' Görüntüleyebilir' : ''  }}
                                      {{ (rol.yetkiler.includes(yetki.key+'_ekle')) ? ' Ekleyebilir' : ''  }}
                                      {{ (rol.yetkiler.includes(yetki.key+'_duzenle')) ? ' Düzenleyebilir' : ''  }}
                                      {{ (rol.yetkiler.includes(yetki.key+'_sil')) ? ' Silebilir' : ''  }}
                                    </div>
                                </template>
                                </div>
  
                                <div class="col-12 col-md-2 d-flex justify-content-end">
                                  <button v-if="yetki_kontrol('rol_duzenle')" @click="duzenle(rol)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                    <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                                  </button>
                                  <button  v-if="yetki_kontrol('rol_sil')"  @click="sil(rol.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                    <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                                  </button>
                                </div>
                              </div>
                            </div>
                    </div>
              
                  <!-- rol  liste -->
                </template>
                <template  v-if="tur=='kart' || tur==null">
                  <!-- rol Kart -->
                  <div class="col-md-6 mt-4"  v-for="rol in paginatedroller" :key="rol.id" >
                  <div class="card h-100">
                 
                    <div class="card-body">
                      <router-link v-if="yetki_kontrol('rol_goster')" :to="'roller/' + rol.id"  >{{ rol.ad }} </router-link>
                      <div class="text-muted mb-3">{{ rol.aciklama }}</div>
                      <template v-for="yetki in yetkiler" :key="yetki.key">
                        <div class="mb-1" v-if="rol.yetkiler.includes(yetki.key)">{{yetki.ad}}</div>
                        <div class="text-muted mb-1">
                          {{ (rol.yetkiler.includes(yetki.key+'_goster')) ? ' Görüntüleyebilir' : ''  }}
                          {{ (rol.yetkiler.includes(yetki.key+'_ekle')) ? ' Ekleyebilir' : ''  }}
                          {{ (rol.yetkiler.includes(yetki.key+'_duzenle')) ? ' Düzenleyebilir' : ''  }}
                          {{ (rol.yetkiler.includes(yetki.key+'_sil')) ? ' Silebilir' : ''  }}
                        </div>
                    </template>
                      
                      <div class="mt-4 text-center">
                        <button v-if="yetki_kontrol('rol_duzenle')" @click="duzenle(rol)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                          <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                        </button>
                        <button  v-if="yetki_kontrol('rol_sil')"  @click="sil(rol.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                          <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                  <!-- rol Kart -->
                </template>
              </div>
              <div class="row mt-5">
                <div class="col-12 text-center">
                  <!-- Sayfalama -->
                  <nav>
                    <ul class="pagination">
                      <li class="page-item" :class="{ active: page === roller.seciliSayfa }" v-for="page in pages" :key="page">
                        <a class="page-link" @click="rollerSayfaDegistir(page)">{{
                          page
                        }}</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
  
  
  
   
    
  </template>
  
  <script>
  import axiosCustom from "@/services/axiosCustom";
  import { yetkiKontrol } from '@/services/yetkiKontrol';
  
  export default {
    props: {
  
      roller:{
        type: Array,
        required: true
      },
      duzenle: Function,
      formKaydetSonrasi: Function,
      tur:String,
    },
    data() {
        return {
        
          yetkiler: [
          { key: 'bildirim', ad: 'Bildirim' },
          { key: 'isfikir', ad: 'İş fikir' },
          { key: 'isadim', ad: 'İş Adım' },
          { key: 'isdosya', ad: 'İş Dosya' },
          { key: 'isdurum', ad: 'İş Durum' },
          { key: 'is', ad: 'İş' },
          { key: 'istur', ad: 'İş Türleri' },
          { key: 'kullanici', ad: 'Kullanici' },
          { key: 'musteri', ad: 'Müşteri' },
          { key: 'musterinot', ad: 'Müşteri Notu' },
          { key: 'revize', ad: 'Revize' },
          { key: 'rol', ad: 'Rol' },
          { key: 'yapilacak', ad: 'Yapılacak' },
        ],
        
          localFormData: { ...this.formData }, 
          formHatalar:{},
        };
      },
    computed: {
      filteredroller() {
        // Arama filtresine göre kullanıcıları filtrele
        return this.roller.tumu.filter(
          (rol) =>
  
            rol.ad.toLowerCase() 
            
            .includes(this.roller.arananKelime.toLowerCase()) ||rol.ad.toLowerCase()
            
            
            .includes(this.roller.arananKelime.toLowerCase()) ||rol.aciklama.toLowerCase()
            
            .includes(this.roller.arananKelime.toLowerCase()) ||rol.yetkiler.toLowerCase()
            
            .includes(this.roller.arananKelime.toLowerCase())
  
  
      
        );
      },
      paginatedroller() {
        const start = (this.roller.seciliSayfa - 1) * this.roller.sayfadaKayit;
        const end = start + this.roller.sayfadaKayit;
        return this.filteredroller.slice(start, end);
      },
      roller_toplamSayfa() {
        const totalFilteredUsers = this.filteredroller.length;
        const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.roller.sayfadaKayit), 1);
        if (this.roller.seciliSayfa > maxPage) {
          this.rollerSayfaDegistir(maxPage);
        }
        return maxPage;
      },
      pages() {
        return Array.from({ length: this.roller_toplamSayfa }, (_, index) => index + 1);
      },
    },
    methods: {
      yetki_kontrol(yetki){
        return yetkiKontrol(yetki);
      },
      async sil(rolId) {
        const result = await this.$swal({
          title: "Emin misiniz?",
          text: "Bu işlemi geri alamayacaksınız!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Evet, sil!",
          cancelButtonText: "İptal",
        });
  
        if (result.isConfirmed) {
          try {
            await axiosCustom.post("roller/sil/" + rolId);
            this.$toast.success("Kullanıcı Silindi");
            this.formKaydetSonrasi();
          } catch (error) {
            console.error("Silme hatası: ", error);
            this.$toast.error("Silme hatası");
          }
        }
      },
      rollerSayfaDegistir(page) {
        this.$emit('update:roller',{
        ...this.roller,
        seciliSayfa: page
      });
      },
      rollerSayfadaKayitDegistir(value) {
        this.$emit('update:roller',{
        ...this.roller,
        sayfadaKayit: value
      });
        this.rollerSayfaDegistir(1);
      },
  
      belirliYetkilereSahipMi(yetkiler, yetki) {
        return yetkiler.includes(yetki);
      }
    },
  };
  </script>
  