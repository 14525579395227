<!-- eslint-disable vue/no-mutating-props -->
<template>
  

                 
  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <label for="ad">Ad</label> 
      </div>	
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
      
    
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.aciklama }"> 
        <textarea id="aciklama" v-model="localFormData.aciklama" class="form-control" rows="5"></textarea>
        <label for="aciklama">Açıklama</label> 
      </div>	
        <span v-if="formHatalar.aciklama" class="error">{{ formHatalar.aciklama }}</span>
    
    
    
    
    
    <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.kullanici }"> 
        <v-select-coklu
          multiple
          v-model="localFormData.kullanici"
          :options="kullanicilar"
          label="ad"
          :reduce="kullanici => kullanici.id"
          placeholder="Kullanıcılar Seçin"
        />
      </div>	
        <span v-if="formHatalar.kullanici" class="error">{{ formHatalar.kullanici }}</span>
    
    
   
    
    
    
      <div class="col-12  d-flex justify-content-start mb-1" :class="{ 'text-danger': formHatalar.yapildi }">  
        <label >Yapıldı</label> 
        <Toggle  v-model="localFormData.yapildi" />	
        
      </div>
      <span v-if="formHatalar.yapildi" class="error">{{ formHatalar.yapildi }}</span>
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";


import vSelectCoklu from "vue-select";






import Toggle from '@vueform/toggle';


export default {
components: {
  
  
      vSelectCoklu,
  
  
  
  
  
    Toggle,
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  
  kullanicilar: [],
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  
  this.kullanicilarGetir();

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          if(this.localFormData.yapildi=='1'){
            this.localFormData.yapildi=true;
            }else{
              this.localFormData.yapildi=false;
            }
          
          
          
          try{
            this.localFormData.kullanici = newFormData.kullanici.split(',');
          }catch (error) {
            this.localFormData.kullanici = newFormData.kullanici;
          }
          

      
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  
  async kullanicilarGetir() {
    try {
      const response = await axiosCustom.get("kullanicilar"); 
      this.kullanicilar = response.data;
    } catch (error) {
      console.error("kullanicilar alma hatası: ", error);
    }
  },
  


  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "Ad boş bırakılamaz.";
      kontrol=false;
    }
    
  
  if (!this.localFormData.aciklama) {
      this.formHatalar.aciklama = "Açıklama boş bırakılamaz.";
      kontrol=false;
    }
  
  
  
  
  if (!this.localFormData.kullanici) {
      this.formHatalar.kullanici = "Kullanıcılar boş bırakılamaz.";
      kontrol=false;
    }
  

 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("ad", this.localFormData.ad);
  newFormData.append("aciklama", this.localFormData.aciklama);
  
  
  newFormData.append("kullanici", this.localFormData.kullanici);
  newFormData.append("is", this.localFormData.is); 
  
  newFormData.append("yapildi",  this.localFormData.yapildi==true ? '1' : '0');

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("isadimlar/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("İş Adım Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("isadimlar/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni İş Adım Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
},
};
</script>