<template>
  <h2 class="small-title">Bu Hafta Bitecek İşler</h2>

  <div class="card mb-2 sh-11 sh-md-8" v-for="is in isler" :key="is.id">
    <div class="card-body pt-0 pb-0 h-100">
      <div class="row g-0 h-100 align-content-center">
        <div class="col-11 col-md-7 d-flex align-items-center mb-1 mb-md-0 order-1 order-md-1">
          <router-link :to="'isler/' + is.id"  class="body-link text-truncate">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              class="acorn-icons acorn-icons-file-text sw-2 me-2 text-alternate">
              <path
                d="M6.5 18H13.5C14.9045 18 15.6067 18 16.1111 17.6629C16.3295 17.517 16.517 17.3295 16.6629 17.1111C17 16.6067 17 15.9045 17 14.5V7.44975C17 6.83775 17 6.53175 16.9139 6.24786C16.8759 6.12249 16.8256 6.00117 16.7638 5.88563C16.624 5.62399 16.4076 5.40762 15.9749 4.97487L14.0251 3.02513L14.0251 3.02512C13.5924 2.59238 13.376 2.37601 13.1144 2.23616C12.9988 2.1744 12.8775 2.12415 12.7521 2.08612C12.4682 2 12.1622 2 11.5503 2H6.5C5.09554 2 4.39331 2 3.88886 2.33706C3.67048 2.48298 3.48298 2.67048 3.33706 2.88886C3 3.39331 3 4.09554 3 5.5V14.5C3 15.9045 3 16.6067 3.33706 17.1111C3.48298 17.3295 3.67048 17.517 3.88886 17.6629C4.39331 18 5.09554 18 6.5 18Z">
              </path>
              <path d="M13 6 7 6M13 10 7 10M13 14 7 14"></path>
            </svg>
            <span class="align-middle">{{is.musteri_unvan }} {{is.istur_ad}} {{ is.adet }}</span>
          </router-link>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center justify-content-md-center text-muted order-3 order-md-2">
          {{is.bitistarih }}
        </div>
        <div
          class="col-1 col-md-2 d-flex align-items-center text-muted text-medium justify-content-end order-2 order-md-3">
          {{ getDurumAdi(is.durum) }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";
export default {
  name: "BugunBitecekIsler",
  data() {
    return {
      isler: [],
      durumlar: [
        { id: 'musteriyeni', ad: 'Müşteri İsteği' },
        { id: 'ajansyeni', ad: 'Yeni' },
        { id: 'hazirlaniyor', ad: 'Hazırlanıyor' },
        { id: 'ajansonaybekliyor', ad: 'Ajans Onayı Bekliyor' },
        { id: 'musterionaybekliyor', ad: 'Müşteri Onayı Bekliyor' },
        { id: 'revizeli', ad: 'Revizeli' },
        { id: 'tamamlandi', ad: 'Tamamlandi' },
        { id: 'iptal', ad: 'İptal Edildi' },
      ],
    }
  },
  mounted() {
    this.islerGetir();
  },
  methods: {

    islerGetir() {
      axiosCustom
        .get("anasayfa/bitecekisler")
        .then((response) => {
          this.isler = response.data;
        })
        .catch((error) => {
          console.error("Veri çekme hatası: ", error);
        });
    },

    getDurumAdi(durumId) {
            const durum = this.durumlar.find(item => item.id === durumId);
            return durum ? durum.ad : 'Bilinmeyen Durum';
        },
  }

};
</script>
