<template>

<div class="col">
            <div class="row">
         
      
              <div class="card mb-2" v-for="bildirim in paginatedbildirimler" :key="bildirim.id">
                <div class="row g-0 sh-lg-10 h-auto p-card pt-lg-0 pb-lg-0" >
                <div class="col d-flex align-items-center">
                <p class="mb-0 pe-0 pr-lg-4"> {{ bildirim.bildirim }}</p>
                </div>
                <div class="col-lg-3 d-flex align-items-center">
                <p class="mb-0 text-alternate"> <router-link
                                      v-if="bildirim.link"
                                      :to="bildirim.link" >
                                      Detay
                                    </router-link>
                                  </p>
                </div>
                <div class="col-lg-2 d-flex align-items-center mb-1 mb-lg-0">
                <p class="mb-0 text-alternate">{{ bildirim.olusturma_tarih }}</p>
                </div>
                <div class="col-lg-2 d-flex align-items-center mb-3 mb-lg-0" v-if="bildirim.gorenler==null || !bildirim.gorenler.includes(profil.id)">
                <span class="badge bg-tertiary text-uppercase">Yeni</span>
                </div>
                <div class="col-lg-1 d-flex align-items-center justify-content-left justify-content-lg-end" v-if="bildirim.gorenler==null || !bildirim.gorenler.includes(profil.id)">
                <button class="btn btn-sm btn-icon btn-icon-only btn-outline-primary" type="button" @click="sil(bildirim.id)" >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-check undefined"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg>
                </button>
                </div>
                </div>
                </div>
                
               
            
        
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === bildirimler.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="bildirimlerSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    bildirimler:{
      type: Array,
      required: true
    },
    formKaydetSonrasi: Function,
  },
  data() {
        return {
            profil: [],
           
        };
    },
  computed: {
    filteredbildirimler() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.bildirimler.tumu.filter(
        (bildirim) =>

          bildirim.link.toLowerCase() 
          
          .includes(this.bildirimler.arananKelime.toLowerCase()) ||bildirim.link.toLowerCase()
          
          
          .includes(this.bildirimler.arananKelime.toLowerCase()) ||bildirim.bildirim.toLowerCase()
          
          .includes(this.bildirimler.arananKelime.toLowerCase()) ||bildirim.gorenler.toLowerCase()
          
          .includes(this.bildirimler.arananKelime.toLowerCase())


    
      );
    },
    paginatedbildirimler() {
      const start = (this.bildirimler.seciliSayfa - 1) * this.bildirimler.sayfadaKayit;
      const end = start + this.bildirimler.sayfadaKayit;
      return this.filteredbildirimler.slice(start, end);
    },
    bildirimler_toplamSayfa() {
      const totalFilteredUsers = this.filteredbildirimler.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.bildirimler.sayfadaKayit), 1);
      if (this.bildirimler.seciliSayfa > maxPage) {
        this.bildirimlerSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.bildirimler_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.bildirimler.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  mounted() {
        this.profilGetir();
    },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },

    profilGetir() {
            axiosCustom
                .get("profil")
                .then((response) => {
                    this.profil = response.data;
                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },

    async sil(bildirimId) {
     
        try {
          await axiosCustom.post("bildirimler/sil/" + bildirimId);
          this.$toast.success("Bildirim Gizlendi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      
    },
    bildirimlerSayfaDegistir(page) {
      this.$emit('update:bildirimler',{
      ...this.bildirimler,
      seciliSayfa: page
    });
    },
    bildirimlerSayfadaKayitDegistir(value) {
      this.$emit('update:bildirimler',{
      ...this.bildirimler,
      sayfadaKayit: value
    });
      this.bildirimlerSayfaDegistir(1);
    },
  },
};
</script>
