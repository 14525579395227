<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="Musteri_detay">
        <AppMain>
            <div class="container">
                <div class="page-title-container">
                  <div class="row">
                      <div class="col-12 col-md-7">
                          <h1 class="mb-0 pb-0 display-4" id="title">Müşteri Detay</h1>
                          <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                              <ul class="breadcrumb pt-0">
                                  <li class="breadcrumb-item">
                                      <router-link to="/">Anasayfa</router-link>
                                  </li>
                                  <li class="breadcrumb-item">
                                      <router-link to="/musteriler">Müşteriler</router-link>
                                  </li>
                                  <li class="breadcrumb-item">
                                      <router-link to="/">Müşteri Detay</router-link>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                      <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">

                        <div class="col-12 col-md-5 d-flex align-items-start justify-content-end gap-2">
                            
                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="isler_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>İş Ekle</span>
                            </button>
                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="musterinotlar_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>Müşteri Not Ekle</span>
                            </button>
                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="yapilacaklar_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>Yapılacak Ekle</span>
                            </button>
                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="fikirler_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>İş Fikir Ekle</span>
                            </button>
                            

                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="dosyalar_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>Dosyalar Ekle</span>
                            </button>
                            

                        </div>
                      </div>
                  </div>
              </div>
        

                <div class="row">

                    <div class="col-12 col-xl-4 col-xxl-3">

                        <h2 class="small-title">Müşteri</h2>
                        <div class="card  mb-4">
                            <div class="card-body">
                                <div class="d-flex align-items-center flex-column mb-4">
                                    <div class="d-flex align-items-center flex-column">
                                        <div class="sw-13 position-relative mb-3">
                                            <img :src="$globalVariable.uploadUrl + musteriler.logo"
                                                @error="$globalVariable.varsayilanGorsel" class="img-fluid rounded-xl"
                                                alt="thumb">
                                        </div>
                                        <div class="h5 mb-0">{{ musteriler.unvan }}</div>
                                    </div>
                                </div>


                                <div class="mb-5">
                                    <p class="text-small text-muted mb-2">İLETİŞİM</p>
                                    <div class="row g-0 mb-2">
                                        <div class="col-auto">
                                            <div class="sw-3 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="acorn-icons acorn-icons-phone text-primary">
                                                    <path
                                                        d="M2.36839 7.02416C2.12354 6.39146 2.25595 5.68011 2.67976 5.15035L4.20321 3.24603C5.07388 2.1577 6.76286 2.27131 7.47994 3.46644L8.71763 5.52926C8.89353 5.82244 8.90746 6.18516 8.75456 6.49097L8.10563 7.78883C8.0362 7.92769 7.99726 8.08102 8.00921 8.2358C8.15129 10.0757 9.92438 11.8487 11.7642 11.9908C11.919 12.0028 12.0723 11.9638 12.2112 11.8944L13.5091 11.2455C13.8149 11.0926 14.1776 11.1065 14.4708 11.2824L16.5336 12.5201C17.7287 13.2372 17.8423 14.9262 16.754 15.7968L14.8497 17.3203C14.3199 17.7441 13.6086 17.8765 12.9759 17.6317C7.87729 15.6586 4.34147 12.1228 2.36839 7.02416Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="col text-alternate">{{ musteriler.telefon }}</div>
                                    </div>
                                    <div class="row g-0 mb-2">
                                        <div class="col-auto">
                                            <div class="sw-3 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="acorn-icons acorn-icons-email text-primary">
                                                    <path
                                                        d="M18 7L11.5652 10.2174C10.9086 10.5457 10.5802 10.7099 10.2313 10.7505C10.0776 10.7684 9.92238 10.7684 9.76869 10.7505C9.41977 10.7099 9.09143 10.5457 8.43475 10.2174L2 7">
                                                    </path>
                                                    <path
                                                        d="M14.5 4C15.9045 4 16.6067 4 17.1111 4.33706C17.3295 4.48298 17.517 4.67048 17.6629 4.88886C18 5.39331 18 6.09554 18 7.5L18 12.5C18 13.9045 18 14.6067 17.6629 15.1111C17.517 15.3295 17.3295 15.517 17.1111 15.6629C16.6067 16 15.9045 16 14.5 16L5.5 16C4.09554 16 3.39331 16 2.88886 15.6629C2.67048 15.517 2.48298 15.3295 2.33706 15.1111C2 14.6067 2 13.9045 2 12.5L2 7.5C2 6.09554 2 5.39331 2.33706 4.88886C2.48298 4.67048 2.67048 4.48298 2.88886 4.33706C3.39331 4 4.09554 4 5.5 4L14.5 4Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="col text-alternate">{{ musteriler.email }}</div>
                                    </div>
                                    <div class="row g-0 mb-2">
                                        <div class="col-auto">
                                            <div class="sw-3 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="acorn-icons acorn-icons-pin text-primary">
                                                    <path
                                                        d="M3.5 8.49998C3.5 -0.191432 16.5 -0.191368 16.5 8.49998C16.5 12.6585 12.8256 15.9341 11.0021 17.3036C10.4026 17.7539 9.59777 17.754 8.99821 17.3037C7.17467 15.9342 3.5 12.6585 3.5 8.49998Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="col text-alternate">{{ musteriler.adres }}</div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="card ">
                            <div class="card-body">

                                <div class="nav flex-column" role="tablist">
                                    
                                    <a class="nav-link px-0  border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'isler' }"
                                        @click="aktifSekmeDegistir('isler')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"
                                            fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" class="acorn-icons acorn-icons-book me-2">
                                            <path
                                                d="M6.38964 11.7402C6.22662 11.8489 6.14511 11.9033 6.0914 11.9794 6.06777 12.0129 6.04835 12.0492 6.03357 12.0875 6 12.1744 6 12.2724 6 12.4683L6 17.125C6 17.4761 6 17.6517 6.08427 17.7778 6.12075 17.8324 6.16762 17.8793 6.22222 17.9157 6.34833 18 6.52389 18 6.875 18L11.125 18C11.4761 18 11.6517 18 11.7778 17.9157 11.8324 17.8793 11.8793 17.8324 11.9157 17.7778 12 17.6517 12 17.4761 12 17.125L12 8.81748C12 8.51251 12 8.36002 11.9225 8.28613 11.8897 8.25492 11.8491 8.23318 11.8049 8.22324 11.7004 8.1997 11.5736 8.28429 11.3198 8.45345L6.38964 11.7402zM5.51631 3.75816C5.7173 3.85865 5.8178 3.9089 5.885 3.98999 5.91448 4.02557 5.93888 4.06506 5.95752 4.10734 6 4.2037 6 4.31606 6 4.54078L6 17.125C6 17.4761 6 17.6517 5.91573 17.7778 5.87925 17.8324 5.83238 17.8793 5.77778 17.9157 5.65167 18 5.47611 18 5.125 18L2.875 18C2.52388 18 2.34833 18 2.22222 17.9157 2.16762 17.8793 2.12074 17.8324 2.08427 17.7778 2 17.6517 2 17.4761 2 17.125L2 2.70789C2 2.43878 2 2.30423 2.0693 2.23175 2.09874 2.20095 2.13558 2.17818 2.1763 2.16562 2.27211 2.13606 2.39246 2.19623 2.63316 2.31658L5.51631 3.75816zM12.3896 11.7402C12.2266 11.8489 12.1451 11.9033 12.0914 11.9794 12.0678 12.0129 12.0483 12.0492 12.0336 12.0875 12 12.1744 12 12.2724 12 12.4683L12 17.125C12 17.4761 12 17.6517 12.0843 17.7778 12.1207 17.8324 12.1676 17.8793 12.2222 17.9157 12.3483 18 12.5239 18 12.875 18L17.125 18C17.4761 18 17.6517 18 17.7778 17.9157 17.8324 17.8793 17.8793 17.8324 17.9157 17.7778 18 17.6517 18 17.4761 18 17.125L18 8.81748C18 8.51251 18 8.36002 17.9225 8.28614 17.8897 8.25492 17.8491 8.23318 17.8049 8.22324 17.7004 8.1997 17.5736 8.28429 17.3198 8.45345L12.3896 11.7402z">
                                            </path>
                                        </svg>
                                        <span class="align-middle">İşler</span>
                                    </a>
                                    
                                    <a class="nav-link px-0  border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'musterinotlar' }"
                                        @click="aktifSekmeDegistir('musterinotlar')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-book me-2"><path d="M3 5.5C3 4.09554 3 3.39331 3.33706 2.88886C3.48298 2.67048 3.67048 2.48298 3.88886 2.33706C4.39331 2 5.09554 2 6.5 2H13.5C14.9045 2 15.6067 2 16.1111 2.33706C16.3295 2.48298 16.517 2.67048 16.6629 2.88886C17 3.39331 17 4.09554 17 5.5V14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18H6.5C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5V5.5Z"></path><path d="M8 6H12M8 10H12M8 14H12M2 8H4M2 12H4"></path></svg>
                                        <span class="align-middle">Müşteri Notları</span>
                                    </a>
                                    
                                    <a class="nav-link px-0  border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'yapilacaklar' }"
                                        @click="aktifSekmeDegistir('yapilacaklar')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-book me-2"><path d="M3 5.5C3 4.09554 3 3.39331 3.33706 2.88886C3.48298 2.67048 3.67048 2.48298 3.88886 2.33706C4.39331 2 5.09554 2 6.5 2H13.5C14.9045 2 15.6067 2 16.1111 2.33706C16.3295 2.48298 16.517 2.67048 16.6629 2.88886C17 3.39331 17 4.09554 17 5.5V14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18H6.5C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5V5.5Z"></path><path d="M11 2L11 9.0072C11 9.36798 11 9.54837 10.9118 9.62325C10.8749 9.65464 10.8296 9.6746 10.7815 9.6807C10.6667 9.69526 10.5336 9.57353 10.2673 9.33009L9.09042 8.2541C8.85159 8.03574 8.73217 7.92656 8.59205 7.90031C8.53121 7.88892 8.46879 7.88892 8.40795 7.90031C8.26783 7.92656 8.14841 8.03574 7.90958 8.2541L6.73271 9.33009C6.46645 9.57353 6.33332 9.69526 6.21855 9.6807C6.17044 9.6746 6.12515 9.65464 6.08818 9.62325C6 9.54837 6 9.36798 6 9.0072L6 2"></path></svg>
                            
                                        <span class="align-middle">Yapılacaklar</span>
                                    </a>
                                    
                                    <a class="nav-link px-0  border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'fikirler' }"
                                        @click="aktifSekmeDegistir('fikirler')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-book me-2"><path d="M8 14L8.28283 16.4684C8.35276 17.0786 8.38772 17.3837 8.55097 17.6009C8.63047 17.7067 8.73017 17.7956 8.8443 17.8625C9.07862 18 9.38575 18 10 18V18C10.6143 18 10.9214 18 11.1557 17.8625C11.2698 17.7956 11.3695 17.7067 11.449 17.6009C11.6123 17.3837 11.6472 17.0786 11.7172 16.4684L12 14"></path><path d="M15 7C15 10.1518 13.5 10.5 13 11.5C12.5 12.5 12.6096 14.5 10 14.5C7.39038 14.5 7.5 12.5 7 11.5C6.5 10.5 5 10.1518 5 7C5 4.23858 7.23858 2 10 2C12.7614 2 15 4.23858 15 7Z"></path><path d="M18 7 17.5 7M16.9658 3 16.4829 3.12941M16.9658 11.2588 16.4829 11.1294M2 7 2.5 7M3.03418 3 3.51714 3.12941M3.03418 11.2588 3.51714 11.1294"></path></svg>
                                        <span class="align-middle">İş Fikirleri</span>
                                    </a>
                                    

                                   
                                    <a class="nav-link px-0 border-bottom border-separator-light"
                                        :class="{ 'active': aktifSekme === 'dosyalar' }"
                                        @click="aktifSekmeDegistir('dosyalar')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"
                                            fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" class="acorn-icons acorn-icons-lock-off me-2">
                                            <path
                                                d="M16.25 2H3.75C3.04777 2 2.69665 2 2.44443 2.16853C2.33524 2.24149 2.24149 2.33524 2.16853 2.44443C2 2.69665 2 3.04777 2 3.75V5.25C2 5.95223 2 6.30335 2.16853 6.55557C2.24149 6.66476 2.33524 6.75851 2.44443 6.83147C2.69665 7 3.04777 7 3.75 7H16.25C16.9522 7 17.3033 7 17.5556 6.83147C17.6648 6.75851 17.7585 6.66476 17.8315 6.55557C18 6.30335 18 5.95223 18 5.25V3.75C18 3.04777 18 2.69665 17.8315 2.44443C17.7585 2.33524 17.6648 2.24149 17.5556 2.16853C17.3033 2 16.9522 2 16.25 2Z">
                                            </path>
                                            <path
                                                d="M13.5 7C14.9045 7 15.6067 7 16.1111 7.33706C16.3295 7.48298 16.517 7.67048 16.6629 7.88886C17 8.39331 17 9.09554 17 10.5L17 14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18L6.5 18C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5L3 10.5C3 9.09554 3 8.39331 3.33706 7.88886C3.48298 7.67048 3.67048 7.48298 3.88886 7.33706C4.39331 7 5.09554 7 6.5 7L13.5 7Z">
                                            </path>
                                            <path d="M9 10H11"></path>
                                        </svg>
                                        <span class="align-middle">Dosyalar</span>
                                    </a>
                                    

                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="col-12 col-xl-8 col-xxl-9 mb-5 tab-content">

                        
                        <div class="tab-pane active" v-show="aktifSekme === 'isler'">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">İşler</h2>
                                    <isCard :isler="isler" :duzenle="isler_duzenle"   :formKaydetSonrasi="musterilerDetayGetir"  @update:isler="islerGuncelle" />





                                        <div class="modal fade modal-close-out show" style="display: block;" v-if="isler.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h5 class="modal-title" v-if="isler.islem == 'ekle'"> Yeni İş Ekle </h5>
                                            <h5 class="modal-title" v-if="isler.islem == 'duzenle'">İş Düzenleme </h5>
                                            <button type="button" class="btn-close" @click="isler_iptal"></button>
                                            </div>
                                            <div class="modal-body">

                                                <isForm :formIslem="isler.islem" :formData="isler.secili"
                                                :formKaydetSonrasi="musterilerDetayGetir" :formIptal="isler_iptal" />


                                            </div>
                                        </div>

                                        </div>
                                    </div>
         

                                </div>
                            </div>

                        </div>
                        
                        <div class="tab-pane active" v-show="aktifSekme === 'musterinotlar'">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">Müşteri Notları</h2>
                                    <musterinotCard :musterinotlar="musterinotlar" :duzenle="musterinotlar_duzenle"   :formKaydetSonrasi="musterilerDetayGetir"  @update:musterinotlar="musterinotlarGuncelle" />





                                        <div class="modal fade modal-close-out show" style="display: block;" v-if="musterinotlar.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h5 class="modal-title" v-if="musterinotlar.islem == 'ekle'"> Yeni Müşteri Not Ekle </h5>
                                            <h5 class="modal-title" v-if="musterinotlar.islem == 'duzenle'">Müşteri Not Düzenleme </h5>
                                            <button type="button" class="btn-close" @click="musterinotlar_iptal"></button>
                                            </div>
                                            <div class="modal-body">

                                                <musterinotForm :formIslem="musterinotlar.islem" :formData="musterinotlar.secili"
                                                :formKaydetSonrasi="musterilerDetayGetir" :formIptal="musterinotlar_iptal" />


                                            </div>
                                        </div>

                                        </div>
                                    </div>
         

                                </div>
                            </div>

                        </div>
                        
                        <div class="tab-pane active" v-show="aktifSekme === 'yapilacaklar'">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">Yapılacaklar</h2>
                                    <yapilacakCard :yapilacaklar="yapilacaklar" :duzenle="yapilacaklar_duzenle"   :formKaydetSonrasi="musterilerDetayGetir"  @update:yapilacaklar="yapilacaklarGuncelle" />





                                        <div class="modal fade modal-close-out show" style="display: block;" v-if="yapilacaklar.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h5 class="modal-title" v-if="yapilacaklar.islem == 'ekle'"> Yeni Yapılacak Ekle </h5>
                                            <h5 class="modal-title" v-if="yapilacaklar.islem == 'duzenle'">Yapılacak Düzenleme </h5>
                                            <button type="button" class="btn-close" @click="yapilacaklar_iptal"></button>
                                            </div>
                                            <div class="modal-body">

                                                <yapilacakForm :formIslem="yapilacaklar.islem" :formData="yapilacaklar.secili"
                                                :formKaydetSonrasi="musterilerDetayGetir" :formIptal="yapilacaklar_iptal" />


                                            </div>
                                        </div>

                                        </div>
                                    </div>
         

                                </div>
                            </div>

                        </div>
                        
                        <div class="tab-pane active" v-show="aktifSekme === 'fikirler'">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">İş Fikirleri</h2>
                                    <isfikirCard :fikirler="fikirler" :duzenle="fikirler_duzenle"   :formKaydetSonrasi="musterilerDetayGetir"  @update:fikirler="fikirlerGuncelle" />





                                        <div class="modal fade modal-close-out show" style="display: block;" v-if="fikirler.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h5 class="modal-title" v-if="fikirler.islem == 'ekle'"> Yeni İş Fikir Ekle </h5>
                                            <h5 class="modal-title" v-if="fikirler.islem == 'duzenle'">İş Fikir Düzenleme </h5>
                                            <button type="button" class="btn-close" @click="fikirler_iptal"></button>
                                            </div>
                                            <div class="modal-body">

                                                <isfikirForm :formIslem="fikirler.islem" :formData="fikirler.secili"
                                                :formKaydetSonrasi="musterilerDetayGetir" :formIptal="fikirler_iptal" />


                                            </div>
                                        </div>

                                        </div>
                                    </div>
         

                                </div>
                            </div>

                        </div>
                        

                        
                        <div class="tab-pane active" v-show="aktifSekme === 'dosyalar'">
                            <h2 class="small-title">Dosyalar</h2>






                            <div class="modal fade modal-close-out show" style="display: block;" v-if="dosyalarEkleModal">
                                        <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h5 class="modal-title" > Dosyalar Ekleme </h5>
                                           <button type="button" class="btn-close" @click="dosyalar_iptal"></button>
                                            </div>
                                            <div class="modal-body">

                                                <input type="file" class="form-control"  @change="dosyalarIslem" multiple />
                                    <div class="mt-2">
                                        <div
                                        v-for="(file, index) in seciliDosyalar"
                                        :key="index"
                                        class="d-flex justify-content-between align-items-center"
                                        >
                                        <span>{{ file.name }}</span>
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-danger"
                                            @click="dosyalarIslem(index)"
                                        >
                                            Kaldır
                                        </button>
                                        </div>
                                    </div>
                                   
                                    <div v-if="isUploading">
                                    {{ uploadFileNmae }} Dosyası Yükleniyor
                                     </div>
                                    <div class="progress" v-if="isUploading">
                                        <div class="progress-bar" role="progressbar" :style="{ width: `${uploadProgress}%` }">
                                            {{ uploadProgress }}%
                                        </div>
                                        </div>

                                    <div class="d-flex justify-content-around gap-2 mt-4"> 
                                    <button class="btn btn-outline-primary" @click="dosyalar_iptal">İptal</button>
                                    <button class="btn btn-primary w-100"  @click="cokluDosyalarYukle">Kaydet</button>
                                    </div>
                                            </div>
                                        </div>

                                        </div>
                                    </div>

                            <div class="mt-4 d-flex flex-s flex-wrap">

                                <div class="card mb-2  w-100" v-for="(file, index) in yuklenmisDosyalar" :key="index">
                                    <div class="row g-0 sh-12 ">
                                        <div class="col-auto h-100" >
                                            <a :href="$globalVariable.uploadUrl + file.dosya">
                                                <img :src="$globalVariable.uploadUrl + file.dosya"
                                                @error="$globalVariable.varsayilanGorsel"
                                                    alt="alternate text"
                                                    class="card-img card-img-horizontal sw-13 sw-lg-15 ">
                                            </a>
                                        </div>
                                        <div class="col">
                                            <div class="card-body pt-0 pb-0 h-100">
                                                <div class="row g-0 h-100 align-content-center">


                                                    <div class="col w-100 d-flex justify-content-around mb-1">
                                                        <div class="me-3 me-md-7">
                                                            <p class="text-small text-muted mb-1">Ad</p>
                                                            <p class="mb-0">{{ file.ad }}</p>
                                                        </div>
                                                        <div class="me-3 me-md-7">
                                                            <p class="text-small text-muted mb-1">Açıklama</p>
                                                            <p class="mb-0">{{ file.aciklama }}</p>
                                                        </div>
                                                        <div class="me-3 me-md-7">
                                                            <p class="text-small text-muted mb-1">Yükleme Tarihi</p>
                                                            <p class="mb-0">{{ file.olusturma_tarih }} </p>
                                                        </div>


                                                    </div>
                                                    <div class="col-auto d-flex align-items-center justify-content-md-end" >

                                                        <a :href="$globalVariable.uploadUrl + file.dosya" target="_blank"  
                                                            class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1"  >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                                viewBox="0 0 20 20" fill="none" stroke="currentColor"
                                                                stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="acorn-icons acorn-icons-bin undefined">
                                                                <path
                                                                    d="M2 14V14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18H14.5C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5V14">
                                                                </path>
                                                                <path
                                                                    d="M14 10 10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L6 10M10 2 10 13">
                                                                </path>
                                                            </svg>
                                                            <span class="d-none d-xxl-inline-block">İndir</span>
                                                        </a>
                                                        <button class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1"   @click="dosyalarSil(file.id)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                                viewBox="0 0 20 20" fill="none" stroke="currentColor"
                                                                stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="acorn-icons acorn-icons-bin undefined">
                                                                <path
                                                                    d="M4 5V14.5C4 15.9045 4 16.6067 4.33706 17.1111C4.48298 17.3295 4.67048 17.517 4.88886 17.6629C5.39331 18 6.09554 18 7.5 18H12.5C13.9045 18 14.6067 18 15.1111 17.6629C15.3295 17.517 15.517 17.3295 15.6629 17.1111C16 16.6067 16 15.9045 16 14.5V5">
                                                                </path>
                                                                <path
                                                                    d="M14 5L13.9424 4.74074C13.6934 3.62043 13.569 3.06028 13.225 2.67266C13.0751 2.50368 12.8977 2.36133 12.7002 2.25164C12.2472 2 11.6734 2 10.5257 2L9.47427 2C8.32663 2 7.75281 2 7.29981 2.25164C7.10234 2.36133 6.92488 2.50368 6.77496 2.67266C6.43105 3.06028 6.30657 3.62044 6.05761 4.74074L6 5">
                                                                </path>
                                                                <path d="M2 5H18M12 9V13M8 9V13"></path>
                                                            </svg>
                                                            <span class="d-none d-xxl-inline-block">Sil</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            </div>
                        
                        </div>

                    </div>
                </div>

            </AppMain>
        </div>
</template>
  
<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';
import AppMain from "@/components/AppMain.vue";

    
import isCard from "@/components/isler/isCard.vue";
import isForm from "@/components/isler/isForm.vue";
    
import musterinotCard from "@/components/musterinotlar/musterinotCard.vue";
import musterinotForm from "@/components/musterinotlar/musterinotForm.vue";
    
import yapilacakCard from "@/components/yapilacaklar/yapilacakCard.vue";
import yapilacakForm from "@/components/yapilacaklar/yapilacakForm.vue";
    
import isfikirCard from "@/components/fikirler/isfikirCard.vue";
import isfikirForm from "@/components/fikirler/isfikirForm.vue";



export default {
    name: "musterilerDetayView",
    components: {
        AppMain,
            
        isCard, 
        isForm,
            
        musterinotCard, 
        musterinotForm,
            
        yapilacakCard, 
        yapilacakForm,
            
        isfikirCard, 
        isfikirForm,
        
    },
    data() {
        return {
            musteriler: [],
           
            aktifSekme: 'isler',
            hareketSayfadaKayit: 10,
            hareketSeciliSayfa: 1,

            
            isler: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
                filtreCard:'tumisler',
            },
            
            musterinotlar: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },
            
            yapilacaklar: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },
            
            fikirler: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },
            
          
            
            yuklenmisDosyalar: [],
            dosyalarEkleModal: false,
            seciliDosyalar:[],

            isUploading: false,
            uploadProgress: 0,
            uploadFileName:'',
            


        };
    },
    mounted() {
        this.ilkTabTanimla();
        this.musterilerDetayGetir();
    },

    methods: {
        yetki_kontrol(yetki) {
            return yetkiKontrol(yetki);
        },
        ilkTabTanimla() {
            const tab = this.$route.params.tab;
                    if(tab=='bilgiler'){
                        this.aktifSekme = 'isler';
                        this.aktifSekmeDegistir("isler");
                    }else{
                        this.aktifSekme = tab;
                    }
                    
        },
        musterilerDetayGetir() {
            const id = this.$route.params.id;

            axiosCustom
                .get(`musteriler/detay/${id}`)
                .then((response) => {
                    this.musteriler = response.data;
           
                    
                    this.isler.tumu = response.data.isler;
                    this.isler.modal = false;
                    
                    this.musterinotlar.tumu = response.data.musterinotlar;
                    this.musterinotlar.modal = false;
                    
                    this.yapilacaklar.tumu = response.data.yapilacaklar;
                    this.yapilacaklar.modal = false;
                    
                    this.fikirler.tumu = response.data.fikirler;
                    this.fikirler.modal = false;
                    

                    
                    this.yuklenmisDosyalar = response.data.dosyalar;
                    

                    
                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },

    
        aktifSekmeDegistir(sekme) {
            this.aktifSekme = sekme;
            const id = this.$route.params.id;
            this.$router.push('/musteriler/'+id+'/'+sekme);
        },




        
        islerGuncelle(veriler) {
            this.isler = veriler;
        },
        isler_ekle() {
            this.isler.secili = {'musteri':this.musteriler.id};
            this.isler.islem = "ekle";
            this.isler.modal = true;
            this.aktifSekmeDegistir("isler");
        },
        isler_duzenle(veri) {
            this.isler.secili = veri;
            this.isler.islem = "duzenle";
            this.isler.modal = true;
        },
        isler_iptal() {
            this.isler.secili = {};
            this.isler.modal = false;
        },
        
        musterinotlarGuncelle(veriler) {
            this.musterinotlar = veriler;
        },
        musterinotlar_ekle() {
            this.musterinotlar.secili = {'musteri':this.musteriler.id};
            this.musterinotlar.islem = "ekle";
            this.musterinotlar.modal = true;
            this.aktifSekmeDegistir("musterinotlar");
        },
        musterinotlar_duzenle(veri) {
            this.musterinotlar.secili = veri;
            this.musterinotlar.islem = "duzenle";
            this.musterinotlar.modal = true;
        },
        musterinotlar_iptal() {
            this.musterinotlar.secili = {};
            this.musterinotlar.modal = false;
        },
        
        yapilacaklarGuncelle(veriler) {
            this.yapilacaklar = veriler;
        },
        yapilacaklar_ekle() {
            this.yapilacaklar.secili = {'musteriler':this.musteriler.id};
            this.yapilacaklar.islem = "ekle";
            this.yapilacaklar.modal = true;
            this.aktifSekmeDegistir("yapilacaklar");
        },
        yapilacaklar_duzenle(veri) {
            this.yapilacaklar.secili = veri;
            this.yapilacaklar.islem = "duzenle";
            this.yapilacaklar.modal = true;
        },
        yapilacaklar_iptal() {
            this.yapilacaklar.secili = {};
            this.yapilacaklar.modal = false;
        },
        
        fikirlerGuncelle(veriler) {
            this.fikirler = veriler;
        },
        fikirler_ekle() {
            this.fikirler.secili = {'musteri':this.musteriler.id};
            this.fikirler.islem = "ekle";
            this.fikirler.modal = true;
            this.aktifSekmeDegistir("fikirler");
        },
        fikirler_duzenle(veri) {
            this.fikirler.secili = veri;
            this.fikirler.islem = "duzenle";
            this.fikirler.modal = true;
        },
        fikirler_iptal() {
            this.fikirler.secili = {};
            this.fikirler.modal = false;
        },
        


       

        
                   
        dosyalar_ekle() {
            this.dosyalarEkleModal = true;
            this.aktifSekmeDegistir("dosyalar");
        },
        dosyalar_iptal() {
            this.dosyalarEkleModal = false;
            this.aktifSekmeDegistir("dosyalar");
        },
        dosyalarIslem(eventOrIndex) {
        if (eventOrIndex instanceof Event) {
          // Dosyalar seçildiğinde
          const files = eventOrIndex.target.files;
          for (let i = 0; i < files.length; i++) {
            this.seciliDosyalar.push(files[i]);
          }
         
            } else {
            // Kaldır düğmesine basıldığında
            this.seciliDosyalar.splice(eventOrIndex, 1);
            }
        },
      
  async cokluDosyalarYukle() {
const CHUNK_SIZE = 1024 * 1024; // 1 MB

for (const file of this.seciliDosyalar) {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let chunk = 0; chunk < totalChunks; chunk++) {
        const start = chunk * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);

        const chunkData = file.slice(start, end);

        let formData = new FormData();
        formData.append("fileName", file.name);
        this.uploadFileNmae = file.name;
        formData.append("fileChunk", chunkData);
        formData.append("currentChunk", chunk);
        formData.append("totalChunks", totalChunks);

        try {
            await axiosCustom.post("musteriler/dosyalaryukle/" + this.musteriler.id, formData);
            this.isUploading = true;
         
            this.uploadProgress = (chunk * totalChunks) / 100;

           

        } catch (error) {
            console.error('Dosyalar yükleme hatası: ', error);
            this.$toast.error('Dosyalar yükleme hatası');
            // Handle error
            return;
        }
    }

  
}

// Tüm dosyaların tüm chunk'ları yüklendiyse, işlemi tamamla
this.isUploading = false;
this.uploadProgress = 100;

this.$toast.success("Dosyalar Yüklendi");
this.musterilerDetayGetir();
this.dosyalarEkleModal = false;
},

        async dosyalarSil(id) {
            const result = await this.$swal({
                title: "Emin misiniz?",
                text: "Bu işlemi geri alamayacaksınız!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Evet, sil!",
                cancelButtonText: "İptal",
            });

            if (result.isConfirmed) {
                try {
                await axiosCustom.post("musteriler/dosyalarsil/" + id);
                this.$toast.success("Dosyalar Silindi");
                this.musterilerDetayGetir();
                } catch (error) {
                console.error("Silme hatası: ", error);
                this.$toast.error("Silme hatası");
                }
            }
         },
      
        
         



    }
};
</script>
  

  