<template>

<div class="col">
            <div class="row">
         
              <template  v-if="tur=='liste'">
                <!-- musteri  liste -->
                
                <div class="card mb-2 sh-10 sh-md-8"  v-for="musteri in paginatedmusteriler" :key="musteri.id" >
                          <div class="card-body pt-0 pb-0 h-100">
                            <div class="row g-0 h-100 align-content-center">
                              <div class="col-12 col-md-4 d-flex align-items-center mb-2 mb-md-0">
                                <span class="body-link text-truncate"> {{ musteri.unvan }}</span>
                              </div>
                            

                              <div class="col-12 col-md-3 d-flex align-items-center justify-content-end text-muted text-medium">
                                <span>{{ musteri.unvan }}</span>
                              </div>
                              <div class="col-12 col-md-3 d-flex align-items-center justify-content-end text-muted text-medium">
                                
                              </div>

                              <div class="col-12 col-md-2 d-flex justify-content-end">
                                <button v-if="yetki_kontrol('musteri_duzenle')" @click="duzenle(musteri)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                  <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                                </button>
                                <button  v-if="yetki_kontrol('musteri_sil')"  @click="sil(musteri.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                  <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                                </button>
                              </div>
                            </div>
                          </div>
                  </div>
            
                <!-- musteri  liste -->
              </template>
              <template  v-if="tur=='kart' || tur==null">
                <!-- musteri Kart -->
                <div class="col-md-3 mt-4"  v-for="musteri in paginatedmusteriler" :key="musteri.id" >
                <div class="card h-100">
                  <router-link v-if="yetki_kontrol('musteri_goster')"  :to="'musteriler/' + musteri.id">
                    <img :src="$globalVariable.uploadUrl+musteri.logo" @error="$globalVariable.varsayilanGorsel" class="card-img-top sh-30"  />
                  </router-link>
                  <div class="card-body">
                    <router-link v-if="yetki_kontrol('musteri_goster')" :to="'musteriler/' + musteri.id"  >{{ musteri.unvan }} </router-link>
                    <div class="text-muted mb-1"></div>

                    <div class="row g-0 align-items-center mb-2">
                      <div class="col-auto">
                        <div class="sw-3 d-flex justify-content-center align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-phone text-primary"><path d="M2.36839 7.02416C2.12354 6.39146 2.25595 5.68011 2.67976 5.15035L4.20321 3.24603C5.07388 2.1577 6.76286 2.27131 7.47994 3.46644L8.71763 5.52926C8.89353 5.82244 8.90746 6.18516 8.75456 6.49097L8.10563 7.78883C8.0362 7.92769 7.99726 8.08102 8.00921 8.2358C8.15129 10.0757 9.92438 11.8487 11.7642 11.9908C11.919 12.0028 12.0723 11.9638 12.2112 11.8944L13.5091 11.2455C13.8149 11.0926 14.1776 11.1065 14.4708 11.2824L16.5336 12.5201C17.7287 13.2372 17.8423 14.9262 16.754 15.7968L14.8497 17.3203C14.3199 17.7441 13.6086 17.8765 12.9759 17.6317C7.87729 15.6586 4.34147 12.1228 2.36839 7.02416Z"></path></svg>
                        </div>
                      </div>
                      <div class="col ps-3">
                        <div class="d-flex align-items-center lh-1-25">
                          {{ musteri.unvan }}
                        </div>
                      </div>
                    </div>
                    <div class="row g-0 align-items-center mb-2">
                      <div class="col-auto">
                        <div class="sw-3 d-flex justify-content-center align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-email text-primary"><path d="M18 7L11.5652 10.2174C10.9086 10.5457 10.5802 10.7099 10.2313 10.7505C10.0776 10.7684 9.92238 10.7684 9.76869 10.7505C9.41977 10.7099 9.09143 10.5457 8.43475 10.2174L2 7"></path><path d="M14.5 4C15.9045 4 16.6067 4 17.1111 4.33706C17.3295 4.48298 17.517 4.67048 17.6629 4.88886C18 5.39331 18 6.09554 18 7.5L18 12.5C18 13.9045 18 14.6067 17.6629 15.1111C17.517 15.3295 17.3295 15.517 17.1111 15.6629C16.6067 16 15.9045 16 14.5 16L5.5 16C4.09554 16 3.39331 16 2.88886 15.6629C2.67048 15.517 2.48298 15.3295 2.33706 15.1111C2 14.6067 2 13.9045 2 12.5L2 7.5C2 6.09554 2 5.39331 2.33706 4.88886C2.48298 4.67048 2.67048 4.48298 2.88886 4.33706C3.39331 4 4.09554 4 5.5 4L14.5 4Z"></path></svg>
                         
                        </div>
                      </div>
                      <div class="col ps-3">
                        <div class="d-flex align-items-center lh-1-25">
                          {{ musteri.unvan }}
                        </div>
                      </div>
                    </div>
                    <div class="mt-4 text-center">
                      <button v-if="yetki_kontrol('musteri_duzenle')" @click="duzenle(musteri)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                      </button>
                      <button  v-if="yetki_kontrol('musteri_sil')"  @click="sil(musteri.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                <!-- musteri Kart -->
              </template>
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === musteriler.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="musterilerSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    musteriler:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  computed: {
    filteredmusteriler() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.musteriler.tumu.filter(
        (musteri) =>

          musteri.unvan.toLowerCase() 
          
          .includes(this.musteriler.arananKelime.toLowerCase()) ||musteri.unvan.toLowerCase()
          
          .includes(this.musteriler.arananKelime.toLowerCase()) ||musteri.telefon.toLowerCase()
          
          .includes(this.musteriler.arananKelime.toLowerCase()) ||musteri.email.toLowerCase()
          
          .includes(this.musteriler.arananKelime.toLowerCase()) ||musteri.adres.toLowerCase()
          
          .includes(this.musteriler.arananKelime.toLowerCase()) ||musteri.kullaniciadi.toLowerCase()
          
          
          .includes(this.musteriler.arananKelime.toLowerCase())


    
      );
    },
    paginatedmusteriler() {
      const start = (this.musteriler.seciliSayfa - 1) * this.musteriler.sayfadaKayit;
      const end = start + this.musteriler.sayfadaKayit;
      return this.filteredmusteriler.slice(start, end);
    },
    musteriler_toplamSayfa() {
      const totalFilteredUsers = this.filteredmusteriler.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.musteriler.sayfadaKayit), 1);
      if (this.musteriler.seciliSayfa > maxPage) {
        this.musterilerSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.musteriler_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.musteriler.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(musteriId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("musteriler/sil/" + musteriId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    musterilerSayfaDegistir(page) {
      this.$emit('update:musteriler',{
      ...this.musteriler,
      seciliSayfa: page
    });
    },
    musterilerSayfadaKayitDegistir(value) {
      this.$emit('update:musteriler',{
      ...this.musteriler,
      sayfadaKayit: value
    });
      this.musterilerSayfaDegistir(1);
    },
  },
};
</script>
