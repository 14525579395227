<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="Musteri_detay">
        <AppMainMusteri>
            <div class="container">
                <div class="page-title-container">
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="mb-0 pb-0 display-4" id="title">{{ musteriler.unvan }}</h1>
                            <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                                <ul class="breadcrumb pt-0">

                                    <li class="breadcrumb-item">
                                        <router-link to="/">Müşteri Paneli</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">

                            <div class="col-12 col-md-5 d-flex align-items-start justify-content-end gap-2">







                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-12 col-xl-4 col-xxl-3">

                        <h2 class="small-title">Müşteri</h2>
                        <div class="card  mb-4">
                            <div class="card-body">
                                <div class="d-flex align-items-center flex-column mb-4">
                                    <div class="d-flex align-items-center flex-column">
                                        <div class="sw-13 position-relative mb-3">
                                            <img :src="$globalVariable.uploadUrl + musteriler.logo"
                                                @error="$globalVariable.varsayilanGorsel" class="img-fluid rounded-xl"
                                                alt="thumb">
                                        </div>
                                        <div class="h5 mb-0">{{ musteriler.unvan }}</div>
                                    </div>
                                </div>


                                <div class="mb-5">
                                    <p class="text-small text-muted mb-2">İLETİŞİM</p>
                                    <div class="row g-0 mb-2">
                                        <div class="col-auto">
                                            <div class="sw-3 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="acorn-icons acorn-icons-phone text-primary">
                                                    <path
                                                        d="M2.36839 7.02416C2.12354 6.39146 2.25595 5.68011 2.67976 5.15035L4.20321 3.24603C5.07388 2.1577 6.76286 2.27131 7.47994 3.46644L8.71763 5.52926C8.89353 5.82244 8.90746 6.18516 8.75456 6.49097L8.10563 7.78883C8.0362 7.92769 7.99726 8.08102 8.00921 8.2358C8.15129 10.0757 9.92438 11.8487 11.7642 11.9908C11.919 12.0028 12.0723 11.9638 12.2112 11.8944L13.5091 11.2455C13.8149 11.0926 14.1776 11.1065 14.4708 11.2824L16.5336 12.5201C17.7287 13.2372 17.8423 14.9262 16.754 15.7968L14.8497 17.3203C14.3199 17.7441 13.6086 17.8765 12.9759 17.6317C7.87729 15.6586 4.34147 12.1228 2.36839 7.02416Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="col text-alternate">{{ musteriler.telefon }}</div>
                                    </div>
                                    <div class="row g-0 mb-2">
                                        <div class="col-auto">
                                            <div class="sw-3 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="acorn-icons acorn-icons-email text-primary">
                                                    <path
                                                        d="M18 7L11.5652 10.2174C10.9086 10.5457 10.5802 10.7099 10.2313 10.7505C10.0776 10.7684 9.92238 10.7684 9.76869 10.7505C9.41977 10.7099 9.09143 10.5457 8.43475 10.2174L2 7">
                                                    </path>
                                                    <path
                                                        d="M14.5 4C15.9045 4 16.6067 4 17.1111 4.33706C17.3295 4.48298 17.517 4.67048 17.6629 4.88886C18 5.39331 18 6.09554 18 7.5L18 12.5C18 13.9045 18 14.6067 17.6629 15.1111C17.517 15.3295 17.3295 15.517 17.1111 15.6629C16.6067 16 15.9045 16 14.5 16L5.5 16C4.09554 16 3.39331 16 2.88886 15.6629C2.67048 15.517 2.48298 15.3295 2.33706 15.1111C2 14.6067 2 13.9045 2 12.5L2 7.5C2 6.09554 2 5.39331 2.33706 4.88886C2.48298 4.67048 2.67048 4.48298 2.88886 4.33706C3.39331 4 4.09554 4 5.5 4L14.5 4Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="col text-alternate">{{ musteriler.email }}</div>
                                    </div>
                                    <div class="row g-0 mb-2">
                                        <div class="col-auto">
                                            <div class="sw-3 me-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                    viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="acorn-icons acorn-icons-pin text-primary">
                                                    <path
                                                        d="M3.5 8.49998C3.5 -0.191432 16.5 -0.191368 16.5 8.49998C16.5 12.6585 12.8256 15.9341 11.0021 17.3036C10.4026 17.7539 9.59777 17.754 8.99821 17.3037C7.17467 15.9342 3.5 12.6585 3.5 8.49998Z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="col text-alternate">{{ musteriler.adres }}</div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>


                    <div class="col-12 col-xl-8 col-xxl-9 mb-5 tab-content">


                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="small-title">İşler</h2>
                                <div class="col">
                                    <div class="row" v-for="is in paginatedisler" :key="is.id">


                                        <div class="card mb-2 pb-3">
                                            <div class="row g-0 sh-12">
                                                <div class="col">
                                                    <div class="card-body pt-0 pb-0 h-100">



                                                        <div class="row g-0 h-100 align-content-center">
                                                            <div
                                                                class="col-auto d-flex flex-column mb-2 mb-md-0 position-static">
                                                                <router-link to="/musteripanel"> {{ is.ad }} </router-link>
                                                                <div class="text-small text-muted text-truncate">İş Türü: {{
                                                                    isturBilgileri(is.istur).ad }} </div>
                                                            </div>

                                                            <div class="col w-100 d-flex justify-content-around mb-1">
                                                               
                                                                <div class="me-3 me-md-7">
                                                                    <p class="text-small text-muted mb-1">Durum</p>
                                                                    <p class="mb-0"><span
                                                                            class="badge bg-outline-primary me-1">{{
                                                                                getDurumAdi(is.durum) }} </span></p>
                                                                </div>
                                                                <div class="me-3 me-md-7">
                                                                <button 
                                                                    class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1"  v-if="is.durum!='tamamlandi'" @click="revize_ekle(is.id)">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-bin undefined"><path d="M6.00008 2.73606 6.00009 9.19098C6.0001 9.56267 5.60894 9.80442 5.27649 9.63819L3.41494 8.70741C3.15184 8.57587 2.84324 8.56555 2.58596 8.70814 2.27686 8.87944 1.84943 9.15067 1.5001 9.5.883224 10.1169 1.68396 10.7338 1.70487 10.7497L1.70627 10.7507 5.73239 13.7974C5.9061 13.9289 6.11798 14 6.33581 14H13.4434C13.6963 14 13.9398 13.9041 14.1249 13.7318L14.6816 13.2133C14.8847 13.0241 15.0001 12.759 15.0001 12.4815V8.34711C15.0001 7.85827 14.6467 7.44108 14.1645 7.36072L9.8357 6.63925C9.35351 6.55889 9.0001 6.1417 9.00009 5.65286L9.00008 2.73605C9.00008 2.58081 8.96531 2.42668 8.87614 2.2996 8.67132 2.00771 8.21263 1.49999 7.50008 1.49999 6.78754 1.49999 6.32885 2.00771 6.12403 2.2996 6.03485 2.42668 6.00008 2.58081 6.00008 2.73606zM12.25 14C12.9522 14 13.3033 14 13.5556 14.1685 13.6648 14.2415 13.7585 14.3352 13.8315 14.4444 14 14.6967 14 15.0478 14 15.75L14 16.25C14 16.9522 14 17.3033 13.8315 17.5556 13.7585 17.6648 13.6648 17.7585 13.5556 17.8315 13.3033 18 12.9522 18 12.25 18L7.75 18C7.04777 18 6.69665 18 6.44443 17.8315 6.33524 17.7585 6.24149 17.6648 6.16853 17.5556 6 17.3033 6 16.9522 6 16.25L6 15.75C6 15.0478 6 14.6967 6.16853 14.4444 6.24149 14.3352 6.33524 14.2415 6.44443 14.1685 6.69665 14 7.04777 14 7.75 14L12.25 14z"></path></svg>
                                                                    <span class="d-none d-xxl-inline-block">Revize Ekle</span>
                                                                </button>
                                                                <button
                                                                   
                                                                    class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1" @click="is_onayla(is.id)" v-if="is.durum!='tamamlandi'" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-bin undefined"><path d="M16 5L7.7051 14.2166C7.32183 14.6424 6.65982 14.6598 6.2547 14.2547L3 11"></path></svg>
                                                                    <span class="d-none d-xxl-inline-block">İşi Onayla</span>
                                                                </button>
                                                            </div>
                                                            </div>

                                                            <div
                                                                class="col-auto d-flex align-items-center justify-content-md-end">






                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>


                                            
                                            <p class="text-small text-muted mb-1 mt-2" v-if="is.calismalar.length > 0">Çalışmalar</p>
                                            <div class="row mb-2">

                                                <div class="col-sm-6 col-lg-3 col-xxl-3 "
                                                    v-for="(file, index) in is.calismalar" :key="index">
                                                    <div class="card h-100">
                                                        <img :src="$globalVariable.uploadUrl + file.dosya"
                                                            @error="$globalVariable.varsayilanGorsel"
                                                            class="card-img-top sh-22">
                                                        <div class="card-body pb-0">
                                                            <a :href="$globalVariable.uploadUrl + file.dosya"
                                                                class="h7 heading ">{{ file.ad }}
                                                            </a>
                                                        </div>
                                                        <div class="card-footer border-0 pt-0">
                                                            <div class="d-flex align-items-center ">
                                                                <a :href="$globalVariable.uploadUrl + file.dosya"
                                                                    target="_blank"
                                                                    class="btn btn-sm btn-icon btn-icon-start btn-outline-primary ms-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15"
                                                                        height="15" viewBox="0 0 20 20" fill="none"
                                                                        stroke="currentColor" stroke-width="1.5"
                                                                        stroke-linecap="round" stroke-linejoin="round"
                                                                        class="acorn-icons acorn-icons-bin undefined">
                                                                        <path
                                                                            d="M2 14V14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18H14.5C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5V14">
                                                                        </path>
                                                                        <path
                                                                            d="M14 10 10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L6 10M10 2 10 13">
                                                                        </path>
                                                                    </svg>
                                                                    <span class="d-none d-xxl-inline-block">İndir</span>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            <p class="text-small text-muted mb-1 mt-2"   v-if="is.durum!='tamamlandi' && is.revizeler.length > 0">Revizeler</p>
                                           
                                            <div  v-if="is.durum!='tamamlandi'">
                                            <div class="row g-0 mb-2"   v-for="(revize, index) in is.revizeler" :key="index">
                                                <div class="col-auto">
                                                <div class="sw-3 d-inline-block d-flex justify-content-start align-items-center h-100">
                                                <div class="sh-3">
                                                    <svg  v-if="revize.yapildi==1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-check-circle mb-3 d-inline-block text-primary"><path d="M17 5L10.6329 12.2032C10.2511 12.6351 9.58418 12.6556 9.17656 12.248L6.92857 10"></path><path d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C11.0609 2 12.0736 2.20651 13 2.58152"></path></svg>
                                                    <svg  v-if="revize.yapildi==0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-close mb-3 d-inline-block text-primary"><path d="M5 5 15 15M15 5 5 15"></path></svg>
                                                </div>
                                                </div>
                                                </div>
                                                <div class="col">
                                                <div class="card-body d-flex flex-column pt-0 pb-0 ps-3 pe-4 h-100 justify-content-center">
                                                <div class="d-flex flex-column">
                                                <div class="text-alternate mt-n1 lh-1-25">
                                                    {{ revize.revize }}
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                <div class="col-auto">
                                                <div class="d-inline-block d-flex justify-content-end align-items-center h-100">
                                                <div class="text-muted ms-2 mt-n1 lh-1-25">{{ revize.olusturma_tarih }}</div>
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                        </div>
                                    </div>


                                    <div class="row mt-5">
                                        <div class="col-12 text-center">
                                            <!-- Sayfalama -->
                                            <nav>
                                                <ul class="pagination">
                                                    <li class="page-item" :class="{ active: page === isler.seciliSayfa }"
                                                        v-for="page in pages" :key="page">
                                                        <a class="page-link" @click="islerSayfaDegistir(page)">{{
                                                            page
                                                        }}</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>


                           

                            </div>
                        </div>




                        <div class="modal fade modal-close-out show" style="display: block;"
                                        v-if="revizeModal">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title"> Yeni Revize Ekle </h5>
                                                    <button type="button" class="btn-close"
                                                        @click="revize_iptal"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <form @submit.prevent="revizeekle">

<div class="row">


  <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.revize }"> 
    <textarea id="revize" v-model="revizeFormData.revize" class="form-control" rows="5"></textarea>
    <label for="revize">Revize</label> 
  </div>	
    <span v-if="formHatalar.revize" class="error">{{ formHatalar.revize }}</span>
  

 
</div>




<div class="d-flex justify-content-around gap-2 mt-4"> 
  <button class="btn btn-outline-primary" type="button" @click="revize_iptal">İptal</button>
  <button class="btn btn-primary w-100" type="submit">Kaydet</button>
</div>
</form>

                                                </div>
                                            </div>

                                        </div>
                                    </div>








                    </div>

                </div>
            </div>

        </AppMainMusteri>
    </div>
</template>
  
<script>
import axiosCustomMusteri from "@/services/axiosCustomMusteri";
import AppMainMusteri from "@/components/AppMainMusteri.vue";





export default {
    name: "MusteriPanelView",
    components: {
        AppMainMusteri,



    },
    data() {
        return {
            musteriler: [],
            isturler: [],

            tabAktif: 'bilgiler',



            isler: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
                filtreCard: 'tumisler',
            },

            durumlar: [
                { id: 'musteriyeni', ad: 'Müşteri İsteği' },
                { id: 'ajansyeni', ad: 'Yeni' },
                { id: 'hazirlaniyor', ad: 'Hazırlanıyor' },
                { id: 'ajansonaybekliyor', ad: 'Ajans Onayı Bekliyor' },
                { id: 'musterionaybekliyor', ad: 'Müşteri Onayı Bekliyor' },
                { id: 'revizeli', ad: 'Revizeli' },
                { id: 'tamamlandi', ad: 'Tamamlandi' },
                { id: 'iptal', ad: 'İptal Edildi' },
            ],


            revizeFormData: {}, 
            formHatalar:{},
            revizeModal:false,



        };
    },
    mounted() {
        this.musteriGetir();
        this.isturlerGetir();
    },
    computed: {
        filteredisler() {
            return this.isler.tumu.filter((is) => {


                const digerFiltreler =
                    (is.uruntur && is.uruntur.toLowerCase().includes(this.isler.arananKelime && this.isler.arananKelime.toLowerCase())) ||
                    (is.aciklama && is.aciklama.toLowerCase().includes(this.isler.arananKelime && this.isler.arananKelime.toLowerCase()));


                return digerFiltreler;
            });
        },
        paginatedisler() {
            const start = (this.isler.seciliSayfa - 1) * this.isler.sayfadaKayit;
            const end = start + this.isler.sayfadaKayit;
            return this.filteredisler.slice(start, end);
        },
        isler_toplamSayfa() {
            const totalFilteredUsers = this.filteredisler.length;
            const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.isler.sayfadaKayit), 1);
            if (this.isler.seciliSayfa > maxPage) {
                this.islerSayfaDegistir(maxPage);
            }
            return maxPage;
        },
        pages() {
            return Array.from({ length: this.isler_toplamSayfa }, (_, index) => index + 1);
        },
    },
    methods: {

        tabDegistir(tab) {
            this.tabAktif = tab;
        },

        musteriGetir() {
            axiosCustomMusteri
                .get("musteripanel/bilgiler")
                .then((response) => {
                    this.musteriler = response.data;
                    this.isler.tumu = response.data.isler;

                    this.revizeModal=false;
                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },

        islerSayfaDegistir(page) {
            this.isler.seciliSayfa = page;

        },

        async isturlerGetir() {
            try {
                const response = await axiosCustomMusteri.get("musteripanel/isturler");
                this.isturler = response.data;
            } catch (error) {
                console.error("istürler alma hatası: ", error);
            }
        },
        isturBilgileri(id) {
            return this.isturler.find((musteri) => musteri.id === id) || {};
        },
        getDurumAdi(durumId) {
            const durum = this.durumlar.find(item => item.id === durumId);
            return durum ? durum.ad : 'Bilinmeyen Durum';
        },
        revize_ekle(isid) {
            this.revizeModal=true;
            this.revizeFormData.is=isid;
        },
        revize_iptal() {
            this.revizeModal=false;
        },
        async  revizeekle() {
            if(this.formDogrula()){
                // FormData oluştur
                const newFormData = new FormData();
                newFormData.append("revize", this.revizeFormData.revize);
                
                
                newFormData.append("is", this.revizeFormData.is);


                try {
                    axiosCustomMusteri
                    .post("musteripanel/revizeekle", newFormData)
                    .then(() => {
                        

                        this.$toast.success("Yeni Revize Eklendi");
                        this.musteriGetir();
                    })
                    .catch((error) => {
                        console.error("Ekleme hatası: ", error);
                        this.$toast.error("Ekleme hatası");
                    });
                } catch (error) {
                    console.error('Kaydetme hatası: ', error);
                    this.$toast.error('Kaydetme hatası');
                }
                } 
        },
        formDogrula() {
                this.formHatalar = {};
                var kontrol=true;
                
                if (!this.revizeFormData.revize) {
                this.formHatalar.revize = "Revize boş bırakılamaz.";
                kontrol=false;
                }
                
                return kontrol;
        },

        async is_onayla(isid) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "İş Onaylansın mı?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, Onayla!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustomMusteri.post("musteripanel/isonayla/" + isid);
          this.$toast.success("İş Onaylandı");
          this.musteriGetir();

        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },

    }
};
</script>
  

  