<template>
    <div class="yapilacaklar_detay">
        <AppMain>
            <div class="container">
                <div class="page-title-container">
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="mb-0 pb-0 display-4" id="title">Yapılacak Detay</h1>
                            <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                                <ul class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Anasayfa</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/yapilacaklar">Yapılacaklar</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Yapılacak Detay</router-link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">
  
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xl-12 ">
                        <div class="card h-100">
  
  <div class="card-body row g-0">
    <div class="col-auto">
      <div class="d-inline-block d-flex">
        <div class="bg-gradient-light sw-5 sh-5 rounded-md">
          <div
            class="text-white d-flex justify-content-center align-items-center h-100 text-small text-center lh-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
              class="acorn-icons acorn-icons-notebook-1 undefined">
              <path
                d="M3 5.5C3 4.09554 3 3.39331 3.33706 2.88886C3.48298 2.67048 3.67048 2.48298 3.88886 2.33706C4.39331 2 5.09554 2 6.5 2H13.5C14.9045 2 15.6067 2 16.1111 2.33706C16.3295 2.48298 16.517 2.67048 16.6629 2.88886C17 3.39331 17 4.09554 17 5.5V14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18H6.5C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5V5.5Z">
              </path>
              <path d="M8 6H12M8 10H12M8 14H12M2 8H4M2 12H4"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card-body d-flex flex-column pe-0 pt-0 pb-0 h-100 justify-content-start">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column justify-content-center">
            <router-link v-if="yetki_kontrol('yapilacaklar_goster')" :to="'yapilacaklar/' + yapilacaklar.id"
              class="heading ">{{ yapilacaklar.ad }}</router-link>
          </div>
          <div class="text-alternate">{{ yapilacaklar.tarih }} </div>
          <div class="text-alternate" v-if="yapilacaklar.musteriler && yapilacaklar.musteriler.length>0">
            <template v-for="mus in yapilacaklar.musteriler">
              {{ musteriBilgileri(mus).unvan }}   
           </template>
          </div>
          <div class="text-muted mt-1">{{ yapilacaklar.aciklama }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-muted" v-if="yapilacaklar.kullanicilar && yapilacaklar.kullanicilar.length > 0">
    <div class="row g-0 sh-6" v-for="kul in yapilacaklar.kullanicilar" :key="kul">
      <div class="col-auto" >
        <div class="row g-0 align-items-start align-content-start align-content-md-center align-items-md-center sh-5" >
          <div class="col-auto d-flex align-items-center">
            <div class="sw-4">
              <div class="sw-4 mb-1 d-inline-block">
                <img :src="$globalVariable.uploadUrl + kullaniciBilgileri(kul).profil"
                       @error="$globalVariable.varsayilanGorsel"
                  class="img-fluid rounded-xl border border-2 border-foreground sh-4" >
              </div>
            </div>
          </div>
          <div class="col">
            <div>{{ kullaniciBilgileri(kul).ad }}
              {{ kullaniciBilgileri(kul).soyad }}</div>
          
          </div>
        </div>
      </div>
    </div>
  
  
  </div>
  
  </div>
                    </div>
                  
                </div>
  
            </div>
        </AppMain>
    </div>
  </template>
  
  <script>
  import axiosCustom from "@/services/axiosCustom";
  import { yetkiKontrol } from '@/services/yetkiKontrol';
  import AppMain from "@/components/AppMain.vue";
  export default {
    name: "ProfilView",
    components: {
        AppMain,
  
    },
    data() {
        return {
            yapilacaklar: [],
            kullanicilar: [],
            musteriler: [],
        };
    },
  
    mounted() {
        this.yapilacaklarDetayGetir();
        this.kullanicilarGetir(); 
        this.musterilerGetir();
    },
    methods: {
        yetki_kontrol(yetki) {
            return yetkiKontrol(yetki);
        },
        yapilacaklarDetayGetir() {
               const id = this.$route.params.id;
               
            axiosCustom
                .get(`yapilacaklar/detay/${id}`)
                .then((response) => {
                    this.yapilacaklar = response.data;
                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },
        async kullanicilarGetir() {
        try {
          const response = await axiosCustom.get("kullanicilar");
          this.kullanicilar = response.data;
        } catch (error) {
          console.error("kullanicilar alma hatası: ", error);
        }
      },
      kullaniciBilgileri(id) {
        return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
      },
  
  
      async musterilerGetir() {
        try {
          const response = await axiosCustom.get("musteriler");
          this.musteriler = response.data;
        } catch (error) {
          console.error("musteriler alma hatası: ", error);
        }
      },
      musteriBilgileri(id) {
        return this.musteriler.find((musteri) => musteri.id === id) || {};
      },
  
  
    },
  };
  </script>
  