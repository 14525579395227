<!-- eslint-disable vue/no-mutating-props -->
<template>
  
  <h5 class="card-title" v-if="formIslem === 'duzenle'">İş Tür Düzenleme</h5>
  <h5 class="card-title" v-if="formIslem === 'ekle'">Yeni İş Tür Ekle</h5>
                 
  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <label for="ad">Ad</label> 
      </div>	
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ortalamasaat }"> 
        <input id="ortalamasaat" v-model="localFormData.ortalamasaat" class="form-control"/>
        <label for="ortalamasaat">Ortalama Kaç Saat de Yapılır</label> 
      </div>	
        <span v-if="formHatalar.ortalamasaat" class="error">{{ formHatalar.ortalamasaat }}</span>
      
    
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.aciklama }"> 
        <textarea id="aciklama" v-model="localFormData.aciklama" class="form-control" rows="5"></textarea>
        <label for="aciklama">Açıklama</label> 
      </div>	
        <span v-if="formHatalar.aciklama" class="error">{{ formHatalar.aciklama }}</span>
    
    
    
    
    
    
    
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";








export default {
components: {
  
  
  
  
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
   

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "Ad boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.ortalamasaat) {
      this.formHatalar.ortalamasaat = "Ortalama Kaç Saat de Yapılır boş bırakılamaz.";
      kontrol=false;
    }
    
  

  
   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("ad", this.localFormData.ad);newFormData.append("ortalamasaat", this.localFormData.ortalamasaat);
  newFormData.append("aciklama", this.localFormData.aciklama);
  
  
   
  
  

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("isturler/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("İş Tür Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("isturler/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni İş Tür Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
},
};
</script>