<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="Musteri_detay">
        <AppMain>
            <div class="container">
                <div class="page-title-container">
                  <div class="row">
                      <div class="col-12 col-md-7">
                          <h1 class="mb-0 pb-0 display-4" id="title">Kullanıcı Detay</h1>
                          <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                              <ul class="breadcrumb pt-0">
                                  <li class="breadcrumb-item">
                                      <router-link to="/">Anasayfa</router-link>
                                  </li>
                                  <li class="breadcrumb-item">
                                      <router-link to="/kullanicilar">Kullanıcılar</router-link>
                                  </li>
                                  <li class="breadcrumb-item">
                                      <router-link to="/">Kullanıcı Detay</router-link>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                      <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">

                        <div class="col-12 col-md-5 d-flex align-items-start justify-content-end gap-2">
                            
                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="isler_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>İş Ekle</span>
                            </button>
                            
                        
                            
                            <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                                @click="yapilacaklar_ekle">
                                <i data-acorn-icon="pencil"></i>
                                <span>Yapılacak Ekle</span>
                            </button>
                            
                     
                            
                        </div>
                      </div>
                  </div>
              </div>
        

                <div class="row">

                    <div class="col-12 col-xl-4 col-xxl-3">

                        <h2 class="small-title">Kullanıcı</h2>
                        <div class="card  mb-4">
                            <div class="card-body">
                               
                                <div class="d-flex align-items-center flex-column mb-4">
                                  <div class="d-flex align-items-center flex-column">
                                      <div class="sw-13 position-relative mb-3">
                                          <img :src="$globalVariable.uploadUrl + kullanicilar.profil"
                                              @error="$globalVariable.varsayilanGorsel" class="img-fluid rounded-xl"
                                              alt="thumb">
                                      </div>
                                      <div class="h5 mb-0">{{ kullanicilar.ad }} {{ kullanicilar.soyad }}</div>
                                      <div class="text-muted">{{ rolGetir(kullanicilar.rol).ad }}</div>

                                  </div>
                              </div>


                                <div class="mb-5">
                                    <p class="text-small text-muted mb-2">Bilgiler</p>
                                    
                                 
    
                                    
                                    <div class="row g-0 mb-2">
                                      
                                        <div class="text-alternate"> 
                                            Kullanıcı Adı :  {{ kullanicilar.kullaniciadi }}</div>
                                    </div>
                                    
                                    <div class="row g-0 mb-2">
                                      
                                        <div class=" text-alternate">  Telefon : {{ kullanicilar.telefon }}</div>
                                    </div>
                                    
                                    

                                    
                               
                                </div>


                            
                            </div>
                        </div>
                        <div class="card ">
                            <div class="card-body">

                                <div class="nav flex-column" role="tablist">
                                    
                                    <a class="nav-link px-0  border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'isler' }"
                                        @click="aktifSekmeDegistir('isler')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"
                                            fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" class="acorn-icons acorn-icons-book me-2">
                                            <path
                                                d="M6.38964 11.7402C6.22662 11.8489 6.14511 11.9033 6.0914 11.9794 6.06777 12.0129 6.04835 12.0492 6.03357 12.0875 6 12.1744 6 12.2724 6 12.4683L6 17.125C6 17.4761 6 17.6517 6.08427 17.7778 6.12075 17.8324 6.16762 17.8793 6.22222 17.9157 6.34833 18 6.52389 18 6.875 18L11.125 18C11.4761 18 11.6517 18 11.7778 17.9157 11.8324 17.8793 11.8793 17.8324 11.9157 17.7778 12 17.6517 12 17.4761 12 17.125L12 8.81748C12 8.51251 12 8.36002 11.9225 8.28613 11.8897 8.25492 11.8491 8.23318 11.8049 8.22324 11.7004 8.1997 11.5736 8.28429 11.3198 8.45345L6.38964 11.7402zM5.51631 3.75816C5.7173 3.85865 5.8178 3.9089 5.885 3.98999 5.91448 4.02557 5.93888 4.06506 5.95752 4.10734 6 4.2037 6 4.31606 6 4.54078L6 17.125C6 17.4761 6 17.6517 5.91573 17.7778 5.87925 17.8324 5.83238 17.8793 5.77778 17.9157 5.65167 18 5.47611 18 5.125 18L2.875 18C2.52388 18 2.34833 18 2.22222 17.9157 2.16762 17.8793 2.12074 17.8324 2.08427 17.7778 2 17.6517 2 17.4761 2 17.125L2 2.70789C2 2.43878 2 2.30423 2.0693 2.23175 2.09874 2.20095 2.13558 2.17818 2.1763 2.16562 2.27211 2.13606 2.39246 2.19623 2.63316 2.31658L5.51631 3.75816zM12.3896 11.7402C12.2266 11.8489 12.1451 11.9033 12.0914 11.9794 12.0678 12.0129 12.0483 12.0492 12.0336 12.0875 12 12.1744 12 12.2724 12 12.4683L12 17.125C12 17.4761 12 17.6517 12.0843 17.7778 12.1207 17.8324 12.1676 17.8793 12.2222 17.9157 12.3483 18 12.5239 18 12.875 18L17.125 18C17.4761 18 17.6517 18 17.7778 17.9157 17.8324 17.8793 17.8793 17.8324 17.9157 17.7778 18 17.6517 18 17.4761 18 17.125L18 8.81748C18 8.51251 18 8.36002 17.9225 8.28614 17.8897 8.25492 17.8491 8.23318 17.8049 8.22324 17.7004 8.1997 17.5736 8.28429 17.3198 8.45345L12.3896 11.7402z">
                                            </path>
                                        </svg>
                                        <span class="align-middle">İşler</span>
                                    </a>
                                    
                              
                                    
                                    <a class="nav-link px-0  border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'yapilacaklar' }"
                                        @click="aktifSekmeDegistir('yapilacaklar')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-book me-2"><path d="M3 5.5C3 4.09554 3 3.39331 3.33706 2.88886C3.48298 2.67048 3.67048 2.48298 3.88886 2.33706C4.39331 2 5.09554 2 6.5 2H13.5C14.9045 2 15.6067 2 16.1111 2.33706C16.3295 2.48298 16.517 2.67048 16.6629 2.88886C17 3.39331 17 4.09554 17 5.5V14.5C17 15.9045 17 16.6067 16.6629 17.1111C16.517 17.3295 16.3295 17.517 16.1111 17.6629C15.6067 18 14.9045 18 13.5 18H6.5C5.09554 18 4.39331 18 3.88886 17.6629C3.67048 17.517 3.48298 17.3295 3.33706 17.1111C3 16.6067 3 15.9045 3 14.5V5.5Z"></path><path d="M11 2L11 9.0072C11 9.36798 11 9.54837 10.9118 9.62325C10.8749 9.65464 10.8296 9.6746 10.7815 9.6807C10.6667 9.69526 10.5336 9.57353 10.2673 9.33009L9.09042 8.2541C8.85159 8.03574 8.73217 7.92656 8.59205 7.90031C8.53121 7.88892 8.46879 7.88892 8.40795 7.90031C8.26783 7.92656 8.14841 8.03574 7.90958 8.2541L6.73271 9.33009C6.46645 9.57353 6.33332 9.69526 6.21855 9.6807C6.17044 9.6746 6.12515 9.65464 6.08818 9.62325C6 9.54837 6 9.36798 6 9.0072L6 2"></path></svg>
                            
                                        <span class="align-middle">Yapılacaklar</span>
                                    </a>
                              
                           
                                    

                                    <a class="nav-link px-0  border-bottom border-separator-light " :class="{ 'active': aktifSekme === 'hareketler' }"  @click="aktifSekmeDegistir('hareketler')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-user me-2"><path d="M10.0179 8C10.9661 8 11.4402 8 11.8802 7.76629C12.1434 7.62648 12.4736 7.32023 12.6328 7.06826C12.8989 6.64708 12.9256 6.29324 12.9789 5.58557C13.0082 5.19763 13.0071 4.81594 12.9751 4.42106C12.9175 3.70801 12.8887 3.35148 12.6289 2.93726C12.4653 2.67644 12.1305 2.36765 11.8573 2.2256C11.4235 2 10.9533 2 10.0129 2V2C9.03627 2 8.54794 2 8.1082 2.23338C7.82774 2.38223 7.49696 2.6954 7.33302 2.96731C7.07596 3.39365 7.05506 3.77571 7.01326 4.53982C6.99635 4.84898 6.99567 5.15116 7.01092 5.45586C7.04931 6.22283 7.06851 6.60631 7.33198 7.03942C7.4922 7.30281 7.8169 7.61166 8.08797 7.75851C8.53371 8 9.02845 8 10.0179 8V8Z"></path><path d="M16.5 17.5L16.583 16.6152C16.7267 15.082 16.7986 14.3154 16.2254 13.2504C16.0456 12.9164 15.5292 12.2901 15.2356 12.0499C14.2994 11.2842 13.7598 11.231 12.6805 11.1245C11.9049 11.048 11.0142 11 10 11C8.98584 11 8.09511 11.048 7.31945 11.1245C6.24021 11.231 5.70059 11.2842 4.76443 12.0499C4.47077 12.2901 3.95441 12.9164 3.77462 13.2504C3.20144 14.3154 3.27331 15.082 3.41705 16.6152L3.5 17.5"></path></svg>
                                        <span class="align-middle">Hareketler</span>
                                    </a>

                                    <a class="nav-link px-0 border-bottom border-separator-light" :class="{ 'active': aktifSekme === 'yetkiler' }"  @click="aktifSekmeDegistir('yetkiler')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-lock-off me-2"><path d="M5 12.6667C5 12.0467 5 11.7367 5.06815 11.4824C5.25308 10.7922 5.79218 10.2531 6.48236 10.0681C6.73669 10 7.04669 10 7.66667 10H12.3333C12.9533 10 13.2633 10 13.5176 10.0681C14.2078 10.2531 14.7469 10.7922 14.9319 11.4824C15 11.7367 15 12.0467 15 12.6667V13C15 13.9293 15 14.394 14.9231 14.7804C14.6075 16.3671 13.3671 17.6075 11.7804 17.9231C11.394 18 10.9293 18 10 18V18C9.07069 18 8.60603 18 8.21964 17.9231C6.63288 17.6075 5.39249 16.3671 5.07686 14.7804C5 14.394 5 13.9293 5 13V12.6667Z"></path><path d="M11 15H10 9M13 6V5C13 3.34315 11.6569 2 10 2V2C8.34315 2 7 3.34315 7 5V10"></path></svg>
                                        <span class="align-middle">Yetkiler</span>
                                    </a>
                                    

                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="col-12 col-xl-8 col-xxl-9 mb-5 tab-content">

                        
                        <div class="tab-pane active" v-show="aktifSekme == 'isler'">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">İşler</h2>
                              
                                    <isCard :isler="isler" :duzenle="isler_duzenle"   :formKaydetSonrasi="kullanicilarDetayGetir"  @update:isler="islerGuncelle"  />




                                        <div class="modal fade modal-close-out show" style="display: block;" v-if="isler.modal">
                                        <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h5 class="modal-title" v-if="isler.islem == 'ekle'"> Yeni İş Ekle </h5>
                                            <h5 class="modal-title" v-if="isler.islem == 'duzenle'">İş Düzenleme </h5>
                                            <button type="button" class="btn-close" @click="isler_iptal"></button>
                                            </div>
                                            <div class="modal-body">

                                                <isForm :formIslem="isler.islem" :formData="isler.secili"
                                                :formKaydetSonrasi="kullanicilarDetayGetir" :formIptal="isler_iptal" />


                                            </div>
                                        </div>

                                        </div>
                                    </div>
         

                                </div>
                            </div>

                        </div>
                        
                
                 
                   

                       
                    
                        <div class="tab-pane active" v-show="aktifSekme === 'yapilacaklar'">
                            <h2 class="small-title">Yapılacak</h2>

                    
                            <div class="row mb-3 g-2">
                                <div class="col mb-1">
                                    <div class="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
                                        <input class="form-control" placeholder="Arama" v-model="yapilacaklar.arananKelime">
                                        <span class="search-magnifier-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-search undefined"><circle cx="9" cy="9" r="7"></circle><path d="M14 14L17.5 17.5"></path></svg>
                                        </span>
                                        <span class="search-delete-icon d-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="acorn-icons acorn-icons-close undefined"><path d="M5 5 15 15M15 5 5 15"></path></svg>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        
                            <yapilacakCard :yapilacaklar="yapilacaklar" :duzenle="yapilacaklar_duzenle"   :formKaydetSonrasi="kullanicilarDetayGetir"  @update:yapilacaklar="yapilacaklarGuncelle" />





                                    <div class="modal fade modal-close-out show" style="display: block;" v-if="yapilacaklar.modal">
                                    <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                        <h5 class="modal-title" v-if="yapilacaklar.islem == 'ekle'"> Yeni Yapılacak Ekle </h5>
                                        <h5 class="modal-title" v-if="yapilacaklar.islem == 'duzenle'">Yapılacak Düzenleme </h5>
                                        <button type="button" class="btn-close" @click="yapilacaklar_iptal"></button>
                                        </div>
                                        <div class="modal-body">

                                            <yapilacakForm :formIslem="yapilacaklar.islem" :formData="yapilacaklar.secili"
                                            :formKaydetSonrasi="kullanicilarDetayGetir" :formIptal="yapilacaklar_iptal" />


                                        </div>
                                    </div>

                                    </div>
                                    </div>

                        

                        </div>
       
                        <div class="tab-pane active"  v-show="aktifSekme === 'hareketler'">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="small-title">Hareketler</h2>
                                    <div class="card mb-5">
                                            <div class="card-body">

                                                <div class="row g-0" v-for="hareket in paginatedHareketler" :key="hareket.id">
                                                    <div
                                                        class="col-auto sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                                                        <div class="w-100 d-flex sh-1"></div>
                                                        <div
                                                            class="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                                                            <div class="bg-gradient-light sw-1 sh-1 rounded-xl position-relative"></div>
                                                        </div>
                                                        <div class="w-100 d-flex h-100 justify-content-center position-relative">
                                                            <div class="line-w-1 bg-separator h-100 position-absolute"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col mb-4">
                                                        <div class="h-100">
                                                            <div class="d-flex flex-column justify-content-start">
                                                                <div class="d-flex flex-column">
                                                                    <a href="#" class="heading stretched-link">{{ hareket.aciklama }}</a>
                                                                    <div class="text-alternate">{{ hareket.tarih }}</div>
                                                                    <div class="text-muted mt-1">
                                                                        {{ hareket.tarayici }}-{{ hareket.ip }}-{{ hareket.url }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                
                                </div>
                            </div>
                            <div class="row mt-5">
                        <div class="col-12 text-center">
                            <!-- Sayfalama -->
                            <nav>
                            <ul class="pagination">
                                <li class="page-item" :class="{ active: page === hareketSeciliSayfa }" v-for="page in hareketler_pages" :key="page">
                                <a class="page-link" @click="hareketlerSayfaDegistir(page)">{{
                                    page
                                }}</a>
                                </li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                        </div>
                        <div class="tab-pane active" v-show="aktifSekme === 'yetkiler'">
                            <h2 class="small-title">Yetkiler</h2>
                            <div class="row row-cols-1 g-2">


                                <div class="col" v-for="rol in roller" :key="rol.id">
                                    <div class="card">
                                        <div class="card-body">
                                            <label class="form-check custom-icon mb-0 checked-opacity-100">
                                                <input type="checkbox"   @click="rolDegistir(rol.id)" class="form-check-input" :checked="rol.id === kullanicilar.rol">
                                                <span class="form-check-label">
                                                    <span class="content opacity-50">
                                                        <span class="heading mb-1 lh-1-25">{{ rol.ad }}</span>
                                                        <span class="d-block text-small text-muted" >
                                                            {{ rol.aciklama }}

                                                            <template v-for="yetki in yetkiler" :key="yetki.key">
                                                            <div class="mb-1" v-if="rol.yetkiler.includes(yetki.key)"><b>{{yetki.ad}}</b> -
                                                                {{
                                                                    (rol.yetkiler.includes(yetki.key + '_goster') ? ' Görüntüleyebilir' : '') +
                                                                    (rol.yetkiler.includes(yetki.key + '_ekle') ? ' Ekleyebilir' : '') +
                                                                    (rol.yetkiler.includes(yetki.key + '_duzenle') ? ' Düzenleyebilir' : '') +
                                                                    (rol.yetkiler.includes(yetki.key + '_sil') ? ' Silebilir' : '')
                                                                    }}
                                                            </div>
                                                        </template>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            
                
                            </div>
                        </div>

                        
                        </div>

                    </div>
                </div>

            </AppMain>
        </div>
</template>
  
<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';
import AppMain from "@/components/AppMain.vue";

    
import isCard from "@/components/isler/isCard.vue";
import isForm from "@/components/isler/isForm.vue";
    

    
import yapilacakCard from "@/components/yapilacaklar/yapilacakCard.vue";
import yapilacakForm from "@/components/yapilacaklar/yapilacakForm.vue";
    



export default {
    name: "kullanicilarDetayView",
    components: {
        AppMain,
            
        isCard, 
        isForm,
            
       
            
        yapilacakCard, 
        yapilacakForm,
       
        
    },
    data() {
        return {
            kullanicilar: [],
           
            aktifSekme: 'isler',
            hareketSayfadaKayit: 10,
            hareketSeciliSayfa: 1,

            
            isler: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
                filtreCard:'tumisler',
            },
            
         
            
            yapilacaklar: {
                tumu: [],
                secili: null,
                seciliSayfa: 1,
                sayfadaKayit: 5,
                arananKelime: "",
                islem: 'ekle',
                modal: false,
            },
            
           
            
           
            roller:[],
          
        yetkiler: [
          { key: 'bildirim', ad: 'Bildirim' },
          { key: 'isfikir', ad: 'İş fikir' },
          { key: 'isadim', ad: 'İş Adım' },
          { key: 'isdosya', ad: 'İş Dosya' },
          { key: 'isdurum', ad: 'İş Durum' },
          { key: 'is', ad: 'İş' },
          { key: 'istur', ad: 'İş Türleri' },
          { key: 'kullanici', ad: 'Kullanici' },
          { key: 'musteri', ad: 'Müşteri' },
          { key: 'musterinot', ad: 'Müşteri Notu' },
          { key: 'revize', ad: 'Revize' },
          { key: 'rol', ad: 'Rol' },
          { key: 'yapilacak', ad: 'Yapılacak' },
        ],


        };
    },
    mounted() {
        this.ilkTabTanimla();
        this.kullanicilarDetayGetir();
        this.rollerGetir();
    },
    computed: {

            paginatedHareketler() {
                if (!this.kullanicilar || !this.kullanicilar.hareketler) {
                    return [];
                }
            const start = (this.hareketSeciliSayfa - 1) * this.hareketSayfadaKayit;
            const end = start + this.hareketSayfadaKayit;
            var hareketler=this.kullanicilar.hareketler;
            return hareketler.slice(start, end);
            },
            hareketler_toplamSayfa() {
                if (!this.kullanicilar || !this.kullanicilar.hareketler) {
                    return [];
                }
            const toplamHareket = this.kullanicilar.hareketler.length;
            const maxPage = Math.max(Math.ceil(toplamHareket / this.hareketSayfadaKayit), 1);
            if (this.seciliSayfa > maxPage) {
                this.kullanicilarSayfaDegistir(maxPage);
            }
            return maxPage;
            },

            hareketler_pages() {
                const totalPageCount = this.hareketler_toplamSayfa;
                const maxVisiblePages = 5;
                const currentPage = this.hareketSeciliSayfa;

                if (totalPageCount <= maxVisiblePages) {
                    return Array.from({ length: totalPageCount }, (_, index) => index + 1);
                }

                const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
                const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

                return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
            },
            },
    methods: {
        yetki_kontrol(yetki) {
            return yetkiKontrol(yetki);
        },
        ilkTabTanimla() {
            const tab = this.$route.params.tab;
                    if(tab=='bilgiler'){
                        this.aktifSekme = 'isler';
                        this.aktifSekmeDegistir("isler");
                    }else{
                        this.aktifSekme = tab;
                    }
                    
        },
        async kullanicilarDetayGetir() {
            const id = this.$route.params.id;

            axiosCustom
                .get(`kullanicilar/detay/${id}`)
                .then((response) => {
                    this.kullanicilar = response.data;
           
                
                    this.isler.tumu = response.data.isler;
                    this.isler.modal = false;
                    
                 
                    
                    this.yapilacaklar.tumu = response.data.yapilacaklar;
                    this.yapilacaklar.modal = false;
                    
                  
                    

                    

                    
                })
                .catch((error) => {
                    console.error("Veri çekme hatası: ", error);
                });
        },

        hareketlerSayfaDegistir(page) {
            this.hareketSeciliSayfa= page
        },
        aktifSekmeDegistir(sekme) {
            this.aktifSekme = sekme;
            const id = this.$route.params.id;
            this.$router.push('/kullanicilar/'+id+'/'+sekme);
        },




        
        islerGuncelle(veriler) {
            this.isler = veriler;
        },
        isler_ekle() {
            this.isler.secili = {'kullanicilar':this.kullanicilar.id};
            this.isler.islem = "ekle";
            this.isler.modal = true;
            this.aktifSekmeDegistir("isler");
        },
        isler_duzenle(veri) {
            this.isler.secili = veri;
            this.isler.islem = "duzenle";
            this.isler.modal = true;
        },
        isler_iptal() {
            this.isler.secili = {};
            this.isler.modal = false;
        },
     
        
        yapilacaklarGuncelle(veriler) {
            this.yapilacaklar = veriler;
        },
        yapilacaklar_ekle() {
            this.yapilacaklar.secili = {'kullanicilar':this.kullanicilar.id};
            this.yapilacaklar.islem = "ekle";
            this.yapilacaklar.modal = true;
            this.aktifSekmeDegistir("yapilacaklar");
        },
        yapilacaklar_duzenle(veri) {
            this.yapilacaklar.secili = veri;
            this.yapilacaklar.islem = "duzenle";
            this.yapilacaklar.modal = true;
        },
        yapilacaklar_iptal() {
            this.yapilacaklar.secili = {};
            this.yapilacaklar.modal = false;
        },
        
      

        rollerGetir() {
            axiosCustom
                .get("roller")
                .then((response) => {
                this.roller = response.data;
                })
                .catch((error) => {
                console.error("Veri çekme hatası: ", error);
                });
            },

        async  rolDegistir(rolid) {
            if(this.yetki_kontrol('kullanici_duzenle')){
            const newFormData = new FormData();
            
            newFormData.append("rol", rolid);
            
            try {
                axiosCustom
                .post("kullanicilar/roldegistir/" + this.kullanicilar.id, newFormData)
                .then(() => {
                    this.$toast.success("Yetki Degiştirildi.");
                    this.kullanicilarDetayGetir();
                }).catch((error) => {
                    console.error("Güncelleme hatası: ", error);
                    this.$toast.error("Güncelleme hatası");
                });
            
            } catch (error) {
                console.error('Kaydetme hatası: ', error);
                this.$toast.error('Kaydetme hatası');
            }
            }
        },
        rolGetir(id) {
            return this.roller.find((rol) => rol.id === id) || {};
        },
    


    }
};
</script>
  

  