<template>

<div class="col">
            <div class="row">
         
         
                <!-- isfikir Kart -->
                <div class="col-md-3 mt-4"  v-for="isfikir in paginatedfikirler" :key="isfikir.id" >
                <div class="card h-100">

                  <span v-if="isfikir.yapildi!=1" class="badge rounded-pill bg-primary me-1 position-absolute s-n2 t-2 z-index-1 t-1">Yapılmadı</span>
                  <span v-if="isfikir.yapildi==1" class="badge rounded-pill bg-background me-1 position-absolute s-n2 t-2 z-index-1 t-1">Yapıldı</span>

                  <router-link v-if="yetki_kontrol('isfikir_goster')"  to="/fikirler">
                    <img :src="$globalVariable.uploadUrl+isfikir.gorsel" @error="$globalVariable.varsayilanGorsel" class="card-img-top sh-30"  />
                  </router-link>
                  <div class="card-body">
                    <a v-if="yetki_kontrol('isfikir_goster')" href="#" >{{ isfikir.baslik }} </a>
                    <div class="text-muted mb-1"></div>

                    <div class="row g-0 align-items-center mb-2">
                    
                      <div class="col">
                        <div class="d-flex align-items-center lh-1-25">
                          {{ isfikir.aciklama }}
                        </div>
                      </div>
                    </div>
                    
                    <div class="row g-0 align-items-center mb-2">
                      <router-link
                      v-if="isfikir.link"
                      :to="isfikir.link"
                      target="_blank"
                    >
                      Fikir Sayfası
                    </router-link>
                    
                  </div>
                   

                    <div class="mt-4 text-center">
                      <button v-if="yetki_kontrol('isfikir_duzenle')" @click="duzenle(isfikir)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                      </button>
                      <button  v-if="yetki_kontrol('isfikir_sil')"  @click="sil(isfikir.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                        <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                <!-- isfikir Kart -->
              
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === fikirler.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="fikirlerSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    fikirler:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  computed: {
    filteredfikirler() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.fikirler.tumu.filter(
        (isfikir) =>

          isfikir.baslik.toLowerCase() 
          
          .includes(this.fikirler.arananKelime.toLowerCase()) ||isfikir.baslik.toLowerCase()
          
          .includes(this.fikirler.arananKelime.toLowerCase()) ||isfikir.link.toLowerCase()
          
          
          .includes(this.fikirler.arananKelime.toLowerCase()) ||isfikir.aciklama.toLowerCase()
          
          .includes(this.fikirler.arananKelime.toLowerCase())


    
      );
    },
    paginatedfikirler() {
      const start = (this.fikirler.seciliSayfa - 1) * this.fikirler.sayfadaKayit;
      const end = start + this.fikirler.sayfadaKayit;
      return this.filteredfikirler.slice(start, end);
    },
    fikirler_toplamSayfa() {
      const totalFilteredUsers = this.filteredfikirler.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.fikirler.sayfadaKayit), 1);
      if (this.fikirler.seciliSayfa > maxPage) {
        this.fikirlerSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.fikirler_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.fikirler.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(isfikirId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("fikirler/sil/" + isfikirId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    fikirlerSayfaDegistir(page) {
      this.$emit('update:fikirler',{
      ...this.fikirler,
      seciliSayfa: page
    });
    },
    fikirlerSayfadaKayitDegistir(value) {
      this.$emit('update:fikirler',{
      ...this.fikirler,
      sayfadaKayit: value
    });
      this.fikirlerSayfaDegistir(1);
    },
  },
};
</script>
