<!-- eslint-disable vue/no-mutating-props -->
<template>
  
         
  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <label for="ad">Ad</label> 
      </div>	
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
      
    
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.not }"> 
        <textarea id="not" v-model="localFormData.not" class="form-control" rows="5"></textarea>
        <label for="not">Not</label> 
      </div>	
        <span v-if="formHatalar.not" class="error">{{ formHatalar.not }}</span>
    
    
    
    
      <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.musteri }"> 
        <v-select
          v-model="localFormData.musteri"
          :options="musteriler"
          label="unvan"
          :reduce="musteri => musteri.id"
          placeholder="Müşteri Seçin"
        />
      </div>	
        <span v-if="formHatalar.musteri" class="error">{{ formHatalar.musteri }}</span>
    
 
    
    
    
    
    
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";

import vSelect from "vue-select";








export default {
components: {
  
      vSelect,
  
  
  
  
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  musteriler: [],
  


  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  this.musterilerGetir();
  
 
  
   

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };


          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  async musterilerGetir() {
    try {
      const response = await axiosCustom.get("musteriler"); 
      this.musteriler = response.data;
    } catch (error) {
      console.error("musteriler alma hatası: ", error);
    }
  },

  
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "Ad boş bırakılamaz.";
      kontrol=false;
    }
    
  
  if (!this.localFormData.not) {
      this.formHatalar.not = "Not boş bırakılamaz.";
      kontrol=false;
    }
  
  
  
  if (!this.localFormData.musteri) {
      this.formHatalar.musteri = "Müşteri boş bırakılamaz.";
      kontrol=false;
    }

   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("ad", this.localFormData.ad);
  newFormData.append("not", this.localFormData.not);
  
  newFormData.append("musteri", this.localFormData.musteri);

   
  
  

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("musterinotlar/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("Müşteri Not Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("musterinotlar/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni Müşteri Not Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
},
};
</script>