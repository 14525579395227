<!-- eslint-disable vue/no-mutating-props -->
<template>
  
  <h5 class="card-title" v-if="formIslem === 'duzenle'">Kullanıcı Düzenleme</h5>
  <h5 class="card-title" v-if="formIslem === 'ekle'">Yeni Kullanıcı Ekle</h5>
                 
  <form @submit.prevent="gonder">

    <div class="row">
   
    
  
      <div class="col-12 mb-2" :class="{ 'text-danger': formHatalar.profil }"> 
       
       <picture-input 
         ref="profil"
         height="200" 
         margin="0" 
         accept="image/jpeg,image/png" 
         size="10" 
         button-class="btn"
         :custom-strings="{
           upload: '<h1>Profil</h1>',
           drag: 'Profil Resmi Degiştirmek için tıklayınız'
         }"
         @change="profilDegistir">
       </picture-input>
     </div>	
       <span v-if="formHatalar.profil" class="error">{{ formHatalar.profil }}</span>


      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <label for="ad">Ad</label> 
      </div>	
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.soyad }"> 
        <input id="soyad" v-model="localFormData.soyad" class="form-control"/>
        <label for="soyad">Soyad</label> 
      </div>	
        <span v-if="formHatalar.soyad" class="error">{{ formHatalar.soyad }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.kullaniciadi }"> 
        <input id="kullaniciadi" v-model="localFormData.kullaniciadi" class="form-control"/>
        <label for="kullaniciadi">Kullanıcı Adı</label> 
      </div>	
        <span v-if="formHatalar.kullaniciadi" class="error">{{ formHatalar.kullaniciadi }}</span>

        <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.sifre }"> 
        <input id="sifre" v-model="localFormData.sifre" class="form-control" type="password"/>
        <label for="sifre">Şifre</label> 
      </div>	
        <span v-if="formHatalar.sifre" class="error">{{ formHatalar.sifre }}</span>

        <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.sifre2 }"> 
          <input id="sifre2" v-model="localFormData.sifre2" class="form-control" type="password"/>
          <label for="sifre2">Şifre Tekrarı</label> 
        </div>	
        <span v-if="formHatalar.sifre2" class="error">{{ formHatalar.sifre2 }}</span>
      
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.telefon }"> 
        <input id="telefon" v-model="localFormData.telefon" class="form-control"/>
        <label for="telefon">Telefon</label> 
      </div>	
        <span v-if="formHatalar.telefon" class="error">{{ formHatalar.telefon }}</span>
      
    
    
    
    
      <div class=" col-12 mb-1" :class="{ 'text-danger': formHatalar.rol }"> 
        <v-select
          v-model="localFormData.rol"
          :options="roller"
          label="ad"
          :reduce="rol => rol.id"
          placeholder="Rol Seçin"
        />
      </div>	
        <span v-if="formHatalar.rol" class="error">{{ formHatalar.rol }}</span>
    
    
    
  
   
    
    
    

     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";

import vSelect from "vue-select";




import PictureInput from 'vue-picture-input'





export default {
components: {
  
      vSelect,
  
  
  
    PictureInput,
  
  
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    profilSecili: null,
    
    
  roller: [],
  
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  this.rollerGetir();
  
   

  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };
          this.localFormData.sifre='';
  
          
          
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {
  
  async rollerGetir() {
    try {
      const response = await axiosCustom.get("roller"); 
      this.roller = response.data;
    } catch (error) {
      console.error("roller alma hatası: ", error);
    }
  },
  
  



  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "Ad boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.soyad) {
      this.formHatalar.soyad = "Soyad boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.kullaniciadi) {
      this.formHatalar.kullaniciadi = "Kullanıcı Adı boş bırakılamaz.";
      kontrol=false;
    }
    
    if (!this.localFormData.telefon) {
      this.formHatalar.telefon = "Telefon boş bırakılamaz.";
      kontrol=false;
    }
    
  
  
  
  if (!this.localFormData.rol) {
      this.formHatalar.rol = "Rol boş bırakılamaz.";
      kontrol=false;
    }
  
   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  newFormData.append("ad", this.localFormData.ad);newFormData.append("soyad", this.localFormData.soyad);newFormData.append("kullaniciadi", this.localFormData.kullaniciadi);newFormData.append("telefon", this.localFormData.telefon);
  
  
  newFormData.append("sifre", this.localFormData.sifre);
  newFormData.append("sifre2", this.localFormData.sifre2);

  newFormData.append("rol", this.localFormData.rol);
   
  newFormData.append("profil",  this.profilSecili);
  

  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("kullanicilar/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("Kullanıcı Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("kullanicilar/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni Kullanıcı Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
  profilDegistir(event) {
        // Dosya seçildiğinde bu metod çağrılır
        this.profilSecili = event.target.files[0];
        if (this.profilSecili) {
            const reader = new FileReader();
            reader.readAsDataURL(this.profilSecili);
        }
    },
    
},
};
</script>