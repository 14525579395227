<template>
 
        <h2 class="small-title">Kullanıcı Durumları</h2>
        <div class="card h-100-card">
          <div class="card-body mb-n2 border-last-none h-100">

            <div class="border-bottom border-separator-light mb-2 pb-2"  v-for="kullanici in kullanicilar" :key="kullanici.id">
              <div class="row g-0 sh-6">
                <div class="col-auto">
                  <img :src="$globalVariable.uploadUrl+kullanici.profil" @error="$globalVariable.varsayilanGorsel" class="card-img rounded-xl sh-6 sw-6" alt="thumb">
                </div>
                <div class="col">
                  <div
                    class="card-body d-flex flex-row pt-0 pb-0 ps-3 pe-0 h-100 align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <div>{{ kullanici.ad }}  {{ kullanici.soyad }}</div>
                      <div class="text-small text-muted">{{kullanici.rol_ad}}</div>
                    </div>
                    <div class="col-3 text-center">
                      <div class="text-small text-muted mb-1">İş Adeti</div>
                      <div class="cta-2 text-primary">{{kullanici.is_adet}}</div>
                      </div>
                      <div class="col-3 text-center">
                      <div class="text-small text-muted mb-1">Ortalama Saat</div>
                      <div class="cta-2 text-primary">{{kullanici.is_saat}}</div>
                      </div>
                    <div class="d-flex">
                      
                       <router-link class="btn btn-sm btn-icon btn-icon-only btn-outline-secondary ms-1" v-if="yetki_kontrol('kullanici_goster')"  :to="'kullanicilar/' + kullanici.id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                          class="acorn-icons acorn-icons-more-vertical undefined">
                          <path
                            d="M10 9C10.5523 9 11 9.44772 11 10V10C11 10.5523 10.5523 11 10 11V11C9.44772 11 9 10.5523 9 10V10C9 9.44772 9.44772 9 10 9V9zM10 2C10.5523 2 11 2.44772 11 3V3C11 3.55228 10.5523 4 10 4V4C9.44772 4 9 3.55228 9 3V3C9 2.44772 9.44772 2 10 2V2zM10 16C10.5523 16 11 16.4477 11 17V17C11 17.5523 10.5523 18 10 18V18C9.44772 18 9 17.5523 9 17V17C9 16.4477 9.44772 16 10 16V16z">
                          </path>
                        </svg>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

         
          </div>
        </div>
     
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  name: "KullanicilarDurum",
  data() {
    return {
      kullanicilar: [],
    }
  },
  mounted() {
    this.kullanicilarGetir();
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },

    kullanicilarGetir() {
      axiosCustom
        .get("anasayfa/kullanicidurumlar")
        .then((response) => {
          this.kullanicilar = response.data;
        })
        .catch((error) => {
          console.error("Veri çekme hatası: ", error);
        });
    },





  },
};
</script>
