<!-- eslint-disable vue/no-mutating-props -->
<template>
  

                 
  <form @submit.prevent="gonder">

    <div class="row">
   
    
    
      <div class="top-label col-12 mb-1" :class="{ 'text-danger': formHatalar.durum }"> 
        <textarea id="durum" v-model="localFormData.durum" class="form-control" rows="5"></textarea>
        <label for="durum">Durum</label> 
      </div>	
        <span v-if="formHatalar.durum" class="error">{{ formHatalar.durum }}</span>
    
     
    </div>

    


    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";





export default {
components: {

  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    
  
  

  
    localFormData: { ...this.formData }, 
    formHatalar:{},

    

  };
},
created() {
  
  
  
},
watch: {
      formData: {
        handler(newFormData) {
          this.localFormData = { ...newFormData };

  
          
          
          
         
          
        },
        immediate: true, // Component oluşturulduğunda da tetikle
      },
    },
methods: {

  
  

  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
  
  if (!this.localFormData.durum) {
      this.formHatalar.durum = "Durum boş bırakılamaz.";
      kontrol=false;
    }
  

   
 


    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
  // FormData oluştur
  const newFormData = new FormData();
  
  newFormData.append("durum", this.localFormData.durum);
  
  newFormData.append("is", this.localFormData.is);


  try {
  if (this.formIslem === "duzenle") {
    axiosCustom
      .post("isdurumlar/duzenle/" + this.localFormData.id, newFormData)
      .then(() => {
        

        this.$toast.success("İş Durum Düzenlendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Güncelleme hatası: ", error);
        this.$toast.error("Güncelleme hatası");
      });
  } else {
    axiosCustom
      .post("isdurumlar/ekle", newFormData)
      .then(() => {
        

        this.$toast.success("Yeni İş Durum Eklendi");
        this.formKaydetSonrasi();
      })
      .catch((error) => {
        console.error("Ekleme hatası: ", error);
        this.$toast.error("Ekleme hatası");
      });
  }
} catch (error) {
    console.error('Kaydetme hatası: ', error);
    this.$toast.error('Kaydetme hatası');
  }
} 
  },
  
  iptalEt() {
    this.formIptal();
  },

  

  
},
};
</script>