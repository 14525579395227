<template>
  <div class="kullanicilar">
    <AppMain>
      <div class="container">
        <div class="page-title-container">
          <div class="row">
            <div class="col-12 col-md-7">
              <h1 class="mb-0 pb-0 display-4" id="title">Kullanıcılar</h1>
              <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                <ul class="breadcrumb pt-0">
                  <li class="breadcrumb-item">
                    <router-link to="/">Anasayfa</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/">Kullanıcılar</router-link>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-start justify-content-end">
              <button  v-if="yetki_kontrol('kullanici_ekle')"  class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" @click="kullanicilar_ekle">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="acorn-icons acorn-icons-plus undefined">
                  <path d="M10 17 10 3M3 10 17 10"></path>
                </svg>
                <span>Kullanıcı Ekle</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12 col-md-5 col-lg-3 col-xxl-2 mb-1">
            <div class="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
              <input class="form-control" placeholder="Arama" v-model="kullanicilar.arananKelime" />
              <span class="search-magnifier-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="acorn-icons acorn-icons-search undefined">
                  <circle cx="9" cy="9" r="7"></circle>
                  <path d="M14 14L17.5 17.5"></path>
                </svg>
              </span>
              <span class="search-delete-icon d-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="acorn-icons acorn-icons-close undefined">
                  <path d="M5 5 15 15M15 5 5 15"></path>
                </svg>
              </span>
            </div>
          </div>
          <div class="col-sm-12 col-md-7 col-lg-9 col-xxl-10 text-end mb-1">
            <div class="d-inline-block">
              <div class="d-inline-block">
                <button class="btn p-0" data-bs-toggle="dropdown" type="button" data-bs-offset="0,3">
                  <span class="btn btn-icon btn-icon-only btn-foreground-alternate shadow dropdown" data-bs-delay="0"
                    data-bs-placement="top" data-bs-toggle="tooltip" title="" data-bs-original-title="Export">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
                      stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                      class="acorn-icons acorn-icons-download undefined">
                      <path
                        d="M2 14V14.5C2 15.9045 2 16.6067 2.33706 17.1111C2.48298 17.3295 2.67048 17.517 2.88886 17.6629C3.39331 18 4.09554 18 5.5 18H14.5C15.9045 18 16.6067 18 17.1111 17.6629C17.3295 17.517 17.517 17.3295 17.6629 17.1111C18 16.6067 18 15.9045 18 14.5V14">
                      </path>
                      <path d="M14 10 10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L6 10M10 2 10 13">
                      </path>
                    </svg>
                  </span>
                </button>
                <div class="dropdown-menu shadow dropdown-menu-end">
                  <button class="dropdown-item export-copy" type="button">
                    Kopyala
                  </button>
                  <button class="dropdown-item export-excel" type="button">
                    Excel İndir
                  </button>
                  <button class="dropdown-item export-cvs" type="button">
                    Cvs İndir
                  </button>
                </div>
              </div>
              <div class="dropdown-as-select d-inline-block ms-1" data-childselector="span">
                <button class="btn p-0 shadow" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false" data-bs-offset="0,3">
                  <span class="btn btn-foreground-alternate dropdown-toggle" data-bs-toggle="tooltip"
                    data-bs-placement="top" data-bs-delay="0" title="" data-bs-original-title="Item Count">{{ kullanicilar.sayfadaKayit}}
                    Adet</span>
                </button>
                <div class="dropdown-menu shadow dropdown-menu-end" style="">
                  <a class="dropdown-item" @click="kullanicilarSayfadaKayitDegistir(5)">5 Adet</a>
                  <a class="dropdown-item" @click="kullanicilarSayfadaKayitDegistir(10)">10 Adet</a>
                  <a class="dropdown-item" @click="kullanicilarSayfadaKayitDegistir(20)">20 Adet</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">

          <kullaniciCard :kullanicilar="kullanicilar" :duzenle="kullanicilar_duzenle"   :formKaydetSonrasi="kullanicilarYenile"  @update:kullanicilar="kullanicilarGuncelle"  />

          <template v-if=" this.kullanicilar.modal">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body">
                  <kullaniciForm 
                  :formIslem="kullanicilar.islem" 
                  :formData="kullanicilar.secili" 
                  :formKaydetSonrasi="kullanicilarYenile" 
                  :formIptal="kullanicilar_iptal" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </AppMain>
  </div>
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';
import AppMain from "@/components/AppMain.vue";
import kullaniciCard from "@/components/kullanicilar/kullaniciCard.vue";
import kullaniciForm from "@/components/kullanicilar/kullaniciForm.vue";

export default {
  name: "kullanicilarView",
  components: {
    AppMain,
    kullaniciCard,
    kullaniciForm,
  },
  data() {
    return {
      kullanicilar: {
        tumu:[],
        secili:null,
        seciliSayfa:1,
        sayfadaKayit:5,
        arananKelime:"",
        islem:'ekle',
        modal:false,
      },
    };
  },
 
  mounted() {
    this.kullanicilarGetir();
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    kullanicilarGetir() {
      axiosCustom
        .get("kullanicilar")
        .then((response) => {
          this.kullanicilar.tumu = response.data;
        })
        .catch((error) => {
          console.error("Veri çekme hatası: ", error);
        });
    },
    kullanicilarYenile() {
      this.kullanicilarGetir();
      this.kullanicilar.modal=false;
    },
    kullanicilarGuncelle(veriler) {
      this.kullanicilar = veriler;
    },
   
    kullanicilar_ekle() {
      this.kullanicilar.secili = {};
      this.kullanicilar.islem = "ekle";
      this.kullanicilar.modal=true;
    },
    kullanicilar_duzenle(kullanici) {
      this.kullanicilar.secili = kullanici;
      this.kullanicilar.islem = "duzenle";
      this.kullanicilar.modal=true;
    },
    kullanicilar_iptal() {
      this.kullanicilar.secili = {};
      this.kullanicilar.modal=false;
    },
    
    kullanicilarSayfadaKayitDegistir(value) {
      this.kullanicilar.sayfadaKayit=value;
    },
    
   
  },
};
</script>
