import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { globalVariable } from '@/services/globalVariable';

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import "@vueform/toggle/themes/default.css";

import "vue-select/dist/vue-select.css";

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


//icon
import Unicon from 'vue-unicons'
import * as AllIcons from 'vue-unicons/dist/icons';
Unicon.add(Object.values(AllIcons));
//icon

import "./assets/font/CS-Interface/style.css";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/OverlayScrollbars.min.css";
import "./assets/styles.css";


const app = createApp(App);

// Vue uygulamanızın global özelliklerine $globalVariable ekleyin
app.config.globalProperties.$globalVariable = globalVariable;

// Vue Router'ı kullanıma alın
app.use(router);

//icon
app.use(Unicon, {
    fill: 'royalblue',
    height: 20,
    width: 20
});

// Vue Router nesnesini globalVariable modülüne ilet
globalVariable.setRouter(router);

// Toast ve Sweetalert2 eklentilerini kullanıma alın
app.use(ToastPlugin, { position: 'top' })
    .use(VueSweetalert2);

// Uygulamayı mount edin
app.mount("#app");