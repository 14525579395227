<template>

<div class="col">
            <div class="row">
         
           
                <!-- istur  liste -->
                
                <div class="card mb-2 sh-10 sh-md-8"  v-for="istur in paginatedisturler" :key="istur.id" >
                          <div class="card-body pt-0 pb-0 h-100">
                            <div class="row g-0 h-100 align-content-center">
                              <div class="col-12 col-md-4 d-flex align-items-center mb-2 mb-md-0">
                                <span class="body-link text-truncate"> {{ istur.ad }}</span>
                              </div>
                            

                              <div class="col-12 col-md-3 d-flex align-items-center justify-content-end text-muted text-medium">
                                <span>{{ istur.aciklama }}</span>
                              </div>
                              <div class="col-12 col-md-3 d-flex align-items-center justify-content-end text-muted text-medium">
                                
                              </div>

                              <div class="col-12 col-md-2 d-flex justify-content-end">
                                <button v-if="yetki_kontrol('istur_duzenle')" @click="duzenle(istur)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                  <unicon name="pen" fill="#7c7c7c" class="icon" ></unicon>
                                </button>
                                <button  v-if="yetki_kontrol('istur_sil')"  @click="sil(istur.id)" class="btn btn-icon btn-icon-only btn-foreground-alternate">
                                  <unicon name="trash" fill="#7c7c7c" class="icon" ></unicon>
                                </button>
                              </div>
                            </div>
                          </div>
                  </div>
            
                <!-- istur  liste -->
            
         
            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === isturler.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="isturlerSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    isturler:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  computed: {
    filteredisturler() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.isturler.tumu.filter(
        (istur) =>

          istur.ad.toLowerCase() 
          
          .includes(this.isturler.arananKelime.toLowerCase()) ||istur.ad.toLowerCase()
          
          .includes(this.isturler.arananKelime.toLowerCase()) ||istur.ortalamasaat.toLowerCase()
          
          
          .includes(this.isturler.arananKelime.toLowerCase()) ||istur.aciklama.toLowerCase()
          
          .includes(this.isturler.arananKelime.toLowerCase())


    
      );
    },
    paginatedisturler() {
      const start = (this.isturler.seciliSayfa - 1) * this.isturler.sayfadaKayit;
      const end = start + this.isturler.sayfadaKayit;
      return this.filteredisturler.slice(start, end);
    },
    isturler_toplamSayfa() {
      const totalFilteredUsers = this.filteredisturler.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.isturler.sayfadaKayit), 1);
      if (this.isturler.seciliSayfa > maxPage) {
        this.isturlerSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.isturler_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.isturler.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(isturId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("isturler/sil/" + isturId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    isturlerSayfaDegistir(page) {
      this.$emit('update:isturler',{
      ...this.isturler,
      seciliSayfa: page
    });
    },
    isturlerSayfadaKayitDegistir(value) {
      this.$emit('update:isturler',{
      ...this.isturler,
      sayfadaKayit: value
    });
      this.isturlerSayfaDegistir(1);
    },
  },
};
</script>
