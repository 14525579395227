<template>

<div class="col">
            <div class="row">
         



              <div class="col-md-6 mt-4"  v-for="musterinot in paginatedmusterinotlar" :key="musterinot.id">

<div class="card h-100">

  <div class="card-body row g-0">
   
      <div class="card-body d-flex flex-column pe-0 pt-0 pb-0 h-100 justify-content-start">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column justify-content-center">
            <router-link v-if="yetki_kontrol('yapilacak_goster')" :to="'yapilacaklar/' + musterinot.id"
              class="heading ">{{ musterinot.ad }}</router-link>
          </div>
          <div class="text-alternate">{{ musterinot.olusturma_tarih }} </div>
          <div class="text-alternate" v-if="musterinot.musteri && musterinot.musteri.length>0"> 
            {{ musteriBilgileri(musterinot.musteri).unvan }}  
          </div>
          <div class="text-muted mt-1">{{ musterinot.not }}</div>
        </div>
      </div>
    
  </div>
  <div class="card-footer text-muted">
    <div class="row g-0 sh-6">
      <div class="col-auto" v-if="musterinot.ekleyen && musterinot.ekleyen.length > 0">
        <div class="row g-0 align-items-start align-content-start align-content-md-center align-items-md-center sh-5">
          <div class="col-auto d-flex align-items-center">
            <div class="sw-5">
              <div class="sw-4 mb-1 d-inline-block">
                <img :src="$globalVariable.uploadUrl + kullaniciBilgileri(musterinot.ekleyen).profil"
                       @error="$globalVariable.varsayilanGorsel"
                  class="img-fluid rounded-xl border border-2 border-foreground sh-4" >
              </div>
            </div>
          </div>
          <div class="col">
            <div>{{ kullaniciBilgileri(musterinot.ekleyen).ad }}
              {{ kullaniciBilgileri(musterinot.ekleyen).soyad }}</div>
     
          </div>
        </div>
      </div>
      <div class="col  d-flex justify-content-end">
        
            <button v-if="yetki_kontrol('musterinot_duzenle')" @click="duzenle(musterinot)"
              class="btn btn-icon btn-icon-only btn-foreground-alternate">
              <unicon name="pen" fill="#7c7c7c" class="icon"></unicon>
            </button>
            <button v-if="yetki_kontrol('musterinot_sil')" @click="sil(musterinot.id)"
              class="btn btn-icon btn-icon-only btn-foreground-alternate">
              <unicon name="trash" fill="#7c7c7c" class="icon"></unicon>
            </button>
       
        
      </div>
    </div>


  </div>

</div>

</div>


            </div>
            <div class="row mt-5">
              <div class="col-12 text-center">
                <!-- Sayfalama -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ active: page === musterinotlar.seciliSayfa }" v-for="page in pages" :key="page">
                      <a class="page-link" @click="musterinotlarSayfaDegistir(page)">{{
                        page
                      }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>



 
  
</template>

<script>
import axiosCustom from "@/services/axiosCustom";
import { yetkiKontrol } from '@/services/yetkiKontrol';

export default {
  props: {

    musterinotlar:{
      type: Array,
      required: true
    },
    duzenle: Function,
    formKaydetSonrasi: Function,
    tur:String,
  },
  data() {
    return {
      kullanicilar: [],
      musteriler: [],
    };
  },
  created() {
    this.kullanicilarGetir(); this.musterilerGetir();
  },
  computed: {
    filteredmusterinotlar() {
      // Arama filtresine göre kullanıcıları filtrele
      return this.musterinotlar.tumu.filter(
        (musterinot) =>

          musterinot.ad.toLowerCase() 
          
          .includes(this.musterinotlar.arananKelime.toLowerCase()) ||musterinot.ad.toLowerCase()
          
          
          .includes(this.musterinotlar.arananKelime.toLowerCase()) ||musterinot.not.toLowerCase()
          
          .includes(this.musterinotlar.arananKelime.toLowerCase())


    
      );
    },
    paginatedmusterinotlar() {
      const start = (this.musterinotlar.seciliSayfa - 1) * this.musterinotlar.sayfadaKayit;
      const end = start + this.musterinotlar.sayfadaKayit;
      return this.filteredmusterinotlar.slice(start, end);
    },
    musterinotlar_toplamSayfa() {
      const totalFilteredUsers = this.filteredmusterinotlar.length;
      const maxPage = Math.max(Math.ceil(totalFilteredUsers / this.musterinotlar.sayfadaKayit), 1);
      if (this.musterinotlar.seciliSayfa > maxPage) {
        this.musterinotlarSayfaDegistir(maxPage);
      }
      return maxPage;
    },
    pages() {
        const totalPageCount = this.musterinotlar_toplamSayfa;
        const maxVisiblePages = 5;
        const currentPage = this.musterinotlar.seciliSayfa;

        if (totalPageCount <= maxVisiblePages) {
            return Array.from({ length: totalPageCount }, (_, index) => index + 1);
        }

        const pagesBefore = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        const pagesAfter = Math.min(pagesBefore + maxVisiblePages - 1, totalPageCount);

        return Array.from({ length: pagesAfter - pagesBefore + 1 }, (_, index) => pagesBefore + index);
    },
  
  },
  methods: {
    yetki_kontrol(yetki){
      return yetkiKontrol(yetki);
    },
    async sil(musterinotId) {
      const result = await this.$swal({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        try {
          await axiosCustom.post("musterinotlar/sil/" + musterinotId);
          this.$toast.success("Kullanıcı Silindi");
          this.formKaydetSonrasi();
        } catch (error) {
          console.error("Silme hatası: ", error);
          this.$toast.error("Silme hatası");
        }
      }
    },
    musterinotlarSayfaDegistir(page) {
      this.$emit('update:musterinotlar',{
      ...this.musterinotlar,
      seciliSayfa: page
    });
    },
    musterinotlarSayfadaKayitDegistir(value) {
      this.$emit('update:musterinotlar',{
      ...this.musterinotlar,
      sayfadaKayit: value
    });
      this.musterinotlarSayfaDegistir(1);
    },


    async kullanicilarGetir() {
      try {
        const response = await axiosCustom.get("kullanicilar");
        this.kullanicilar = response.data;
      } catch (error) {
        console.error("kullanicilar alma hatası: ", error);
      }
    },
    kullaniciBilgileri(id) {
      return this.kullanicilar.find((kullanici) => kullanici.id === id) || {};
    },


    async musterilerGetir() {
      try {
        const response = await axiosCustom.get("musteriler");
        this.musteriler = response.data;
      } catch (error) {
        console.error("musteriler alma hatası: ", error);
      }
    },
    musteriBilgileri(id) {
      return this.musteriler.find((musteri) => musteri.id === id) || {};
    },
  },
};
</script>
