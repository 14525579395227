<!-- eslint-disable vue/no-mutating-props -->
<template>
  
  <h5 class="card-title">{{ formIslem === 'duzenle'
                ? 'Rol Düzenle'
                : 'Yeni Rol Ekle'
                 }}</h5>
  <form @submit.prevent="gonder">

    <div class="row">
   
    
      <div class="form-group col-12 mb-1" :class="{ 'text-danger': formHatalar.ad }"> 
        <label for="ad">Ad</label> 
        <input id="ad" v-model="localFormData.ad" class="form-control"/>
        <span v-if="formHatalar.ad" class="error">{{ formHatalar.ad }}</span>
      </div>	
    
    
      <div class="form-group col-12 mb-1" :class="{ 'text-danger': formHatalar.aciklama }"> 
        <label for="aciklama">Açıklama</label> 
        <input id="aciklama" v-model="localFormData.aciklama" class="form-control"/>
        <span v-if="formHatalar.aciklama" class="error">{{ formHatalar.aciklama }}</span>
      </div>	
    
     

      <template v-for="yetki in yetkiler" :key="yetki.key">
  <div class="mb-1">{{yetki.ad}}</div>
  <div class="text-muted mb-1">

    <input
      type="checkbox"
      :checked="localFormData.yetkiler.includes(`${yetki.key}_goster`)"
      @change="toggleYetki(yetki.key, 'goster')"
      :id="`${yetki.key}_checkGoster`"
    />
    <label :for="`${yetki.key}_checkGoster`" class="form-check-label">
      Görüntüleyebilir
    </label>



    <input
      type="checkbox"
      :checked="localFormData.yetkiler.includes(`${yetki.key}_ekle`)"
      @change="toggleYetki(yetki.key, 'ekle')"
      :id="`${yetki.key}_checkEkle`"
    />
    <label :for="`${yetki.key}_checkEkle`" class="form-check-label">
      Ekleyebilir
    </label>

    <input
      type="checkbox"
      :checked="localFormData.yetkiler.includes(`${yetki.key}_duzenle`)"
      @change="toggleYetki(yetki.key, 'duzenle')"
      :id="`${yetki.key}_checkDuzenle`"
    />
    <label :for="`${yetki.key}_checkDuzenle`" class="form-check-label">
      Düzenleyebilir
    </label>

    <input
      type="checkbox"
      :checked="localFormData.yetkiler.includes(`${yetki.key}_sil`)"
      @change="toggleYetki(yetki.key, 'sil')"
      :id="`${yetki.key}_checkSil`"
    />
    <label :for="`${yetki.key}_checkSil`" class="form-check-label">
      Silebilir
    </label>
  </div>
</template>
    
    
    
    
    
    

    

     
    </div>

    <div class="d-flex justify-content-around gap-2 mt-4"> 
      <button class="btn btn-outline-primary" @click="iptalEt">İptal</button>
      <button class="btn btn-primary w-100" type="submit">Kaydet</button>
    </div>
  </form>

</template>

<script>
import axiosCustom from "@/services/axiosCustom";










export default {
components: {
  
  
  
  
  
  
},
props: {
  formIslem: String,
  formData: Object,
  formKaydetSonrasi: Function,
  formIptal: Function,

},
data() {
  return {
    
    

    yetkiler: [
          { key: 'bildirim', ad: 'Bildirim' },
          { key: 'isfikir', ad: 'İş fikir' },
          { key: 'isadim', ad: 'İş Adım' },
          { key: 'isdosya', ad: 'İş Dosya' },
          { key: 'isdurum', ad: 'İş Durum' },
          { key: 'is', ad: 'İş' },
          { key: 'istur', ad: 'İş Türleri' },
          { key: 'kullanici', ad: 'Kullanici' },
          { key: 'musteri', ad: 'Müşteri' },
          { key: 'musterinot', ad: 'Müşteri Notu' },
          { key: 'revize', ad: 'Revize' },
          { key: 'rol', ad: 'Rol' },
          { key: 'yapilacak', ad: 'Yapılacak' },
        ],
    localFormData: { ...this.formData }, 
    formHatalar:{},
  };
},
created() {
    
},
watch: {
  formData: {
    handler(newFormData) {
      this.localFormData = { ...newFormData };
      this.localFormData.yetkiler
      if(!this.localFormData.yetkiler){
        this.localFormData.yetkiler="";
      }
    },
    immediate: true, // Component oluşturulduğunda da tetikle
  },
},
methods: {
  
  
  
  formDogrula() {
    this.formHatalar = {};
    var kontrol=true;
    
    
    if (!this.localFormData.ad) {
      this.formHatalar.ad = "Ad boş bırakılamaz.";
      kontrol=false;
    }
    
    

    
     
    
    
    if (!this.localFormData.yetkiler) {
      this.formHatalar.yetkiler = "Yetkiler boş bırakılamaz.";
      kontrol=false;
    }
    
    

    

    

    

    
    

    return kontrol;
  },
  async  gonder() {
    if(this.formDogrula()){
      const newFormData = new FormData();
      newFormData.append("ad", this.localFormData.ad);
      newFormData.append("aciklama", this.localFormData.aciklama);newFormData.append("yetkiler", this.localFormData.yetkiler);
      
      
       
      
      
      try {
      if (this.formIslem === "duzenle") {
        axiosCustom
          .post("roller/duzenle/" + this.localFormData.id, newFormData)
          .then(() => {
            this.$toast.success("roller Düzenlendi");
            this.formKaydetSonrasi();
          })
          .catch((error) => {
            console.error("Güncelleme hatası: ", error);
            this.$toast.error("Güncelleme hatası");
          });
      } else {
        axiosCustom
          .post("roller/ekle", newFormData)
          .then(() => {
            this.$toast.success("Yeni roller Eklendi");
            this.formKaydetSonrasi();
          })
          .catch((error) => {
            console.error("Ekleme hatası: ", error);
            this.$toast.error("Ekleme hatası");
          });
      }
    } catch (error) {
        console.error('Kaydetme hatası: ', error);
        this.$toast.error('Kaydetme hatası');
      }
    }
  },
  iptalEt() {
    this.formIptal();
  },

  toggleYetki(key, yetki) {
    const yetkiString = `${key}_${yetki}`;
    const regex = new RegExp(`${yetkiString},?`);

    if (this.localFormData.yetkiler.match(regex)) {
      // Yetki zaten varsa kaldır
      this.localFormData.yetkiler = this.localFormData.yetkiler.replace(regex, '');
    } else {
      // Yetki yoksa ekle
      this.localFormData.yetkiler += `${yetkiString},`;
    }
  }

  
  
    
},
};
</script>